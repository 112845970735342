import bernhardkeller from '@sites/shared/assets/../lib/images/testimonials/bernhardkeller.png';
import davidizatt from '@sites/shared/assets/../lib/images/testimonials/davidizatt.png';
import { SEO } from '@sites/shared/ui/layout';
import { graphql, Link, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const ConsultingStyles = styled.div`
  padding: 1rem;

  .purchase {
    width: 200px;
    display: block;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .testimonial-outer {
    height: 100%;
    padding: 3px;
    border-radius: 4px;
    background: linear-gradient(
      60deg,
      var(--primary),
      var(--lightest) 40%,
      var(--secondary) 80%
    );
    margin: 4rem;
  }

  .testimonial-inner {
    padding: 4rem;

    background-color: var(--lightest);

    img {
      float: left;
      width: 100px;
      height: auto;
      margin: 0 2rem 1rem 0;
    }
  }
`;

const ConsultingPage = ({ data }: PageProps) => {
  const { site } = data as GatsbyTypes.ConsultingPageDataQuery;

  if (!site) return <></>;

  return (
    <ConsultingStyles>
      <SEO
        title={`Consulting`}
        description={`Consulting services provided by Josh Morony`}
        siteMetadata={site.siteMetadata}
      />
      <div className="title-bar secondary-background">
        <h1>Consulting Services</h1>
      </div>
      <div className="info-box" style={{ marginTop: `4rem` }}>
        <p>
          Although my primary focus is on creating and updating content for
          Elite Ionic, I do generally have some availability for small
          consulting engagements. On this page, you will find details of how my
          consulting engagements are structured. The availability of different
          packages may change depending on my availability.
        </p>

        <h2>What can I help you with?</h2>
        <p>
          I'm familiar with a broad range of technologies related to the web,
          and may be able to help you with topics not listed below. However,
          these are the most common topics that I consult for and have the most
          expertise in:
        </p>
        <ul>
          <li>Ionic</li>
          <li>Angular</li>
          <li>StencilJS</li>
          <li>Capacitor</li>
          <li>Animations</li>
          <li>UI/UX</li>
          <li>Performance Optimisation</li>
          <li>Code Reviews</li>
        </ul>
        <p>
          For anything not listed here, make sure to get{' '}
          <Link to={`/contact/`}>in touch</Link> first. If you have not already
          contacted me before purchasing any of the packages below, I will
          follow up with you shortly after purchase.
        </p>
      </div>
      <div className="testimonial-outer">
        <div className="testimonial-inner">
          <div>
            <img src={davidizatt} alt="David Izatt" />
            <h2>
              <a
                className="underline"
                href="https://www.linkedin.com/in/davidizatt"
              >
                David Izatt
              </a>{' '}
              | Software Architect, Essenti Technologies
            </h2>
          </div>

          <div>
            <p>
              We engaged Josh as the subject matter expert for the mobile
              portion of our project with the intention to create a more
              effective team by offloading some of the more complex aspects of
              our work. I am happy to say that our expectations were met and
              exceeded.
            </p>
            <p>
              From the start, Josh's experience and easy going manner made it
              easy for us to include him as a team member in our software
              engineering process. His knowledge and enthusiasm turned what
              would be schedule blocking issues, into easily manageable tasks
              with a quick turnaround. Easily the most bang for the buck! In
              short - hire Josh. Your engineers, customers, and budget will
              thank you.
            </p>
          </div>
        </div>
      </div>
      <div className="info-box">
        <h1>General Support | $100/hr</h1>
        <p>
          <small>
            <strong>Scheduled times:</strong> If required <br />{' '}
            <strong>Live Calls/Chats:</strong> Not available <br />{' '}
            <strong>Email Communication:</strong> Yes
          </small>
        </p>

        <p>
          The general support packages allow you to email me with whatever
          queries you have, and I will respond to them via email usually within
          24 hours during business hours.
        </p>
        <p>
          These packages can be used up slowly over time as needed, or I can
          schedule in time to tackle larger work in big chunks (like debugging
          help or code reviews). People typically use these packages for:
        </p>
        <ul>
          <li>Quick emails with small questions</li>
          <li>Getting unstuck on roadblocks</li>
          <li>General guidance on approaches to take</li>
          <li>Code reviews</li>
          <li>Debugging</li>
          <li>Setting up a good architecture for an application</li>
        </ul>
        <p>
          I will keep track of any time used and will notify you when the time
          has run out.
        </p>
        <h2>Purchase a General Support package:</h2>
        <div className="center-flex-container">
          <a href="https://joshmorony.lemonsqueezy.com/checkout/buy/cef92f27-e20c-4caf-9107-1b73cfb9fb74">
            <span className="badge secondary-background purchase">1 hour</span>
          </a>
          <a href="https://joshmorony.lemonsqueezy.com/checkout/buy/cef92f27-e20c-4caf-9107-1b73cfb9fb74">
            <span className="badge secondary-background purchase">3 hours</span>
          </a>
          <span className="badge lighter-background purchase">
            10 hours (please contact me)
          </span>
        </div>
      </div>
      <div className="info-box">
        <h1>Weekly Packages | $130/hr</h1>
        <p>
          <small>
            <strong>Scheduled times:</strong> Yes <br />{' '}
            <strong>Live Calls/Chats:</strong> If required <br />{' '}
            <strong>Email Communication:</strong> Yes
          </small>
        </p>
        <p>
          The weekly package functions similarly to a large general support
          purchase, however the weekly package will be scheduled to be completed
          all within a single week. This will also allow for a live call/chat to
          be scheduled during the week if that is required.
        </p>
        <h2>Purchase a Weekly package:</h2>
        <div className="center-flex-container">
          <span className="badge lighter-background purchase">
            10 hours (unavailable)
          </span>
          <span className="badge lighter-background purchase">
            20 hours (unavailable)
          </span>
        </div>
      </div>
      <div className="info-box" style={{ textAlign: `center` }}>
        <h1>Monthly Packages</h1>
        <p>This package is not yet available.</p>
      </div>
      <div className="testimonial-outer">
        <div className="testimonial-inner">
          <div>
            <img
              src={bernhardkeller}
              style={{ width: `50px`, height: `auto` }}
              alt="Bernhard Keller"
            />

            <p>
              <strong>
                <a className="underline">Bernhard Keller</a>
              </strong>{' '}
              | Thank you for your great support. I appreciate the quality and
              speed of your answers. It is difficult to find solutions to some
              questions while developing mobile apps and your support made my
              life easier.
            </p>
          </div>
        </div>
      </div>
      <div className="info-box">
        <h1>Additional Information</h1>
        <h2>Refunds</h2>
        <p>
          Please note that <strong>unused</strong> consulting hours are not
          refundable (e.g. if you only want to use 1 out of 3 available hours,
          you can not receive a refund for the remaining two hours). Once work
          has commenced consulting packages are not refundable, however if you
          decide that the services aren't for you - or that a different package
          might be better suited - <strong>before</strong> commencing work I
          will happily provide a <strong>full refund.</strong>
        </p>
        <h2>Expiration</h2>
        <p>
          You will have a full year to use up any consulting time from the time
          you purchase a package. This time limit will reset any time you top up
          with additional hours.
        </p>
        <h2>Ongoing Availability</h2>
        <p>
          I typically do not work with a single client for more than 10 hours
          per month for general support, so you will not always be able to
          purchase additional time if required. If you do require work on a more
          consistent basis make sure to contact me first, or you might also want
          to investigate hiring an agency Ionic's{' '}
          <a href="https://ionicframework.com/trusted-partners">
            trusted partners
          </a>{' '}
          program.
        </p>
        <p>
          For the weekly and monthly packages there is also no guarantee that I
          will be available for additional consulting after the initial
          purchase, so please make sure to discuss this with me beforehand if
          that might be something you require.
        </p>
        <h2>Non-Disclosure Agreements (NDAs)</h2>
        <p>
          As a general rule, I will not sign a Non-Disclosure Agreement or any
          other contract that your organisation might require. As an ethical and
          professional software engineer I will of course keep any sensitive or
          confidential information private. However, as I typically only engage
          in small consulting arrangements, it is not worth the expense to me to
          have these contracts reviewed in order to make sure I am not exposing
          myself to any unexpected legal risks.
        </p>
        <h2>Looking for other options?</h2>
        <p>
          If you think a more self-directed approach might better suit you, I
          have a range of <a href="https://www.joshmorony.com/books/">books</a>{' '}
          available as well as the{' '}
          <Link to={`/modules/`}>training material</Link> on this site.
        </p>
        <p></p>
      </div>
    </ConsultingStyles>
  );
};

export default ConsultingPage;

export const query = graphql`
  query ConsultingPageData {
    site {
      ...SiteInformation
    }
  }
`;
