import React from 'react';
import Slugger from 'github-slugger';

export interface MdxHeading {
  depth: number;
  value: string;
}

export interface MdxTOCProps {
  headings: readonly MdxHeading[];
}

const buildChildHeadings = (
  headings: readonly MdxHeading[],
  firstChildIndex: number,
  childHeadings: MdxHeading[] = []
): MdxHeading[] => {
  if (headings[firstChildIndex]?.depth === 3) {
    return buildChildHeadings(headings, firstChildIndex + 1, [
      ...childHeadings,
      headings[firstChildIndex],
    ]);
  } else {
    return childHeadings;
  }
};

const renderChildHeadings = (
  headings: readonly MdxHeading[],
  parentHeadingIndex: number
) => {
  const slugger = new Slugger();
  slugger.reset();

  const childHeadings = buildChildHeadings(headings, parentHeadingIndex + 1);
  return (
    <ol>
      {childHeadings.map((heading) => (
        <li>
          <a href={`#${slugger.slug(heading.value)}`}>{heading.value}</a>
        </li>
      ))}
    </ol>
  );
};

export const MdxTOC = ({ headings }: MdxTOCProps) => {
  const slugger = new Slugger();
  slugger.reset();

  return (
    <div className="table-of-contents">
      <ol>
        {headings
          .filter(
            (heading) =>
              heading.depth === 2 &&
              !['Outline', 'Lesson Outline'].includes(heading.value)
          )
          .map((heading, index) => (
            <li key={heading.value}>
              <p>
                <a href={`#${slugger.slug(heading.value)}`}>{heading.value}</a>
              </p>
              {renderChildHeadings(headings, index)}
            </li>
          ))}
      </ol>
    </div>
  );
};
