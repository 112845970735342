import React, { useState } from 'react';
import styled from 'styled-components';

export interface QuizAnswer {
  id: string;
  answerText?: string;
  answerCode?: string;
  correct: boolean;
  selectedText: string;
}

export interface QuizProps {
  id: string;
  questionText: string;
  answers: QuizAnswer[];
}

const QuizStyles = styled.div`
  margin: 5rem 0;

  .selected {
    transition: background-color 0.1s linear;

    &.correct {
      background-color: var(--success);
    }

    &.incorrect {
      background-color: var(--lightest-tint);
      border: 3px solid var(--angular);
    }
  }

  ol {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      overflow: scroll;
      background: var(--lighter);
      margin: 1rem 0;
      padding-left: 3rem;

      label {
        margin-left: 2rem;
        padding: 3rem;
        width: 100%;
      }
    }

    p {
      padding: 3rem;
      margin: 0;
    }
  }

  code {
    color: var(--blacker);
  }
`;

export const Quiz = ({ id, questionText, answers }: QuizProps) => {
  const [chosenAnswer, setChosenAnswer] = useState<QuizAnswer>();

  return (
    <QuizStyles>
      <h3 dangerouslySetInnerHTML={{ __html: questionText }}></h3>
      <ol>
        {answers.map((answer) => (
          <>
            <li
              key={answer.id}
              className={
                answer.id === chosenAnswer?.id
                  ? `selected ${
                      answer.correct === true ? `correct` : `incorrect`
                    }`
                  : `${answer.correct === true ? `correct` : `incorrect`}`
              }
            >
              <input
                onChange={() => setChosenAnswer(answer)}
                type="radio"
                id={answer.id}
                name={id}
                value={answer.answerText}
              />
              {answer.answerCode && (
                <label htmlFor={answer.id}>
                  <pre>
                    <code>{answer.answerCode}</code>
                  </pre>
                </label>
              )}
              {answer.answerText && (
                <label
                  htmlFor={answer.id}
                  dangerouslySetInnerHTML={{ __html: answer.answerText }}
                ></label>
              )}
            </li>
            <p
              style={{
                display:
                  answer.id === chosenAnswer?.id && answer.selectedText
                    ? `block`
                    : `none`,
              }}
              dangerouslySetInnerHTML={{ __html: answer.selectedText }}
            ></p>
          </>
        ))}
      </ol>
    </QuizStyles>
  );
};
