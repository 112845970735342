export const getFeaturedLessonsFromCategory = (category: any, data: any) => {
  let lessons = data.general.nodes;

  if (category) {
    switch (category) {
      case 'Performance':
        lessons = data.performance.nodes;
        break;
      case 'User Experience':
        lessons = data.uiux.nodes;
        break;
      case 'Testing':
        lessons = data.testing.nodes;
        break;
      case 'Backend':
        lessons = data.backend.nodes;
        break;
      case 'Architecture':
        lessons = data.architecture.nodes;
        break;
      case 'Native Integration':
        lessons = data.native.nodes;
        break;
      default:
        lessons = data.general.nodes;
    }
  }

  return lessons;
};
