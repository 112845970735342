import React from 'react';
import styled from 'styled-components';
import { FaYoutube, FaFileAlt, FaChevronLeft } from 'react-icons/fa';
import { Link } from 'gatsby';

const ModuleOutlineStyles = styled.div`
  padding: 2rem;
  border: 2px dashed var(--secondary);

  max-width: 75ch;
  margin: 5rem auto;

  @media only screen and (max-width: 800px) {
    display: none;
  }

  a {
    font-weight: normal;

    svg {
      position: relative;
      top: -2px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  ol {
    list-style-type: none;
    padding: 0 0 0 2rem;

    li {
      margin-bottom: 1rem;
    }
  }

  .active-lesson {
    border-radius: 5px;
    padding: 1rem;
    border: 3px solid var(--success);
  }
`;

export interface ModuleOutlineSimpleProps {
  lessons: GatsbyTypes.ModuleLessonsFragment;
  currentLesson: number;
}

export const ModuleOutlineSimple = ({
  lessons,
  currentLesson,
}: ModuleOutlineSimpleProps) => {
  return (
    <ModuleOutlineStyles>
      <Link to={`/modules`}>
        <FaChevronLeft /> Back to all modules list
      </Link>
      <h1>Module Outline</h1>
      <ol>
        {lessons.nodes.map(
          (lesson) =>
            lesson.frontmatter &&
            typeof lesson.frontmatter.lesson !== 'undefined' && (
              <li
                key={lesson.id}
                className={
                  lesson.frontmatter.lesson === currentLesson
                    ? 'active-lesson'
                    : ''
                }
              >
                <a
                  href={
                    lesson.frontmatter.public
                      ? `/modules/${lesson.frontmatter.module}/${lesson.frontmatter.lesson}`
                      : `/${lesson.frontmatter.path}/modules/${lesson.frontmatter.module}/${lesson.frontmatter.lesson}`
                  }
                >
                  {lesson.frontmatter.video !== null ? (
                    <FaYoutube style={{ color: `#FF0000` }} />
                  ) : (
                    <FaFileAlt />
                  )}{' '}
                  <strong>
                    {lesson.frontmatter.lesson > 0 &&
                      `Lesson ${lesson.frontmatter.lesson}: `}
                  </strong>
                  {lesson.frontmatter.lesson === currentLesson ? (
                    <strong>{lesson.frontmatter.title}</strong>
                  ) : (
                    `${lesson.frontmatter.title}`
                  )}{' '}
                  {!lesson.frontmatter.public ? (
                    <span className="badge tilt secondary-background">
                      {lesson.frontmatter.path?.toUpperCase()}
                    </span>
                  ) : (
                    <span className="badge tilt success-background">
                      PUBLIC
                    </span>
                  )}
                </a>
              </li>
            )
        )}
      </ol>
    </ModuleOutlineStyles>
  );
};
