import styled from 'styled-components';
import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

export interface LessonHeaderProps {
  background: IGatsbyImageData | undefined;
  icon: IGatsbyImageData | undefined;
  title: string;
}

const LessonHeaderStyles = styled.header`
  position: relative;
  height: 50vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 7px 0px var(--shadow-transparent);

  h1 {
    color: var(--lightest);
    text-shadow: 3px 3px var(--dark);
  }

  @media only screen and (max-width: 700px) {
    h1 {
      font-size: 1.3em !important;
    }
  }

  a {
    color: var(--lightest);
    text-transform: uppercase;
  }
`;

export const LessonHeader = ({
  background,
  icon,
  title,
}: LessonHeaderProps) => {
  return (
    <LessonHeaderStyles>
      {background && (
        <GatsbyImage
          alt="Tutorial hero image"
          image={background}
          className="svg-blur"
          style={{
            position: `absolute`,
            top: 0,
            left: 0,
            width: `100%`,
            height: `100%`,
          }}
        />
      )}

      <div
        style={{
          width: `80%`,
          position: `relative`,
          zIndex: 2,
          filter: `drop-shadow(2px 4px 6px var(--shadow))`,
          textAlign: `center`,
        }}
      >
        {icon && (
          <GatsbyImage
            alt="Lesson icon"
            image={icon}
            style={{
              width: `15%`,
              margin: `auto`,
            }}
          />
        )}

        <h1>{title}</h1>
      </div>
    </LessonHeaderStyles>
  );
};
