import { Helmet } from 'react-helmet';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import styled from 'styled-components';
import React from 'react';

export interface LessonFormProps {
  action: string;
  sv: string;
  uid: string;
}

const LessonFormStyles = styled.form`
  width: 100%;
  font-size: 0.9em;
  padding-left: 4rem;
  padding-right: 2rem;

  @media only screen and (max-width: 700px) {
    font-size: 1em;

    & > div {
      flex-direction: column;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    width: 100%;
  }

  button {
  }

  label {
    margin-bottom: 0px;
    width: 100%;
  }
`;

export const LessonForm = ({ action, sv, uid }: LessonFormProps) => {
  return (
    <>
      <Helmet>
        <script async src="https://f.convertkit.com/ckjs/ck.5.js"></script>
      </Helmet>
      <LessonFormStyles
        action={action}
        method="post"
        data-sv-form={sv}
        data-uid={uid}
      >
        <div data-element="fields">
          <label htmlFor="email_address">
            <input
              type="email"
              id="email"
              name="email_address"
              placeholder="email..."
            />
          </label>

          <button data-element="submit">
            <FaEnvelopeOpenText />
          </button>
        </div>
        <p
          style={{
            margin: `0`,
            marginTop: `1rem`,
            fontSize: `0.7em`,
            opacity: `0.5`,
            color: `var(--light)`,
            textAlign: `center`,
          }}
        >
          Join the newsletter. I'll give you exact details in the double opt-in
          email confirmation.
        </p>
        <ul data-element="errors" />
      </LessonFormStyles>
    </>
  );
};
