import React from 'react';
import styled from 'styled-components';

const LogoStyles = styled.svg`
  font-size: 30px;
  margin-right: 10px;
  fill: inherit;
  width: 100%;

  .elite-icon {
    pointer-events: bounding-box;
  }

  .elite-icon:hover {
    animation: sway 2s ease-in-out infinite;
  }

  .flames {
    opacity: 0;
    transform: rotate3d(1, 1, 0, 50deg);
    animation: 1s 1s ease fadeInAndSpin;
    animation-fill-mode: forwards;
  }

  .ionic-logo {
    transform: translate3d(-5px, 6px, 0);
    animation: 1s 1s ease shift;
    animation-fill-mode: forwards;
  }

  .logo-text {
    transform: translate3d(-5px, 0, 0);
    animation: 1s 1s ease shift;
    animation-fill-mode: forwards;
  }

  path {
    box-shadow: 1px 1px #000;
  }

  @keyframes fadeInAndSpin {
    100% {
      opacity: 1;
      transform: rotate3d(1, 1, 0, 0deg);
    }
  }

  @keyframes shift {
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes sway {
    0% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    50% {
      opacity: 0.9;
      transform: translate3d(-1px, 2px, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

export const EliteIonicLogoIconOnly = () => {
  return (
    <LogoStyles
      id="elite-logo"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 50 42"
    >
      <g className="elite-icon">
        <path
          className="flames"
          fill="#ffa400"
          d="M18.407 22.175c2.56 4.403 7.186 7.459 12.546 7.836-2.817 2.173-6.982 3.951-12.73 3.039 0 0-6.448.374-8.363 8.325 0 0 .118-14.342 8.547-19.2z"
        />
        <path
          className="flames"
          fill="#ffa000"
          d="M16.396 12.297a15.94 15.94 0 00-.12 1.953c0 4.228 1.664 8.07 4.373 10.907-7.936 4.195-19.609-.908-19.609-.908 8.04 1.497 11.013-4.237 11.013-4.237.924-3.433 2.524-5.917 4.343-7.715z"
        />
        <path
          className="flames"
          fill="#ffd300"
          d="M16.531 17.09c.846 4.654 3.733 8.599 7.699 10.875C14.28 37.281 0 32.975 0 32.975c4.74.722 7.125-3.72 7.125-3.72 2.679-6.131 6.132-9.877 9.406-12.165z"
        />
        <g className="ionic-logo" fill="#fff">
          <path
            fillRule="nonzero"
            d="M31.984 7.884c-3.619 0-6.596 2.978-6.596 6.596 0 3.619 2.977 6.597 6.596 6.597s6.596-2.978 6.596-6.597c-.004-3.617-2.979-6.592-6.596-6.596z"
          />
          <circle cx="41.455" cy="5.464" r="3.005" />
          <path
            fillRule="nonzero"
            d="M45.155 8.485l-.126-.276-.197.228a4.535 4.535 0 01-1.813 1.249l-.197.071.078.197c.599 1.439.907 2.983.907 4.543 0 6.49-5.341 11.832-11.832 11.832-6.491 0-11.832-5.342-11.832-11.832 0-6.491 5.341-11.833 11.832-11.833 1.756 0 3.49.391 5.075 1.144l.185.089.081-.197a4.535 4.535 0 011.335-1.751l.237-.197-.27-.139A14.312 14.312 0 0031.982 0C24.042.001 17.51 6.535 17.51 14.475c0 7.94 6.534 14.474 14.474 14.474 7.941 0 14.475-6.534 14.475-14.474 0-2.073-.446-4.122-1.306-6.008l.002.018z"
          />
        </g>
      </g>
    </LogoStyles>
  );
};

export const EliteIonicLightLogoIconOnly = () => {
  return (
    <LogoStyles
      id="elite-logo"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 50 42"
    >
      <g className="elite-icon">
        <path
          className="flames"
          fill="#ffa400"
          d="M18.407 22.175c2.56 4.403 7.186 7.459 12.546 7.836-2.817 2.173-6.982 3.951-12.73 3.039 0 0-6.448.374-8.363 8.325 0 0 .118-14.342 8.547-19.2z"
        />
        <path
          className="flames"
          fill="#ffa000"
          d="M16.396 12.297a15.94 15.94 0 00-.12 1.953c0 4.228 1.664 8.07 4.373 10.907-7.936 4.195-19.609-.908-19.609-.908 8.04 1.497 11.013-4.237 11.013-4.237.924-3.433 2.524-5.917 4.343-7.715z"
        />
        <path
          className="flames"
          fill="#ffd300"
          d="M16.531 17.09c.846 4.654 3.733 8.599 7.699 10.875C14.28 37.281 0 32.975 0 32.975c4.74.722 7.125-3.72 7.125-3.72 2.679-6.131 6.132-9.877 9.406-12.165z"
        />
        <g className="ionic-logo" fill="#2b2b31">
          <path
            fillRule="nonzero"
            d="M31.984 7.884c-3.619 0-6.596 2.978-6.596 6.596 0 3.619 2.977 6.597 6.596 6.597s6.596-2.978 6.596-6.597c-.004-3.617-2.979-6.592-6.596-6.596z"
          />
          <circle cx="41.455" cy="5.464" r="3.005" />
          <path
            fillRule="nonzero"
            d="M45.155 8.485l-.126-.276-.197.228a4.535 4.535 0 01-1.813 1.249l-.197.071.078.197c.599 1.439.907 2.983.907 4.543 0 6.49-5.341 11.832-11.832 11.832-6.491 0-11.832-5.342-11.832-11.832 0-6.491 5.341-11.833 11.832-11.833 1.756 0 3.49.391 5.075 1.144l.185.089.081-.197a4.535 4.535 0 011.335-1.751l.237-.197-.27-.139A14.312 14.312 0 0031.982 0C24.042.001 17.51 6.535 17.51 14.475c0 7.94 6.534 14.474 14.474 14.474 7.941 0 14.475-6.534 14.475-14.474 0-2.073-.446-4.122-1.306-6.008l.002.018z"
          />
        </g>
      </g>
    </LogoStyles>
  );
};

export const EliteIonicLogo = () => {
  return (
    <LogoStyles
      id="elite-logo"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 218 42"
    >
      <g className="logo-text" fill="#fff" fillRule="nonzero">
        <path d="M52.445 9.371h14.148v6.3h-6.48v2.664h6.372v5.976h-6.372v2.772h6.732v6.3h-14.4V9.371zM69.005 9.371h7.992v17.712h6.624v6.3H69.005V9.371z" />
        <path d="M84.881 9.371H93.017V33.383H84.881z" />
        <path d="M98.705 15.671h-4.428v-6.3h16.776v6.3h-4.428v17.712h-7.92V15.671zM112.313 9.371h14.148v6.3h-6.48v2.664h6.372v5.976h-6.372v2.772h6.732v6.3h-14.4V9.371z" />
      </g>
      <g className="logo-text" fill="#fff" fillRule="nonzero">
        <path d="M129.526 9.33H132.80100000000002V33.483H129.526z" />
        <path d="M148.391 34.097c-1.842 0-3.537-.325-5.083-.973-1.547-.648-2.871-1.541-3.974-2.678-1.103-1.137-1.968-2.479-2.593-4.025-.626-1.547-.938-3.218-.938-5.015 0-1.797.312-3.468.938-5.015.625-1.546 1.49-2.888 2.593-4.025 1.103-1.137 2.427-2.03 3.974-2.678 1.546-.648 3.241-.973 5.083-.973s3.536.325 5.083.973c1.546.648 2.871 1.541 3.974 2.678 1.103 1.137 1.968 2.479 2.593 4.025.625 1.547.938 3.218.938 5.015 0 1.797-.313 3.468-.938 5.015-.625 1.546-1.49 2.888-2.593 4.025-1.103 1.137-2.428 2.03-3.974 2.678-1.547.648-3.241.973-5.083.973zm0-3.071c1.387 0 2.638-.256 3.753-.767a8.902 8.902 0 002.865-2.064 9.098 9.098 0 001.842-3.053c.432-1.172.648-2.417.648-3.736 0-1.319-.216-2.564-.648-3.736a9.098 9.098 0 00-1.842-3.053 8.902 8.902 0 00-2.865-2.064c-1.115-.511-2.366-.767-3.753-.767s-2.638.256-3.753.767a8.902 8.902 0 00-2.865 2.064 9.098 9.098 0 00-1.842 3.053 10.692 10.692 0 00-.649 3.736c0 1.319.216 2.564.649 3.736a9.098 9.098 0 001.842 3.053 8.902 8.902 0 002.865 2.064c1.115.511 2.366.767 3.753.767zM163.981 9.33h4.299l13.304 19.854h.068V9.33h3.275v24.152h-4.162l-13.441-19.854h-.068v19.854h-3.275V9.33z" />
        <path d="M189.43 9.33H192.705V33.483H189.43z" />
        <path d="M214.027 14.549a6.758 6.758 0 00-2.593-2.047 7.73 7.73 0 00-3.241-.716c-1.364 0-2.598.256-3.701.767a8.531 8.531 0 00-2.832 2.098c-.784.887-1.393 1.928-1.825 3.122-.432 1.194-.648 2.473-.648 3.838 0 1.273.205 2.479.614 3.616a9.062 9.062 0 001.774 3.002 8.321 8.321 0 002.832 2.047c1.114.5 2.376.75 3.786.75 1.388 0 2.604-.284 3.651-.853 1.046-.568 1.933-1.364 2.66-2.388l2.764 2.081c-.182.251-.501.609-.956 1.075-.454.466-1.057.932-1.808 1.399-.75.466-1.654.875-2.712 1.228-1.057.352-2.28.529-3.667.529-1.91 0-3.633-.364-5.168-1.092-1.535-.728-2.843-1.683-3.923-2.866a12.5 12.5 0 01-2.474-4.008 12.603 12.603 0 01-.853-4.52c0-1.865.307-3.588.922-5.169.614-1.58 1.472-2.945 2.575-4.093 1.103-1.149 2.428-2.042 3.974-2.678 1.547-.637 3.253-.956 5.118-.956 1.592 0 3.155.308 4.69.922 1.535.614 2.792 1.557 3.77 2.831l-2.729 2.081z" />
      </g>
      <g className="elite-icon">
        <path
          className="flames"
          fill="#ffa400"
          d="M18.407 22.175c2.56 4.403 7.186 7.459 12.546 7.836-2.817 2.173-6.982 3.951-12.73 3.039 0 0-6.448.374-8.363 8.325 0 0 .118-14.342 8.547-19.2z"
        />
        <path
          className="flames"
          fill="#ffa000"
          d="M16.396 12.297a15.94 15.94 0 00-.12 1.953c0 4.228 1.664 8.07 4.373 10.907-7.936 4.195-19.609-.908-19.609-.908 8.04 1.497 11.013-4.237 11.013-4.237.924-3.433 2.524-5.917 4.343-7.715z"
        />
        <path
          className="flames"
          fill="#ffd300"
          d="M16.531 17.09c.846 4.654 3.733 8.599 7.699 10.875C14.28 37.281 0 32.975 0 32.975c4.74.722 7.125-3.72 7.125-3.72 2.679-6.131 6.132-9.877 9.406-12.165z"
        />
        <g className="ionic-logo" fill="#fff">
          <path
            fillRule="nonzero"
            d="M31.984 7.884c-3.619 0-6.596 2.978-6.596 6.596 0 3.619 2.977 6.597 6.596 6.597s6.596-2.978 6.596-6.597c-.004-3.617-2.979-6.592-6.596-6.596z"
          />
          <circle cx="41.455" cy="5.464" r="3.005" />
          <path
            fillRule="nonzero"
            d="M45.155 8.485l-.126-.276-.197.228a4.535 4.535 0 01-1.813 1.249l-.197.071.078.197c.599 1.439.907 2.983.907 4.543 0 6.49-5.341 11.832-11.832 11.832-6.491 0-11.832-5.342-11.832-11.832 0-6.491 5.341-11.833 11.832-11.833 1.756 0 3.49.391 5.075 1.144l.185.089.081-.197a4.535 4.535 0 011.335-1.751l.237-.197-.27-.139A14.312 14.312 0 0031.982 0C24.042.001 17.51 6.535 17.51 14.475c0 7.94 6.534 14.474 14.474 14.474 7.941 0 14.475-6.534 14.475-14.474 0-2.073-.446-4.122-1.306-6.008l.002.018z"
          />
        </g>
      </g>
    </LogoStyles>
  );
};

export const EliteIonicLightLogo = () => {
  return (
    <LogoStyles
      id="elite-logo"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 218 42"
    >
      <g className="logo-text" fill="#2b2b31" fillRule="nonzero">
        <path d="M52.445 9.371h14.148v6.3h-6.48v2.664h6.372v5.976h-6.372v2.772h6.732v6.3h-14.4V9.371zM69.005 9.371h7.992v17.712h6.624v6.3H69.005V9.371z" />
        <path d="M84.881 9.371H93.017V33.383H84.881z" />
        <path d="M98.705 15.671h-4.428v-6.3h16.776v6.3h-4.428v17.712h-7.92V15.671zM112.313 9.371h14.148v6.3h-6.48v2.664h6.372v5.976h-6.372v2.772h6.732v6.3h-14.4V9.371z" />
      </g>
      <g className="logo-text" fill="#2b2b31" fillRule="nonzero">
        <path d="M129.526 9.33H132.80100000000002V33.483H129.526z" />
        <path d="M148.391 34.097c-1.842 0-3.537-.325-5.083-.973-1.547-.648-2.871-1.541-3.974-2.678-1.103-1.137-1.968-2.479-2.593-4.025-.626-1.547-.938-3.218-.938-5.015 0-1.797.312-3.468.938-5.015.625-1.546 1.49-2.888 2.593-4.025 1.103-1.137 2.427-2.03 3.974-2.678 1.546-.648 3.241-.973 5.083-.973s3.536.325 5.083.973c1.546.648 2.871 1.541 3.974 2.678 1.103 1.137 1.968 2.479 2.593 4.025.625 1.547.938 3.218.938 5.015 0 1.797-.313 3.468-.938 5.015-.625 1.546-1.49 2.888-2.593 4.025-1.103 1.137-2.428 2.03-3.974 2.678-1.547.648-3.241.973-5.083.973zm0-3.071c1.387 0 2.638-.256 3.753-.767a8.902 8.902 0 002.865-2.064 9.098 9.098 0 001.842-3.053c.432-1.172.648-2.417.648-3.736 0-1.319-.216-2.564-.648-3.736a9.098 9.098 0 00-1.842-3.053 8.902 8.902 0 00-2.865-2.064c-1.115-.511-2.366-.767-3.753-.767s-2.638.256-3.753.767a8.902 8.902 0 00-2.865 2.064 9.098 9.098 0 00-1.842 3.053 10.692 10.692 0 00-.649 3.736c0 1.319.216 2.564.649 3.736a9.098 9.098 0 001.842 3.053 8.902 8.902 0 002.865 2.064c1.115.511 2.366.767 3.753.767zM163.981 9.33h4.299l13.304 19.854h.068V9.33h3.275v24.152h-4.162l-13.441-19.854h-.068v19.854h-3.275V9.33z" />
        <path d="M189.43 9.33H192.705V33.483H189.43z" />
        <path d="M214.027 14.549a6.758 6.758 0 00-2.593-2.047 7.73 7.73 0 00-3.241-.716c-1.364 0-2.598.256-3.701.767a8.531 8.531 0 00-2.832 2.098c-.784.887-1.393 1.928-1.825 3.122-.432 1.194-.648 2.473-.648 3.838 0 1.273.205 2.479.614 3.616a9.062 9.062 0 001.774 3.002 8.321 8.321 0 002.832 2.047c1.114.5 2.376.75 3.786.75 1.388 0 2.604-.284 3.651-.853 1.046-.568 1.933-1.364 2.66-2.388l2.764 2.081c-.182.251-.501.609-.956 1.075-.454.466-1.057.932-1.808 1.399-.75.466-1.654.875-2.712 1.228-1.057.352-2.28.529-3.667.529-1.91 0-3.633-.364-5.168-1.092-1.535-.728-2.843-1.683-3.923-2.866a12.5 12.5 0 01-2.474-4.008 12.603 12.603 0 01-.853-4.52c0-1.865.307-3.588.922-5.169.614-1.58 1.472-2.945 2.575-4.093 1.103-1.149 2.428-2.042 3.974-2.678 1.547-.637 3.253-.956 5.118-.956 1.592 0 3.155.308 4.69.922 1.535.614 2.792 1.557 3.77 2.831l-2.729 2.081z" />
      </g>
      <g className="elite-icon">
        <path
          className="flames"
          fill="#ffa400"
          d="M18.407 22.175c2.56 4.403 7.186 7.459 12.546 7.836-2.817 2.173-6.982 3.951-12.73 3.039 0 0-6.448.374-8.363 8.325 0 0 .118-14.342 8.547-19.2z"
        />
        <path
          className="flames"
          fill="#ffa000"
          d="M16.396 12.297a15.94 15.94 0 00-.12 1.953c0 4.228 1.664 8.07 4.373 10.907-7.936 4.195-19.609-.908-19.609-.908 8.04 1.497 11.013-4.237 11.013-4.237.924-3.433 2.524-5.917 4.343-7.715z"
        />
        <path
          className="flames"
          fill="#ffd300"
          d="M16.531 17.09c.846 4.654 3.733 8.599 7.699 10.875C14.28 37.281 0 32.975 0 32.975c4.74.722 7.125-3.72 7.125-3.72 2.679-6.131 6.132-9.877 9.406-12.165z"
        />
        <g className="ionic-logo" fill="#2b2b31">
          <path
            fillRule="nonzero"
            d="M31.984 7.884c-3.619 0-6.596 2.978-6.596 6.596 0 3.619 2.977 6.597 6.596 6.597s6.596-2.978 6.596-6.597c-.004-3.617-2.979-6.592-6.596-6.596z"
          />
          <circle cx="41.455" cy="5.464" r="3.005" />
          <path
            fillRule="nonzero"
            d="M45.155 8.485l-.126-.276-.197.228a4.535 4.535 0 01-1.813 1.249l-.197.071.078.197c.599 1.439.907 2.983.907 4.543 0 6.49-5.341 11.832-11.832 11.832-6.491 0-11.832-5.342-11.832-11.832 0-6.491 5.341-11.833 11.832-11.833 1.756 0 3.49.391 5.075 1.144l.185.089.081-.197a4.535 4.535 0 011.335-1.751l.237-.197-.27-.139A14.312 14.312 0 0031.982 0C24.042.001 17.51 6.535 17.51 14.475c0 7.94 6.534 14.474 14.474 14.474 7.941 0 14.475-6.534 14.475-14.474 0-2.073-.446-4.122-1.306-6.008l.002.018z"
          />
        </g>
      </g>
    </LogoStyles>
  );
};
