import React from 'react';
import { FaClock } from 'react-icons/fa';

export interface ReadTimeProps {
  time: number;
}

export const ReadTime = ({ time }: ReadTimeProps) => {
  return (
    <span className="read-time">
      <FaClock style={{ position: `relative`, top: `-2px` }} /> {time} min read
    </span>
  );
};
