export const handleLogin = async ({ license }: any) => {
  try {
    const data = await postData(`/.netlify/functions/auth`, {
      profile: { license: license.trim() },
    });

    if (typeof data.token != 'undefined') {
      const expires: any = new Date();
      expires.setMonth(expires.getMonth() + 2);
      const cookie =
        'eijwt=' +
        data.token +
        ';expires=' +
        expires['toGMTString']() +
        ';domain=eliteionic.com;path=/';
      document.cookie = cookie;
      return true;
    } else {
      throw new Error('No token present');
    }
  } catch (err: any) {
    throw new Error(err);
  }
};

export const isLoggedIn = () => {
  if (typeof document !== 'undefined') {
    if (document.cookie.indexOf('eijwt') === -1) {
      return false;
    } else {
      return true;
    }
  }

  return false;
};

export const logout = () => {
  document.cookie =
    'eijwt=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=eliteionic.com;path=/';
};

const postData = async (url = ``, data = {}) => {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  return response.json();
};
