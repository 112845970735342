import { SEO } from '@sites/shared/ui/layout';
import { graphql, Link, PageProps } from 'gatsby';
import React from 'react';

const AboutPage = ({ data }: PageProps) => {
  const { site } = data as GatsbyTypes.AboutPageDataQuery;

  if (!site) return <></>;

  return (
    <>
      <SEO title={`About`} siteMetadata={site.siteMetadata} />
      <div className="info-box">
        <h1
          style={{ lineHeight: `1.5`, marginBottom: `4rem`, padding: `4rem` }}
        >
          Elite Ionic provides the education and resources necessary to empower
          Ionic developers - <strong>not to stop at the bare minimum</strong> -
          but to create truly impressive mobile applications powered by
          web-tech.
        </h1>
        <p>
          Ionic applications <em>(and other "web view" based mobile apps)</em>{' '}
          have a <strong>bad reputation</strong> among many developers. It is a
          reputation that is entirely undeserved, but many people view mobile
          applications that use a web view to be inherently worse than their{' '}
          <em>"truly native"</em> counterparts.
        </p>
        <p>
          This is unfortunate, because the usage of web views is actually one of
          Ionic's greatest{' '}
          <a href="https://www.joshmorony.com/the-biggest-advantage-of-ionic-applications-web-views/">
            super powers
          </a>
          . Whilst the browsers of old may have severely limited what you could
          do, modern browsers now power most of the desktop applications we use
          on a day to day basis.
        </p>
        <p>
          <strong>Why does this reputation exist then?</strong> The web is a
          powerful platform perfectly capable of creating most -{' '}
          <em>not all</em> - types of applications. Mobile web views do not
          grant as much power as a desktop browser, but for the majority of
          applications this is of no or little relevance.
        </p>
        <p>
          Having more power/performance <em>available </em>
          will not make a difference if your application doesn't need it or use
          it, and if the application doesn't need it you're not going to miss
          it.
        </p>
        <p>
          I think that part of the reputation for web-view based applications
          does come from some simple misconceptions around performance but I
          think the much larger contributor to this is <strong>us</strong> -{' '}
          <em>the developers of Ionic applications</em>.
        </p>
        <p>
          The truth is that{' '}
          <strong>
            there are a lot of poorly designed and poorly performing Ionic
            applications
          </strong>{' '}
          out there. Ionic makes it <em>way easier</em> to build a mobile app,
          but that lower barrier to entry also means less experienced developers
          will be building these apps.
        </p>
        <p>
          Ionic makes it realistic to put together an application with a minimal
          working knowledge of web technologies. As a complete beginner you
          could probably cobble together an application from various code
          samples in days or a week.{' '}
          <strong>The result will not often be good.</strong>
        </p>
        <p>
          It is (relatively) easy to make an Ionic app, but a great deal of
          knowledge and skill is required to make a really great one -{' '}
          <em>just as is the case for regular native apps</em>.
        </p>
        <p>
          Mobile applications that are <em>just good enough</em> are sometimes
          all that is required, and that is perfectly fine. However, this
          website has a different goal in mind...
        </p>
        <p>
          Elite Ionic's mission is to provide the education and resources
          necessary to empower Ionic developers{' '}
          <strong>not to stop at the bare minimum</strong>, but to create truly
          impressive mobile applications powered by web-tech.
        </p>
        <p>
          On this website, you will find free <strong>intermediate</strong> to{' '}
          <strong>advanced</strong> tutorials mainly focused on{' '}
          <Link to={`/topics/performance/`}>Performance</Link>,{' '}
          <Link to={`/topics/user-experience/`}>
            User Interface/User Experience
          </Link>
          , <Link to={`/topics/testing/`}>Testing</Link>,{' '}
          <Link to={`/topics/backend/`}>Backends</Link>,{' '}
          <Link to={`/topics/architecture/`}>Architecture</Link>, and{' '}
          <Link to={`/topics/native-integration/`}>Native Integrations</Link>.
          For more free beginner level tutorials check out my main blog{' '}
          <a href="https://www.joshmorony.com/blog">joshmorony.com</a>
          ).
        </p>
      </div>
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPageData {
    site {
      ...SiteInformation
    }
  }
`;
