import { SEO } from '@sites/shared/ui/layout';
import { graphql, Link, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const SourceCodeGrid = styled.div`
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;

  & > div {
    margin: 0 20px;
  }

  & > div:first-of-type {
    margin-top: 3rem;
  }
`;

const SourceCodePage = ({ data }: PageProps) => {
  const { site, tutorials } = data as GatsbyTypes.SourceCodePageDataQuery;

  if (!site) return <></>;

  return (
    <>
      <SEO
        title={`Source code`}
        description={`Source code links for all of the tutorials on Elite Ionic`}
        siteMetadata={site.siteMetadata}
      />
      <SourceCodeGrid>
        <div className="title-bar secondary-background">
          <h1>Source Code</h1>
        </div>
        <div className="fancy-box-outer">
          <div className="fancy-box-inner">
            {tutorials.nodes.map(
              (tutorial) =>
                tutorial.frontmatter && (
                  <div key={tutorial.id} style={{ marginBottom: `5rem` }}>
                    <Link to={`/tutorials/${tutorial.frontmatter.slug}/`}>
                      <h2>{tutorial.frontmatter.title}</h2>
                    </Link>
                    <p>{tutorial.frontmatter.description}</p>
                    <ul>
                      <li>
                        <a href={tutorial.frontmatter.bonus_link}>
                          View source code
                        </a>
                      </li>
                    </ul>
                  </div>
                )
            )}
          </div>
        </div>
      </SourceCodeGrid>
    </>
  );
};

export default SourceCodePage;

export const query = graphql`
  query SourceCodePageData {
    site {
      ...SiteInformation
    }
    tutorials: allMdx(
      filter: { frontmatter: { bonus_type: { eq: "source" } } }
      sort: { fields: [frontmatter___updated], order: DESC }
    ) {
      ...AllPostDetailsSimple
    }
  }
`;
