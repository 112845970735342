import React from 'react';
import styled from 'styled-components';

export interface PathArrowProps {
  flip: boolean;
}

const ArrowStyles = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;

  .arrow-path {
    fill: none;
    stroke: black;
    stroke-width: 7px;
    stroke-dasharray: 21, 21, 0, 0;
    animation: 1.5s path linear infinite;
  }

  &.flip {
    transform: scale(-1, 1);
  }

  @keyframes path {
    0% {
      stroke-dashoffset: 42;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
`;

export const PathArrow = ({ flip }: PathArrowProps) => {
  return (
    <ArrowStyles
      className={flip ? 'flip' : ''}
      width="100%"
      height="100%"
      viewBox="0 0 598 328"
      version="1.1"
    >
      <g transform="matrix(1,0,0,1,-89.9911,7.4965)">
        <g transform="matrix(1,-0,-0,1,89.9911,-7.4965)">
          <path d="M577.75,276.066L567.841,297.351L556.758,276.653L577.75,276.066Z" />
          <path
            className="arrow-path"
            d="M29.905,29.698C29.905,29.698 12.884,210.042 287.542,161.381C533.87,117.739 563.815,225.036 567.371,280.558"
          />
        </g>
      </g>
    </ArrowStyles>
  );
};
