import React from 'react';
import styled from 'styled-components';

import gdebadge from '@sites/shared/assets/../lib/images/marketing/gde-angular.png';
import ionicLogo from '@sites/ionicstart/shared/assets/../lib/images/ionic-logo-blue.png';
import josh from '@sites/ionicstart/shared/assets/../lib/images/josh-2022.png';

const CreatedByJoshStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem 2rem;
  background-color: var(--lighter);

  .author-container {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;

    box-shadow: 0px 0px 25px 0px var(--shadow-transparent);
    max-width: 800px;
    padding: 2rem;
    background-color: var(--lightest);
  }

  .badge-box {
    display: flex;
    justify-content: space-evenly;
  }

  .avatar {
    float: left;
    width: 80px;
    height: auto;
    border-radius: 50%;
    margin-right: 2rem;
  }

  @media only screen and (max-width: 700px) {
    .badge-box {
      flex-direction: column;
      align-items: center;
    }

    img {
      margin-bottom: 3rem;
    }
  }
`;

export const CreatedByJosh = () => {
  return (
    <CreatedByJoshStyles>
      <div className="author-container">
        <div style={{ padding: `2rem` }}>
          <img className="avatar" src={josh} alt="Josh Morony avatar" />
          <p>
            <strong>Created</strong> by <strong>Josh Morony</strong> &mdash;{' '}
            <strong>Ionic Developer Expert</strong> and{' '}
            <strong>Google Developer Expert</strong> for{' '}
            <strong>Angular</strong>
          </p>
        </div>
        <div className="badge-box">
          <img
            style={{ width: '200px', height: 'auto' }}
            src={gdebadge}
            alt="Google Developer Expert Angular 2022 Badge"
          />
          <img
            style={{ width: '200px', height: 'auto' }}
            src={ionicLogo}
            alt="Ionic Logo"
          />
        </div>
      </div>
    </CreatedByJoshStyles>
  );
};
