import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { IconType } from 'react-icons';

export interface FeaturedTopic {
  id: string;
  title: string;
  path: string;
  icon?: string | JSX.Element | IconType;
  iconColor?: string;
  isUrl?: boolean;
  description?: string;
}

export interface FeaturedTopicsProps {
  topics: FeaturedTopic[];
  framework?: string;
  category?: string;
}

const FeaturedTopicsGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));

  .active {
    img {
      filter: grayscale(0) drop-shadow(2px 4px 6px var(--shadow-transparent)) !important;
      transform: translateY(-2px);
    }
  }

  & > div {
    height: 100%;
    padding: 3px;
    border-radius: 4px;
    background: linear-gradient(
      60deg,
      var(--primary),
      var(--lightest) 40%,
      var(--secondary) 80%
    );
    /* animation: popIn 0.4s calc((var(--animation-order) + 3) * 70ms) both ease-in; */
  }

  .featured-topic-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
    background-color: var(--lightest);
    height: 200px;

    img {
      width: 50%;
      height: auto;
      filter: grayscale(1);
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      img {
        filter: grayscale(0) drop-shadow(2px 4px 6px var(--shadow-transparent));
        transform: translateY(-2px);
      }
    }
  }
`;

export const FeaturedTopics = ({
  framework,
  category,
  topics,
}: FeaturedTopicsProps) => {
  return (
    <FeaturedTopicsGrid>
      {topics.map((topic, index) => {
        const link = framework
          ? `/topics/${framework}-${topic.path}/`
          : `/topics/${topic.path}/`;

        const content = (
          <div className="featured-topic-container">
            {typeof topic.icon === 'string' ? (
              <img src={topic.icon} alt={topic.title} />
            ) : typeof topic.icon === 'function' ? (
              React.createElement(topic.icon, {
                fontSize: `80px`,
                color: topic.iconColor || '#000',
              })
            ) : (
              topic.icon
            )}
            <span>
              <h3 style={{ marginBottom: `3px` }}>{topic.title}</h3>
              {topic.description && (
                <p className="d-none d-md-block" style={{ fontSize: `1.5rem` }}>
                  {topic.description}
                </p>
              )}
            </span>
          </div>
        );

        return (
          <div
            key={topic.id}
            style={{ '--animation-order': index } as any}
            className={
              category?.toLowerCase().replace(' ', '-') === topic.path
                ? 'active'
                : ''
            }
          >
            {topic.isUrl ? (
              <a href={topic.path}>{content}</a>
            ) : (
              <Link to={link}>{content}</Link>
            )}
          </div>
        );
      })}
    </FeaturedTopicsGrid>
  );
};
