import React from 'react';
import styled from 'styled-components';

const LogoStyles = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  filter: drop-shadow(2px 4px 10px var(--shadow-transparent));
  --ionic: var(--primary);

  #ionic-logo {
    #logo-big-circle {
      fill: var(--ionic);
      fill-rule: nonzero;
    }
    #logo-small-circle {
      fill: var(--ionic);
    }
    #logo-ring {
      fill: var(--ionic);
      fill-rule: nonzero;
    }
    #logo-ring-segment {
      fill: var(--black);
      fill-rule: nonzero;
      animation: segment-move 1s 1.2s ease-out both;
    }
  }

  #ionic-text {
    #ionic-i {
      fill: var(--ionic);
      fill-rule: nonzero;
    }
    #ionic-o {
      fill: var(--ionic);
      fill-rule: nonzero;
    }
    #ionic-n {
      fill: var(--ionic);
      fill-rule: nonzero;
    }
    #ionic-i2 {
      fill: var(--ionic);
      fill-rule: nonzero;
    }
    #ionic-c {
      fill: var(--ionic);
      fill-rule: nonzero;
    }
  }

  --cursor-blink-time: 0.2s;
  --cursor-delay-time: 0.2s;

  #start-text {
    #start-s {
      fill: var(--black);
      fill-rule: nonzero;
      animation: text-flash var(--cursor-blink-time) var(--cursor-delay-time)
        linear both;
    }
    #start-t {
      fill: var(--black);
      fill-rule: nonzero;
      animation: text-flash var(--cursor-blink-time)
        calc(var(--cursor-delay-time) * 2) linear both;
    }
    #start-a {
      fill: var(--black);
      fill-rule: nonzero;
      animation: text-flash var(--cursor-blink-time)
        calc(var(--cursor-delay-time) * 3) linear both;
    }
    #start-r {
      fill: var(--black);
      fill-rule: nonzero;
      animation: text-flash var(--cursor-blink-time)
        calc(var(--cursor-delay-time) * 4) linear both;
    }
    #start-t2 {
      fill: var(--black);
      fill-rule: nonzero;
      animation: text-flash var(--cursor-blink-time)
        calc(var(--cursor-delay-time) * 5) linear both;
    }
  }

  #cursors {
    #s {
      fill: var(--grey);
      animation: cursor-flash var(--cursor-blink-time) var(--cursor-delay-time)
        linear both;
    }

    #t {
      fill: var(--grey);
      animation: cursor-flash var(--cursor-blink-time)
        calc(var(--cursor-delay-time) * 2) linear both;
    }

    #a {
      fill: var(--grey);
      animation: cursor-flash var(--cursor-blink-time)
        calc(var(--cursor-delay-time) * 3) linear both;
    }

    #r {
      fill: var(--grey);
      animation: cursor-flash var(--cursor-blink-time)
        calc(var(--cursor-delay-time) * 4) linear both;
    }

    #t1 {
      fill: var(--grey);
      animation: cursor-flash var(--cursor-blink-time)
        calc(var(--cursor-delay-time) * 5) linear both;
    }

    #end {
      fill: var(--grey);
      animation: cursor-flash 1.3s calc(var(--cursor-delay-time) * 6) linear
        both infinite;
    }
  }

  @keyframes cursor-flash {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes text-flash {
    0% {
      fill: var(--grey);
    }

    50% {
      fill: #fff;
    }

    100% {
      fill: var(--black);
    }
  }

  @keyframes segment-move {
    0% {
      transform: translate(-1px, -3px);
      fill: var(--ionic);
    }

    100% {
      transform: none;
      fill: var(--black);
    }
  }
`;

export const IonicStartLogoIconOnly = () => {
  return (
    <LogoStyles width="100%" height="100%" viewBox="0 0 100 100" version="1.1">
      <g id="ionic-logo">
        <path
          id="logo-big-circle"
          d="M49.253,26.828C36.939,26.828 26.807,36.96 26.807,49.273C26.807,61.587 36.939,71.719 49.253,71.719C61.566,71.719 71.699,61.587 71.699,49.273C71.684,36.966 61.561,26.842 49.253,26.828Z"
        />
        <circle id="logo-small-circle" cx="81.481" cy="18.593" r="10.225" />
        <path
          id="logo-ring"
          d="M20.718,89.295C8.211,80.325 0,65.673 0,49.253C0,22.237 22.229,0.004 49.246,0C57.105,-0.02 64.853,1.863 71.826,5.488L72.744,5.963L71.94,6.633C69.959,8.215 68.398,10.261 67.397,12.59L67.122,13.26L66.492,12.958C61.096,10.396 55.197,9.067 49.223,9.067C27.136,9.067 8.962,27.241 8.962,49.328C8.962,62.737 15.66,74.704 25.864,82.036L20.718,89.295ZM83.843,69.771C87.423,63.762 89.485,56.767 89.485,49.328C89.485,44.022 88.436,38.769 86.399,33.87L86.131,33.2L86.801,32.958C89.176,32.072 91.296,30.612 92.972,28.711L93.642,27.933L94.07,28.871L94.064,28.811C96.991,35.228 98.506,42.2 98.506,49.253C98.506,58.562 95.868,67.302 91.305,74.759L83.843,69.771Z"
        />
        <path
          id="logo-ring-segment"
          d="M85.948,75.114L92.564,79.689C83.879,93.883 68.945,103.239 51.234,103.239C40.634,103.239 30.771,99.817 22.699,94.028L27.48,86.653C34.087,91.401 42.526,94.322 51.204,94.322C65.852,94.322 78.903,86.938 85.948,75.114Z"
        />
      </g>
    </LogoStyles>
  );
};

export const IonicStartLogo = () => {
  return (
    <LogoStyles width="100%" height="100%" viewBox="0 0 749 104" version="1.1">
      <g id="cursors">
        <rect id="s" x="419.521" y="2.902" width="52.896" height="80.684" />
        <rect id="t" x="476.906" y="2.902" width="52.896" height="80.684" />
        <rect id="a" x="535.377" y="2.902" width="52.896" height="80.684" />
        <rect id="r" x="593.3" y="2.902" width="52.896" height="80.684" />
        <rect id="t1" x="651.694" y="2.902" width="52.896" height="80.684" />
        <rect
          style={{ animationIterationCount: `0` }}
          id="end"
          x="704.522"
          y="2.902"
          width="43.573"
          height="80.684"
        />
      </g>
      <g id="start-text">
        <path
          id="start-s"
          d="M464.229,25.29L464.229,37.29C461.698,35.665 459.065,34.438 456.331,33.61C453.596,32.782 450.854,32.368 448.104,32.368C445.041,32.368 442.729,32.813 441.166,33.704C439.604,34.594 438.823,35.915 438.823,37.665C438.823,40.165 442.166,42.196 448.854,43.758L449.37,43.899L453.307,44.836C458.307,46.024 461.971,47.938 464.299,50.579C466.627,53.219 467.791,56.79 467.791,61.29C467.791,66.696 466.018,70.751 462.471,73.454C458.924,76.157 453.588,77.508 446.463,77.508C443.307,77.508 440.073,77.235 436.76,76.688C433.448,76.141 430.088,75.321 426.682,74.227L426.682,62.227C429.713,63.946 432.799,65.258 435.94,66.165C439.081,67.071 442.12,67.524 445.057,67.524C448.276,67.524 450.713,67.055 452.37,66.118C454.026,65.18 454.854,63.821 454.854,62.04C454.854,60.29 454.268,58.946 453.096,58.008C451.924,57.071 449.135,56.071 444.729,55.008L440.932,54.165C435.682,52.977 431.854,51.118 429.448,48.586C427.041,46.055 425.838,42.665 425.838,38.415C425.838,33.352 427.666,29.415 431.323,26.602C434.979,23.79 440.12,22.383 446.745,22.383C449.713,22.383 452.674,22.626 455.627,23.11C458.581,23.594 461.448,24.321 464.229,25.29Z"
        />
        <path
          id="start-t"
          d="M507.916,8.743L507.916,23.649L525.87,23.649L525.87,34.196L507.916,34.196L507.916,59.04C507.916,61.383 508.487,63.063 509.627,64.079C510.768,65.094 512.666,65.602 515.323,65.602L525.87,65.602L525.87,76.149L514.338,76.149C506.463,76.149 501.135,74.891 498.354,72.376C495.573,69.86 494.182,65.196 494.182,58.383L494.182,34.196L480.776,34.196L480.776,23.649L494.182,23.649L494.182,8.743L507.916,8.743Z"
        />
        <path
          id="start-a"
          d="M566.182,51.493C561.057,51.493 557.479,52.149 555.448,53.461C553.416,54.774 552.401,57.024 552.401,60.211C552.401,62.586 553.104,64.477 554.51,65.883C555.916,67.29 557.823,67.993 560.229,67.993C563.854,67.993 566.666,66.626 568.666,63.891C570.666,61.157 571.666,57.336 571.666,52.43L571.666,51.493L566.182,51.493ZM585.307,46.196L585.307,76.149L571.666,76.149L571.666,70.29C570.01,72.602 567.885,74.383 565.291,75.633C562.698,76.883 559.838,77.508 556.713,77.508C550.745,77.508 546.096,75.93 542.768,72.774C539.44,69.618 537.776,65.211 537.776,59.555C537.776,53.43 539.76,48.907 543.729,45.985C547.698,43.063 553.838,41.602 562.151,41.602L571.666,41.602L571.666,39.305C571.666,37.086 570.862,35.407 569.252,34.266C567.643,33.126 565.276,32.555 562.151,32.555C558.87,32.555 555.69,32.969 552.612,33.797C549.534,34.626 546.323,35.946 542.979,37.758L542.979,26.04C546.01,24.79 549.088,23.868 552.213,23.274C555.338,22.68 558.651,22.383 562.151,22.383C570.682,22.383 576.674,24.118 580.127,27.586C583.581,31.055 585.307,37.258 585.307,46.196Z"
        />
        <path
          id="start-r"
          d="M641.12,38.133C639.62,36.758 637.862,35.727 635.846,35.04C633.831,34.352 631.62,34.008 629.213,34.008C626.307,34.008 623.768,34.516 621.596,35.532C619.424,36.547 617.745,38.024 616.557,39.961C615.807,41.149 615.284,42.586 614.987,44.274C614.69,45.961 614.541,48.524 614.541,51.961L614.541,76.149L600.807,76.149L600.807,23.649L614.541,23.649L614.541,31.805C615.885,28.805 617.948,26.485 620.729,24.844C623.51,23.204 626.76,22.383 630.479,22.383C632.354,22.383 634.19,22.61 635.987,23.063C637.784,23.516 639.495,24.18 641.12,25.055L641.12,38.133Z"
        />
        <path
          id="start-t2"
          d="M681.307,8.743L681.307,23.649L699.26,23.649L699.26,34.196L681.307,34.196L681.307,59.04C681.307,61.383 681.877,63.063 683.018,64.079C684.159,65.094 686.057,65.602 688.713,65.602L699.26,65.602L699.26,76.149L687.729,76.149C679.854,76.149 674.526,74.891 671.745,72.376C668.963,69.86 667.573,65.196 667.573,58.383L667.573,34.196L654.166,34.196L654.166,23.649L667.573,23.649L667.573,8.743L681.307,8.743Z"
        />
      </g>
      <g id="ionic-text">
        <rect
          id="ionic-i"
          x="109.682"
          y="10.414"
          width="11.144"
          height="82.187"
        />
        <path
          id="ionic-o"
          d="M173.876,94.69C167.607,94.69 161.842,93.587 156.579,91.381C151.317,89.176 146.809,86.138 143.056,82.269C139.302,78.4 136.362,73.834 134.233,68.571C132.105,63.309 131.041,57.621 131.041,51.507C131.041,45.393 132.105,39.705 134.233,34.443C136.362,29.18 139.302,24.615 143.056,20.745C146.809,16.876 151.317,13.838 156.579,11.633C161.842,9.427 167.607,8.324 173.876,8.324C180.144,8.324 185.909,9.427 191.172,11.633C196.434,13.838 200.942,16.876 204.696,20.745C208.449,24.615 211.39,29.18 213.518,34.443C215.646,39.705 216.71,45.393 216.71,51.507C216.71,57.621 215.646,63.309 213.518,68.571C211.39,73.834 208.449,78.4 204.696,82.269C200.942,86.138 196.434,89.176 191.172,91.381C185.909,93.587 180.144,94.69 173.876,94.69ZM173.876,84.242C178.596,84.242 182.853,83.372 186.645,81.63C190.437,79.889 193.687,77.548 196.396,74.607C199.104,71.667 201.194,68.204 202.664,64.218C204.134,60.233 204.87,55.996 204.87,51.507C204.87,47.019 204.134,42.781 202.664,38.796C201.194,34.81 199.104,31.347 196.396,28.407C193.687,25.466 190.437,23.125 186.645,21.384C182.853,19.642 178.596,18.772 173.876,18.772C169.155,18.772 164.899,19.642 161.106,21.384C157.314,23.125 154.064,25.466 151.356,28.407C148.647,31.347 146.557,34.81 145.087,38.796C143.617,42.781 142.882,47.019 142.882,51.507C142.882,55.996 143.617,60.233 145.087,64.218C146.557,68.204 148.647,71.667 151.356,74.607C154.064,77.548 157.314,79.889 161.106,81.63C164.899,83.372 169.155,84.242 173.876,84.242Z"
        />
        <path
          id="ionic-n"
          d="M226.925,10.414L241.552,10.414L286.824,77.974L287.056,77.974L287.056,10.414L298.2,10.414L298.2,92.6L284.038,92.6L238.301,25.04L238.069,25.04L238.069,92.6L226.925,92.6L226.925,10.414Z"
        />
        <rect
          id="ionic-i2"
          x="313.523"
          y="10.414"
          width="11.144"
          height="82.187"
        />
        <path
          id="ionic-c"
          d="M397.219,28.174C394.897,25.156 391.956,22.835 388.396,21.209C384.837,19.584 381.161,18.772 377.369,18.772C372.725,18.772 368.527,19.642 364.774,21.384C361.02,23.125 357.809,25.505 355.139,28.523C352.469,31.541 350.399,35.081 348.928,39.144C347.458,43.207 346.723,47.56 346.723,52.204C346.723,56.537 347.419,60.639 348.812,64.508C350.205,68.378 352.217,71.783 354.849,74.724C357.48,77.664 360.691,79.986 364.483,81.689C368.275,83.391 372.57,84.242 377.369,84.242C382.089,84.242 386.23,83.275 389.789,81.34C393.349,79.406 396.367,76.697 398.844,73.214L408.247,80.296C407.627,81.147 406.544,82.366 404.996,83.952C403.448,85.539 401.398,87.125 398.844,88.712C396.29,90.298 393.214,91.691 389.615,92.891C386.017,94.09 381.857,94.69 377.136,94.69C370.636,94.69 364.774,93.452 359.55,90.975C354.326,88.499 349.876,85.248 346.2,81.224C342.524,77.2 339.719,72.653 337.784,67.584C335.85,62.516 334.882,57.389 334.882,52.204C334.882,45.858 335.927,39.996 338.017,34.617C340.106,29.239 343.027,24.595 346.781,20.687C350.534,16.779 355.042,13.741 360.304,11.575C365.567,9.408 371.371,8.324 377.717,8.324C383.134,8.324 388.454,9.369 393.678,11.459C398.902,13.548 403.178,16.76 406.505,21.093L397.219,28.174Z"
        />
      </g>
      <g id="ionic-logo">
        <path
          id="logo-big-circle"
          d="M49.253,26.828C36.939,26.828 26.807,36.96 26.807,49.273C26.807,61.587 36.939,71.719 49.253,71.719C61.566,71.719 71.699,61.587 71.699,49.273C71.684,36.966 61.561,26.842 49.253,26.828Z"
        />
        <circle id="logo-small-circle" cx="81.481" cy="18.593" r="10.225" />
        <path
          id="logo-ring"
          d="M20.718,89.295C8.211,80.325 0,65.673 0,49.253C0,22.237 22.229,0.004 49.246,0C57.105,-0.02 64.853,1.863 71.826,5.488L72.744,5.963L71.94,6.633C69.959,8.215 68.398,10.261 67.397,12.59L67.122,13.26L66.492,12.958C61.096,10.396 55.197,9.067 49.223,9.067C27.136,9.067 8.962,27.241 8.962,49.328C8.962,62.737 15.66,74.704 25.864,82.036L20.718,89.295ZM83.843,69.771C87.423,63.762 89.485,56.767 89.485,49.328C89.485,44.022 88.436,38.769 86.399,33.87L86.131,33.2L86.801,32.958C89.176,32.072 91.296,30.612 92.972,28.711L93.642,27.933L94.07,28.871L94.064,28.811C96.991,35.228 98.506,42.2 98.506,49.253C98.506,58.562 95.868,67.302 91.305,74.759L83.843,69.771Z"
        />
        <path
          id="logo-ring-segment"
          d="M85.948,75.114L92.564,79.689C83.879,93.883 68.945,103.239 51.234,103.239C40.634,103.239 30.771,99.817 22.699,94.028L27.48,86.653C34.087,91.401 42.526,94.322 51.204,94.322C65.852,94.322 78.903,86.938 85.948,75.114Z"
        />
      </g>
    </LogoStyles>
  );
};

export const IonicStartLogoWhite = () => {
  return (
    <LogoStyles width="100%" height="100%" viewBox="0 0 749 104" version="1.1">
      <g id="cursors">
        <rect id="s" x="419.521" y="2.902" width="52.896" height="80.684" />
        <rect id="t" x="476.906" y="2.902" width="52.896" height="80.684" />
        <rect id="a" x="535.377" y="2.902" width="52.896" height="80.684" />
        <rect id="r" x="593.3" y="2.902" width="52.896" height="80.684" />
        <rect id="t1" x="651.694" y="2.902" width="52.896" height="80.684" />
        <rect
          style={{ animationIterationCount: `0` }}
          id="end"
          x="704.522"
          y="2.902"
          width="43.573"
          height="80.684"
        />
      </g>
      <g id="start-text">
        <path
          id="start-s"
          d="M464.229,25.29L464.229,37.29C461.698,35.665 459.065,34.438 456.331,33.61C453.596,32.782 450.854,32.368 448.104,32.368C445.041,32.368 442.729,32.813 441.166,33.704C439.604,34.594 438.823,35.915 438.823,37.665C438.823,40.165 442.166,42.196 448.854,43.758L449.37,43.899L453.307,44.836C458.307,46.024 461.971,47.938 464.299,50.579C466.627,53.219 467.791,56.79 467.791,61.29C467.791,66.696 466.018,70.751 462.471,73.454C458.924,76.157 453.588,77.508 446.463,77.508C443.307,77.508 440.073,77.235 436.76,76.688C433.448,76.141 430.088,75.321 426.682,74.227L426.682,62.227C429.713,63.946 432.799,65.258 435.94,66.165C439.081,67.071 442.12,67.524 445.057,67.524C448.276,67.524 450.713,67.055 452.37,66.118C454.026,65.18 454.854,63.821 454.854,62.04C454.854,60.29 454.268,58.946 453.096,58.008C451.924,57.071 449.135,56.071 444.729,55.008L440.932,54.165C435.682,52.977 431.854,51.118 429.448,48.586C427.041,46.055 425.838,42.665 425.838,38.415C425.838,33.352 427.666,29.415 431.323,26.602C434.979,23.79 440.12,22.383 446.745,22.383C449.713,22.383 452.674,22.626 455.627,23.11C458.581,23.594 461.448,24.321 464.229,25.29Z"
        />
        <path
          id="start-t"
          d="M507.916,8.743L507.916,23.649L525.87,23.649L525.87,34.196L507.916,34.196L507.916,59.04C507.916,61.383 508.487,63.063 509.627,64.079C510.768,65.094 512.666,65.602 515.323,65.602L525.87,65.602L525.87,76.149L514.338,76.149C506.463,76.149 501.135,74.891 498.354,72.376C495.573,69.86 494.182,65.196 494.182,58.383L494.182,34.196L480.776,34.196L480.776,23.649L494.182,23.649L494.182,8.743L507.916,8.743Z"
        />
        <path
          id="start-a"
          d="M566.182,51.493C561.057,51.493 557.479,52.149 555.448,53.461C553.416,54.774 552.401,57.024 552.401,60.211C552.401,62.586 553.104,64.477 554.51,65.883C555.916,67.29 557.823,67.993 560.229,67.993C563.854,67.993 566.666,66.626 568.666,63.891C570.666,61.157 571.666,57.336 571.666,52.43L571.666,51.493L566.182,51.493ZM585.307,46.196L585.307,76.149L571.666,76.149L571.666,70.29C570.01,72.602 567.885,74.383 565.291,75.633C562.698,76.883 559.838,77.508 556.713,77.508C550.745,77.508 546.096,75.93 542.768,72.774C539.44,69.618 537.776,65.211 537.776,59.555C537.776,53.43 539.76,48.907 543.729,45.985C547.698,43.063 553.838,41.602 562.151,41.602L571.666,41.602L571.666,39.305C571.666,37.086 570.862,35.407 569.252,34.266C567.643,33.126 565.276,32.555 562.151,32.555C558.87,32.555 555.69,32.969 552.612,33.797C549.534,34.626 546.323,35.946 542.979,37.758L542.979,26.04C546.01,24.79 549.088,23.868 552.213,23.274C555.338,22.68 558.651,22.383 562.151,22.383C570.682,22.383 576.674,24.118 580.127,27.586C583.581,31.055 585.307,37.258 585.307,46.196Z"
        />
        <path
          id="start-r"
          d="M641.12,38.133C639.62,36.758 637.862,35.727 635.846,35.04C633.831,34.352 631.62,34.008 629.213,34.008C626.307,34.008 623.768,34.516 621.596,35.532C619.424,36.547 617.745,38.024 616.557,39.961C615.807,41.149 615.284,42.586 614.987,44.274C614.69,45.961 614.541,48.524 614.541,51.961L614.541,76.149L600.807,76.149L600.807,23.649L614.541,23.649L614.541,31.805C615.885,28.805 617.948,26.485 620.729,24.844C623.51,23.204 626.76,22.383 630.479,22.383C632.354,22.383 634.19,22.61 635.987,23.063C637.784,23.516 639.495,24.18 641.12,25.055L641.12,38.133Z"
        />
        <path
          id="start-t2"
          d="M681.307,8.743L681.307,23.649L699.26,23.649L699.26,34.196L681.307,34.196L681.307,59.04C681.307,61.383 681.877,63.063 683.018,64.079C684.159,65.094 686.057,65.602 688.713,65.602L699.26,65.602L699.26,76.149L687.729,76.149C679.854,76.149 674.526,74.891 671.745,72.376C668.963,69.86 667.573,65.196 667.573,58.383L667.573,34.196L654.166,34.196L654.166,23.649L667.573,23.649L667.573,8.743L681.307,8.743Z"
        />
      </g>
      <g id="ionic-text">
        <rect
          id="ionic-i"
          x="109.682"
          y="10.414"
          width="11.144"
          height="82.187"
          style={{ fill: `#ffffff` }}
        />
        <path
          style={{ fill: `#ffffff` }}
          id="ionic-o"
          d="M173.876,94.69C167.607,94.69 161.842,93.587 156.579,91.381C151.317,89.176 146.809,86.138 143.056,82.269C139.302,78.4 136.362,73.834 134.233,68.571C132.105,63.309 131.041,57.621 131.041,51.507C131.041,45.393 132.105,39.705 134.233,34.443C136.362,29.18 139.302,24.615 143.056,20.745C146.809,16.876 151.317,13.838 156.579,11.633C161.842,9.427 167.607,8.324 173.876,8.324C180.144,8.324 185.909,9.427 191.172,11.633C196.434,13.838 200.942,16.876 204.696,20.745C208.449,24.615 211.39,29.18 213.518,34.443C215.646,39.705 216.71,45.393 216.71,51.507C216.71,57.621 215.646,63.309 213.518,68.571C211.39,73.834 208.449,78.4 204.696,82.269C200.942,86.138 196.434,89.176 191.172,91.381C185.909,93.587 180.144,94.69 173.876,94.69ZM173.876,84.242C178.596,84.242 182.853,83.372 186.645,81.63C190.437,79.889 193.687,77.548 196.396,74.607C199.104,71.667 201.194,68.204 202.664,64.218C204.134,60.233 204.87,55.996 204.87,51.507C204.87,47.019 204.134,42.781 202.664,38.796C201.194,34.81 199.104,31.347 196.396,28.407C193.687,25.466 190.437,23.125 186.645,21.384C182.853,19.642 178.596,18.772 173.876,18.772C169.155,18.772 164.899,19.642 161.106,21.384C157.314,23.125 154.064,25.466 151.356,28.407C148.647,31.347 146.557,34.81 145.087,38.796C143.617,42.781 142.882,47.019 142.882,51.507C142.882,55.996 143.617,60.233 145.087,64.218C146.557,68.204 148.647,71.667 151.356,74.607C154.064,77.548 157.314,79.889 161.106,81.63C164.899,83.372 169.155,84.242 173.876,84.242Z"
        />
        <path
          style={{ fill: `#ffffff` }}
          id="ionic-n"
          d="M226.925,10.414L241.552,10.414L286.824,77.974L287.056,77.974L287.056,10.414L298.2,10.414L298.2,92.6L284.038,92.6L238.301,25.04L238.069,25.04L238.069,92.6L226.925,92.6L226.925,10.414Z"
        />
        <rect
          style={{ fill: `#ffffff` }}
          id="ionic-i2"
          x="313.523"
          y="10.414"
          width="11.144"
          height="82.187"
        />
        <path
          style={{ fill: `#ffffff` }}
          id="ionic-c"
          d="M397.219,28.174C394.897,25.156 391.956,22.835 388.396,21.209C384.837,19.584 381.161,18.772 377.369,18.772C372.725,18.772 368.527,19.642 364.774,21.384C361.02,23.125 357.809,25.505 355.139,28.523C352.469,31.541 350.399,35.081 348.928,39.144C347.458,43.207 346.723,47.56 346.723,52.204C346.723,56.537 347.419,60.639 348.812,64.508C350.205,68.378 352.217,71.783 354.849,74.724C357.48,77.664 360.691,79.986 364.483,81.689C368.275,83.391 372.57,84.242 377.369,84.242C382.089,84.242 386.23,83.275 389.789,81.34C393.349,79.406 396.367,76.697 398.844,73.214L408.247,80.296C407.627,81.147 406.544,82.366 404.996,83.952C403.448,85.539 401.398,87.125 398.844,88.712C396.29,90.298 393.214,91.691 389.615,92.891C386.017,94.09 381.857,94.69 377.136,94.69C370.636,94.69 364.774,93.452 359.55,90.975C354.326,88.499 349.876,85.248 346.2,81.224C342.524,77.2 339.719,72.653 337.784,67.584C335.85,62.516 334.882,57.389 334.882,52.204C334.882,45.858 335.927,39.996 338.017,34.617C340.106,29.239 343.027,24.595 346.781,20.687C350.534,16.779 355.042,13.741 360.304,11.575C365.567,9.408 371.371,8.324 377.717,8.324C383.134,8.324 388.454,9.369 393.678,11.459C398.902,13.548 403.178,16.76 406.505,21.093L397.219,28.174Z"
        />
      </g>
      <g id="ionic-logo">
        <path
          style={{ fill: `#ffffff` }}
          id="logo-big-circle"
          d="M49.253,26.828C36.939,26.828 26.807,36.96 26.807,49.273C26.807,61.587 36.939,71.719 49.253,71.719C61.566,71.719 71.699,61.587 71.699,49.273C71.684,36.966 61.561,26.842 49.253,26.828Z"
        />
        <circle
          style={{ fill: `#ffffff` }}
          id="logo-small-circle"
          cx="81.481"
          cy="18.593"
          r="10.225"
        />
        <path
          style={{ fill: `#ffffff` }}
          id="logo-ring"
          d="M20.718,89.295C8.211,80.325 0,65.673 0,49.253C0,22.237 22.229,0.004 49.246,0C57.105,-0.02 64.853,1.863 71.826,5.488L72.744,5.963L71.94,6.633C69.959,8.215 68.398,10.261 67.397,12.59L67.122,13.26L66.492,12.958C61.096,10.396 55.197,9.067 49.223,9.067C27.136,9.067 8.962,27.241 8.962,49.328C8.962,62.737 15.66,74.704 25.864,82.036L20.718,89.295ZM83.843,69.771C87.423,63.762 89.485,56.767 89.485,49.328C89.485,44.022 88.436,38.769 86.399,33.87L86.131,33.2L86.801,32.958C89.176,32.072 91.296,30.612 92.972,28.711L93.642,27.933L94.07,28.871L94.064,28.811C96.991,35.228 98.506,42.2 98.506,49.253C98.506,58.562 95.868,67.302 91.305,74.759L83.843,69.771Z"
        />
        <path
          style={{ fill: `#ffffff` }}
          id="logo-ring-segment"
          d="M85.948,75.114L92.564,79.689C83.879,93.883 68.945,103.239 51.234,103.239C40.634,103.239 30.771,99.817 22.699,94.028L27.48,86.653C34.087,91.401 42.526,94.322 51.204,94.322C65.852,94.322 78.903,86.938 85.948,75.114Z"
        />
      </g>
    </LogoStyles>
  );
};

export const IonicStartLogoAnimated = () => {
  return (
    <LogoStyles width="100%" height="100%" viewBox="0 0 749 104" version="1.1">
      <g id="cursors">
        <rect id="s" x="419.521" y="2.902" width="52.896" height="80.684" />
        <rect id="t" x="476.906" y="2.902" width="52.896" height="80.684" />
        <rect id="a" x="535.377" y="2.902" width="52.896" height="80.684" />
        <rect id="r" x="593.3" y="2.902" width="52.896" height="80.684" />
        <rect id="t1" x="651.694" y="2.902" width="52.896" height="80.684" />
        <rect id="end" x="704.522" y="2.902" width="43.573" height="80.684" />
      </g>
      <g id="start-text">
        <path
          id="start-s"
          d="M464.229,25.29L464.229,37.29C461.698,35.665 459.065,34.438 456.331,33.61C453.596,32.782 450.854,32.368 448.104,32.368C445.041,32.368 442.729,32.813 441.166,33.704C439.604,34.594 438.823,35.915 438.823,37.665C438.823,40.165 442.166,42.196 448.854,43.758L449.37,43.899L453.307,44.836C458.307,46.024 461.971,47.938 464.299,50.579C466.627,53.219 467.791,56.79 467.791,61.29C467.791,66.696 466.018,70.751 462.471,73.454C458.924,76.157 453.588,77.508 446.463,77.508C443.307,77.508 440.073,77.235 436.76,76.688C433.448,76.141 430.088,75.321 426.682,74.227L426.682,62.227C429.713,63.946 432.799,65.258 435.94,66.165C439.081,67.071 442.12,67.524 445.057,67.524C448.276,67.524 450.713,67.055 452.37,66.118C454.026,65.18 454.854,63.821 454.854,62.04C454.854,60.29 454.268,58.946 453.096,58.008C451.924,57.071 449.135,56.071 444.729,55.008L440.932,54.165C435.682,52.977 431.854,51.118 429.448,48.586C427.041,46.055 425.838,42.665 425.838,38.415C425.838,33.352 427.666,29.415 431.323,26.602C434.979,23.79 440.12,22.383 446.745,22.383C449.713,22.383 452.674,22.626 455.627,23.11C458.581,23.594 461.448,24.321 464.229,25.29Z"
        />
        <path
          id="start-t"
          d="M507.916,8.743L507.916,23.649L525.87,23.649L525.87,34.196L507.916,34.196L507.916,59.04C507.916,61.383 508.487,63.063 509.627,64.079C510.768,65.094 512.666,65.602 515.323,65.602L525.87,65.602L525.87,76.149L514.338,76.149C506.463,76.149 501.135,74.891 498.354,72.376C495.573,69.86 494.182,65.196 494.182,58.383L494.182,34.196L480.776,34.196L480.776,23.649L494.182,23.649L494.182,8.743L507.916,8.743Z"
        />
        <path
          id="start-a"
          d="M566.182,51.493C561.057,51.493 557.479,52.149 555.448,53.461C553.416,54.774 552.401,57.024 552.401,60.211C552.401,62.586 553.104,64.477 554.51,65.883C555.916,67.29 557.823,67.993 560.229,67.993C563.854,67.993 566.666,66.626 568.666,63.891C570.666,61.157 571.666,57.336 571.666,52.43L571.666,51.493L566.182,51.493ZM585.307,46.196L585.307,76.149L571.666,76.149L571.666,70.29C570.01,72.602 567.885,74.383 565.291,75.633C562.698,76.883 559.838,77.508 556.713,77.508C550.745,77.508 546.096,75.93 542.768,72.774C539.44,69.618 537.776,65.211 537.776,59.555C537.776,53.43 539.76,48.907 543.729,45.985C547.698,43.063 553.838,41.602 562.151,41.602L571.666,41.602L571.666,39.305C571.666,37.086 570.862,35.407 569.252,34.266C567.643,33.126 565.276,32.555 562.151,32.555C558.87,32.555 555.69,32.969 552.612,33.797C549.534,34.626 546.323,35.946 542.979,37.758L542.979,26.04C546.01,24.79 549.088,23.868 552.213,23.274C555.338,22.68 558.651,22.383 562.151,22.383C570.682,22.383 576.674,24.118 580.127,27.586C583.581,31.055 585.307,37.258 585.307,46.196Z"
        />
        <path
          id="start-r"
          d="M641.12,38.133C639.62,36.758 637.862,35.727 635.846,35.04C633.831,34.352 631.62,34.008 629.213,34.008C626.307,34.008 623.768,34.516 621.596,35.532C619.424,36.547 617.745,38.024 616.557,39.961C615.807,41.149 615.284,42.586 614.987,44.274C614.69,45.961 614.541,48.524 614.541,51.961L614.541,76.149L600.807,76.149L600.807,23.649L614.541,23.649L614.541,31.805C615.885,28.805 617.948,26.485 620.729,24.844C623.51,23.204 626.76,22.383 630.479,22.383C632.354,22.383 634.19,22.61 635.987,23.063C637.784,23.516 639.495,24.18 641.12,25.055L641.12,38.133Z"
        />
        <path
          id="start-t2"
          d="M681.307,8.743L681.307,23.649L699.26,23.649L699.26,34.196L681.307,34.196L681.307,59.04C681.307,61.383 681.877,63.063 683.018,64.079C684.159,65.094 686.057,65.602 688.713,65.602L699.26,65.602L699.26,76.149L687.729,76.149C679.854,76.149 674.526,74.891 671.745,72.376C668.963,69.86 667.573,65.196 667.573,58.383L667.573,34.196L654.166,34.196L654.166,23.649L667.573,23.649L667.573,8.743L681.307,8.743Z"
        />
      </g>
      <g id="ionic-text">
        <rect
          id="ionic-i"
          x="109.682"
          y="10.414"
          width="11.144"
          height="82.187"
        />
        <path
          id="ionic-o"
          d="M173.876,94.69C167.607,94.69 161.842,93.587 156.579,91.381C151.317,89.176 146.809,86.138 143.056,82.269C139.302,78.4 136.362,73.834 134.233,68.571C132.105,63.309 131.041,57.621 131.041,51.507C131.041,45.393 132.105,39.705 134.233,34.443C136.362,29.18 139.302,24.615 143.056,20.745C146.809,16.876 151.317,13.838 156.579,11.633C161.842,9.427 167.607,8.324 173.876,8.324C180.144,8.324 185.909,9.427 191.172,11.633C196.434,13.838 200.942,16.876 204.696,20.745C208.449,24.615 211.39,29.18 213.518,34.443C215.646,39.705 216.71,45.393 216.71,51.507C216.71,57.621 215.646,63.309 213.518,68.571C211.39,73.834 208.449,78.4 204.696,82.269C200.942,86.138 196.434,89.176 191.172,91.381C185.909,93.587 180.144,94.69 173.876,94.69ZM173.876,84.242C178.596,84.242 182.853,83.372 186.645,81.63C190.437,79.889 193.687,77.548 196.396,74.607C199.104,71.667 201.194,68.204 202.664,64.218C204.134,60.233 204.87,55.996 204.87,51.507C204.87,47.019 204.134,42.781 202.664,38.796C201.194,34.81 199.104,31.347 196.396,28.407C193.687,25.466 190.437,23.125 186.645,21.384C182.853,19.642 178.596,18.772 173.876,18.772C169.155,18.772 164.899,19.642 161.106,21.384C157.314,23.125 154.064,25.466 151.356,28.407C148.647,31.347 146.557,34.81 145.087,38.796C143.617,42.781 142.882,47.019 142.882,51.507C142.882,55.996 143.617,60.233 145.087,64.218C146.557,68.204 148.647,71.667 151.356,74.607C154.064,77.548 157.314,79.889 161.106,81.63C164.899,83.372 169.155,84.242 173.876,84.242Z"
        />
        <path
          id="ionic-n"
          d="M226.925,10.414L241.552,10.414L286.824,77.974L287.056,77.974L287.056,10.414L298.2,10.414L298.2,92.6L284.038,92.6L238.301,25.04L238.069,25.04L238.069,92.6L226.925,92.6L226.925,10.414Z"
        />
        <rect
          id="ionic-i2"
          x="313.523"
          y="10.414"
          width="11.144"
          height="82.187"
        />
        <path
          id="ionic-c"
          d="M397.219,28.174C394.897,25.156 391.956,22.835 388.396,21.209C384.837,19.584 381.161,18.772 377.369,18.772C372.725,18.772 368.527,19.642 364.774,21.384C361.02,23.125 357.809,25.505 355.139,28.523C352.469,31.541 350.399,35.081 348.928,39.144C347.458,43.207 346.723,47.56 346.723,52.204C346.723,56.537 347.419,60.639 348.812,64.508C350.205,68.378 352.217,71.783 354.849,74.724C357.48,77.664 360.691,79.986 364.483,81.689C368.275,83.391 372.57,84.242 377.369,84.242C382.089,84.242 386.23,83.275 389.789,81.34C393.349,79.406 396.367,76.697 398.844,73.214L408.247,80.296C407.627,81.147 406.544,82.366 404.996,83.952C403.448,85.539 401.398,87.125 398.844,88.712C396.29,90.298 393.214,91.691 389.615,92.891C386.017,94.09 381.857,94.69 377.136,94.69C370.636,94.69 364.774,93.452 359.55,90.975C354.326,88.499 349.876,85.248 346.2,81.224C342.524,77.2 339.719,72.653 337.784,67.584C335.85,62.516 334.882,57.389 334.882,52.204C334.882,45.858 335.927,39.996 338.017,34.617C340.106,29.239 343.027,24.595 346.781,20.687C350.534,16.779 355.042,13.741 360.304,11.575C365.567,9.408 371.371,8.324 377.717,8.324C383.134,8.324 388.454,9.369 393.678,11.459C398.902,13.548 403.178,16.76 406.505,21.093L397.219,28.174Z"
        />
      </g>
      <g id="ionic-logo">
        <path
          id="logo-big-circle"
          d="M49.253,26.828C36.939,26.828 26.807,36.96 26.807,49.273C26.807,61.587 36.939,71.719 49.253,71.719C61.566,71.719 71.699,61.587 71.699,49.273C71.684,36.966 61.561,26.842 49.253,26.828Z"
        />
        <circle id="logo-small-circle" cx="81.481" cy="18.593" r="10.225" />
        <path
          id="logo-ring"
          d="M20.718,89.295C8.211,80.325 0,65.673 0,49.253C0,22.237 22.229,0.004 49.246,0C57.105,-0.02 64.853,1.863 71.826,5.488L72.744,5.963L71.94,6.633C69.959,8.215 68.398,10.261 67.397,12.59L67.122,13.26L66.492,12.958C61.096,10.396 55.197,9.067 49.223,9.067C27.136,9.067 8.962,27.241 8.962,49.328C8.962,62.737 15.66,74.704 25.864,82.036L20.718,89.295ZM83.843,69.771C87.423,63.762 89.485,56.767 89.485,49.328C89.485,44.022 88.436,38.769 86.399,33.87L86.131,33.2L86.801,32.958C89.176,32.072 91.296,30.612 92.972,28.711L93.642,27.933L94.07,28.871L94.064,28.811C96.991,35.228 98.506,42.2 98.506,49.253C98.506,58.562 95.868,67.302 91.305,74.759L83.843,69.771Z"
        />
        <path
          id="logo-ring-segment"
          d="M85.948,75.114L92.564,79.689C83.879,93.883 68.945,103.239 51.234,103.239C40.634,103.239 30.771,99.817 22.699,94.028L27.48,86.653C34.087,91.401 42.526,94.322 51.204,94.322C65.852,94.322 78.903,86.938 85.948,75.114Z"
        />
      </g>
    </LogoStyles>
  );
};
