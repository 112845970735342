import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Helmet from 'react-helmet';
import styled from 'styled-components';

const PromoStyles = styled.div`
  position: fixed;
  height: 60px;
  top: 0px;
  width: 100%;
  z-index: 100;
  text-align: center;
  opacity: 0.95;
  background-color: var(--danger);
  font-size: 18px;
  color: var(--white);
  padding: 16px 0;
  z-index: 200;

  a.pro {
    display: inline-block;
  }

  .buy-link:hover {
    box-shadow: 3px 3px var(--shadow) !important;
  }
`;

export interface PromoExpireTime {
  day: number;
  month: number;
  year: number;
  hour: number;
  minute: number;
}

export interface PromoContent {
  message: JSX.Element;
  buttonContent: JSX.Element;
}

export interface PromoBannerProps {
  link: string;
  expires: PromoExpireTime;
  content: PromoContent;
}

export const PromoBanner = ({ expires, content, link }: PromoBannerProps) => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <Helmet>
        <script>
          {`
var countDownDate = new Date(Date.UTC(${expires.year}, ${expires.month - 1}, ${
            expires.day
          }, ${expires.hour}, ${expires.minute}, 0));
var x = setInterval(function() {

  var element = document.getElementById("countdown");

  var now = new Date().getTime();
  var distance = countDownDate - now;

  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + (days * 24);
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  if(element !== null){
    element.innerHTML = hours + "h : "
    + minutes + "m : " + seconds + "s";
  }


  // If the count down is finished, write some text 
  if (distance < 0) {
    clearInterval(x);
    if(element !== null){
      element.innerHTML = "EXPIRED";
    }
  }
}, 1000);
`}
        </script>
      </Helmet>
      {open && (
        <div className="d-none d-md-block">
          <PromoStyles>
            <span style={{ textTransform: `uppercase`, color: `var(--white)` }}>
              {content.message}{' '}
              <span style={{ fontWeight: `bold` }} id="countdown">
                ...
              </span>
            </span>
            <a
              className="pro"
              href={link}
              style={{ marginLeft: `1rem`, position: `relative`, top: `-3px` }}
            >
              <span
                className="badge buy-link"
                style={{
                  padding: `.7rem 1rem`,
                  boxShadow: `2px 2px var(--shadow)`,
                }}
              >
                {content.buttonContent}
              </span>
            </a>
            <span
              style={{ color: `#000`, position: `absolute`, right: `20px` }}
              role="button"
              tabIndex={0}
              onClick={() => {
                setOpen(false);
              }}
            >
              <FaTimes />
            </span>
          </PromoStyles>
          <div style={{ height: `60px` }}></div>
        </div>
      )}
    </>
  );
};
