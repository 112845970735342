import { useEffect } from 'react';

export const useAuthGuard = (
  isPreview: boolean,
  basePath: string,
  isAuthenticated: () => boolean,
  logout: () => void
) => {
  useEffect(() => {
    if (isPreview && isAuthenticated()) {
      if (window.location) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('r')) {
          // Cookie has invalid JWT, clear it to prevent loop
          logout();
        } else {
          setTimeout(() => {
            window.location.replace(`/${basePath}${window.location.pathname}`);
          }, 200);
        }
      }
    }
  });
};
