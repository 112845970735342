import React from 'react';
import styled from 'styled-components';
import { ModuleCard } from '../module-card/module-card';

const ModulesGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

export interface ModuleFeedProps {
  modules: any[];
}

export const ModuleFeed = ({ modules }: ModuleFeedProps) => {
  modules.sort((a, b) => a.frontmatter.order - b.frontmatter.order);

  return (
    <ModulesGrid>
      {modules.map((module) => {
        return <ModuleCard key={module.id} module={module} />;
      })}
    </ModulesGrid>
  );
};
