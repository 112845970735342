import React from 'react';

interface TableOfContentsItem {
  url: string;
  title: string;
  items?: TableOfContentsItem[];
}

interface TableOfContentsData {
  items: TableOfContentsItem[];
}

export interface TOCProps {
  contents: TableOfContentsData;
}

export const TOC = ({ contents }: TOCProps) => {
  return (
    <div className="table-of-contents">
      <ol>
        {contents.items.map((item) => (
          <li>
            <p>
              <a href={item.url}>{item.title}</a>
            </p>
            {item.items && (
              <ol>
                {item.items.map((innerItem) => (
                  <li>
                    <a href={innerItem.url}>{innerItem.title}</a>
                  </li>
                ))}
              </ol>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};
