import { useState } from 'react';

export const useForm = (defaults: any) => {
  const [values, setValues] = useState(defaults);

  const updateValue = (e: any) => {
    let value = e.target.value;
    if (e.target.type === 'number') {
      value = parseInt(value);
    }

    let allowedCharactersRegex = /^[0-9a-zA-Z-]*$/;

    if (e.target.name === 'order') {
      allowedCharactersRegex = /^[0-9]*$/;
    }

    if (allowedCharactersRegex.test(value)) {
      setValues({
        ...values,
        [e.target.name]: value,
      });
    }
  };

  return { values, updateValue };
};

// const { values, updateValue } = useForm({
//     name: '',
//     email: ''
// })
