import { SkeletonBars } from '@sites/shared/ui/general';
import { Link } from 'gatsby';
import React from 'react';
import { FaKey } from 'react-icons/fa';
import styled from 'styled-components';

export interface ProMembershipBoxProps {
  lesson: GatsbyTypes.ModuleLessonFragment;
  location: any;
  previewAmount?: number;
  site: GatsbyTypes.SiteInformationFragment;
}

const ProMembership = styled.div`
  margin-bottom: 10rem;
  text-align: center;

  position: relative;
  top: -550px;

  svg {
    font-size: 5rem;
    margin: 1rem 1rem 3rem 1rem;
  }

  .fancy-box-outer {
    height: initial;
    box-shadow: 0px 0px 30px 0px var(--shadow-transparent);
  }

  .fancy-box-inner {
    padding: 4rem;
  }
`;

export const ProMembershipBox = ({
  lesson,
  location,
  previewAmount,
  site,
}: ProMembershipBoxProps) => {
  if (!(lesson.wordCount?.words && lesson.frontmatter)) return <></>;

  const amountToIncludeInPreview = previewAmount ?? 0.3;
  const skeletonBarAmount = Math.floor(
    (lesson.wordCount.words / 10) * (1 - amountToIncludeInPreview)
  );

  return (
    <>
      <ProMembership>
        <div className="fancy-box-outer">
          <div className="fancy-box-inner">
            <span
              style={{
                position: `absolute`,
                top: `2rem`,
                right: `2rem`,
              }}
              className="badge secondary-background"
            >
              {lesson.frontmatter.path?.toUpperCase()}
            </span>
            <FaKey />
            {lesson.frontmatter.video !== null ? (
              <p>Sorry, there are no previews available for video lessons!</p>
            ) : (
              <p>Thanks for checking out the preview of this lesson!</p>
            )}

            <p>
              You do not have the appropriate membership to view the full
              lesson. If you would like full access to this{' '}
              <strong>module</strong> you can{' '}
              <Link
                to={`/membership/?p=${encodeURIComponent(
                  site.siteMetadata.siteUrl +
                    location.pathname.replace(
                      '/modules',
                      `/${lesson.frontmatter.path}/modules`
                    )
                )}`}
              >
                view membership options
              </Link>{' '}
              (or{' '}
              <Link
                to={`/membership/?p=${encodeURIComponent(
                  site.siteMetadata.siteUrl +
                    location.pathname.replace(
                      '/modules',
                      `/${lesson.frontmatter.path}/modules`
                    )
                )}&#login`}
              >
                log in
              </Link>{' '}
              if you are already have an appropriate membership).
            </p>
          </div>
        </div>
      </ProMembership>
      <div style={{ position: `relative`, top: `-500px` }}>
        <SkeletonBars amount={skeletonBarAmount} />
      </div>
    </>
  );
};
