import { Link, PageProps } from 'gatsby';
import styled from 'styled-components';
import React from 'react';

export interface LessonContentSeriesProps {
  pageProps: Pick<PageProps, 'data' | 'location'>;
  basePath: string;
}

const LessonContentSeriesStyles = styled.nav`
  background-color: var(--lightest);
  border: 2px dashed var(--primary);
  margin-bottom: 4rem;
  padding: 4rem;

  li {
    font-size: 0.9em;
    margin-bottom: 1rem;

    a {
      box-shadow: inset 0px -3px var(--primary);
    }
  }
`;

export const LessonContentSeries = ({
  pageProps: { data, location },
  basePath,
}: LessonContentSeriesProps) => {
  const { othersInSeries } = data as GatsbyTypes.SingleLessonPageDataQuery;

  return (
    <LessonContentSeriesStyles>
      <p>This is part of a series, check out the other parts below:</p>
      <ol>
        {othersInSeries.nodes.map((other) => {
          if (!other?.frontmatter?.slug) return <></>;

          const active =
            basePath + other.frontmatter.slug === location.pathname;

          return (
            <li key={other.id}>
              {active ? (
                <span>{other.frontmatter.title}</span>
              ) : (
                <Link
                  to={`/${basePath}/${other.frontmatter.slug}/`}
                  className="special-link"
                >
                  {other.frontmatter.title}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </LessonContentSeriesStyles>
  );
};
