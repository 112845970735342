import React from 'react';
import { Link } from 'gatsby';
import { Author, ReadTime } from '@sites/shared/ui/general';
import { Tag } from '../../index';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const ContentHeader = styled.header`
  position: relative;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 7px 0px var(--shadow-slight-transparent);
`;

const ContentBody = styled.div`
  padding: 4rem;
  margin-bottom: 5rem;
`;

const ContentFooter = styled.footer`
  padding: 1rem;
  background-color: var(--lighter);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ContentFeedGrid = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(2, minmax(300px, 1fr));

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  & > article {
    background-color: var(--lightest);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 5px 5px var(--primary);
  }

  img {
    width: 100%;
    height: auto;
  }
`;

export interface ContentFeedProps {
  items: readonly any[];
  basePath?: string;
}

export const ContentFeed = ({ items, basePath }: ContentFeedProps) => {
  const path = basePath ? basePath : '';

  return (
    <ContentFeedGrid>
      {items.map((item) => {
        const heroImage = getImage(item.frontmatter.background as any);
        const iconImage = getImage(item.frontmatter.icon as any);
        return (
          <article className="content-article" key={item.id}>
            <ContentHeader>
              <Link to={`${path}/${item.frontmatter.slug}/`}>
                {heroImage && (
                  <GatsbyImage
                    alt="Hero image for article"
                    image={heroImage}
                    style={{
                      position: `absolute`,
                      top: 0,
                      left: 0,
                      width: `100%`,
                      height: `100%`,
                    }}
                  />
                )}

                {iconImage && (
                  <GatsbyImage
                    alt="Icon for article"
                    image={iconImage}
                    className="header-icon"
                    style={{
                      width: `100px`,
                      position: `relative`,
                      zIndex: `2`,
                      filter: `drop-shadow(2px 4px 6px var(--shadow))`,
                    }}
                  />
                )}
              </Link>
            </ContentHeader>
            <ContentBody>
              {item.frontmatter.tags &&
                item.frontmatter.tags.map((tag: any) => (
                  <Tag key={tag} tag={tag} />
                ))}
              <Link to={`${path}/${item.frontmatter.slug}/`}>
                <h1>{item.frontmatter.title}</h1>
              </Link>
              <meta
                itemProp="datePublished"
                content={item.frontmatter.published_unformatted}
              />
              <p>
                <small>
                  <time
                    itemProp="dateModified"
                    dateTime={item.frontmatter.updated_unformatted}
                  >
                    <strong>{item.frontmatter.updated_formatted}</strong>
                  </time>{' '}
                  <ReadTime time={item.timeToRead} />
                </small>
              </p>

              <p>{item.frontmatter.description}</p>
            </ContentBody>
            <ContentFooter>
              <Author
                name={item.frontmatter.author}
                link={item.frontmatter.author_link}
                image={getImage(item.frontmatter.author_image as any)}
              />
            </ContentFooter>
          </article>
        );
      })}
    </ContentFeedGrid>
  );
};
