import { Author } from '@sites/shared/ui/general';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import React from 'react';
import { getImage } from 'gatsby-plugin-image';

export interface LessonFooterProps {
  pageProps: Pick<PageProps, 'data'>;
}

const LessonFooterStyles = styled.footer`
  padding: 1rem;
  background-color: var(--lighter);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LessonFooter = ({ pageProps: { data } }: LessonFooterProps) => {
  const { lesson } = data as GatsbyTypes.SingleLessonPageDataQuery;

  if (!lesson?.frontmatter) return <></>;

  return (
    <LessonFooterStyles>
      <Author
        name={lesson.frontmatter.author}
        link={lesson.frontmatter.author_link}
        image={getImage(lesson.frontmatter.author_image as any)}
      />
    </LessonFooterStyles>
  );
};
