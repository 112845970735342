import React from 'react';
import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';

export interface RevealProps {
  clickText?: string;
  revealText?: string;
}

const RevealStyles = styled.div`
  text-align: center;

  svg {
    fill: var(--primary);
    font-size: 2em;
  }

  .blocker {
    opacity: 0.3;
    height: 100vh;
    margin-top: 1rem;
    background-color: var(--lighter);
    background-image: linear-gradient(
      45deg,
      var(--light) 25%,
      var(--lighter) 25%,
      var(--lighter) 50%,
      var(--light) 50%,
      var(--light) 75%,
      var(--lighter) 75%,
      var(--lighter) 100%
    );
    background-size: 56.57px 56.57px;
  }
`;

export const Reveal = ({ clickText, revealText }: RevealProps) => {
  const id = Date.now().toString();

  const click = clickText || 'Click here to reveal solution';
  const reveal = revealText || 'Solution';

  return (
    <RevealStyles>
      <a href={`#${id}`}>
        <h3>{click}</h3>
        <FaChevronDown />
        <div className="blocker"></div>
      </a>

      <h3 id={id}>{reveal}</h3>
    </RevealStyles>
  );
};
