import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { FaFireAlt } from 'react-icons/fa';
import { Tag } from '../../index';

export interface FeaturedLessonsProps {
  lessons: readonly any[];
  basePath?: string;
}

const FeaturedLessonHeader = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .featured-lesson-title {
    z-index: 2;
    padding: 20px;
    background-color: var(--shadow-slight-transparent);

    h2 {
      font-size: 1.5em;
      color: var(--lightest);
      text-shadow: 2px 2px var(--shadow);
      font-weight: bold;
    }
  }
`;

const FeaturedLessonsStyles = styled.div`
  display: grid;
  gap: 2rem;
  grid-auto-flow: column;

  border: 3px solid var(--darker);
  overflow-x: scroll;
  padding: 10px;

  scrollbar-width: thin;
  scrollbar-color: var(--primary);

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: var(--darker);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 6px;
    border: 3px solid var(--darker);
  }

  div {
    width: 300px;
    height: 350px;
    border-radius: 5px;
    box-shadow: 3px 3px var(--lighter);
    transition: transform 0.1s ease-in-out;
  }

  div:hover {
    transform: rotate(1deg);
  }
`;

const FeaturedLessonsGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 4fr;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;

    .featured-header h1 {
      color: var(--lightest) !important;
      font-size: 1.2em;
      display: none;
    }
  }

  .featured-header {
    height: 100%;
    position: relative;
    border-radius: 4px;
    background: linear-gradient(
      40deg,
      var(--primary),
      var(--lightest) 40%,
      var(--secondary) 80%
    );

    .badge {
      position: absolute;
    }

    & > div {
      position: absolute;
      display: flex;
      align-items: center;
      text-align: center;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      background-color: var(--primary);

      h1 {
        color: var(--lightest);
        font-weight: bold;
        filter: drop-shadow(2px 4px 6px var(--shadow-transparent));
      }

      span {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
  }
`;

export const FeaturedLessons = ({
  lessons,
  basePath,
}: FeaturedLessonsProps) => {
  const path = basePath ? basePath : '';

  return (
    <FeaturedLessonsGrid>
      <div className="featured-header">
        <div>
          <span
            style={{ display: `flex`, alignItems: `center` }}
            className="badge tilt-reverse secondary-background"
          >
            <FaFireAlt style={{ marginRight: `5px` }} /> HOT STUFF
          </span>
          <h1 style={{ color: `var(--darker)`, width: `100%` }}>Featured</h1>
        </div>
      </div>
      <FeaturedLessonsStyles className="featured-lessons-container">
        {lessons.map((lesson, index) => (
          <div key={lesson.id} style={{ '--animation-order': index } as any}>
            <FeaturedLessonHeader>
              <GatsbyImage
                alt="Lesson hero image"
                image={
                  lesson.frontmatter.background.childImageSharp.gatsbyImageData
                }
                style={{
                  position: `absolute`,
                  top: 0,
                  left: 0,
                  width: `100%`,
                  height: `100%`,
                }}
              />
              <div className="featured-lesson-title">
                <Link to={`${path}/${lesson.frontmatter.slug}/`}>
                  <h2>{lesson.frontmatter.title}</h2>
                </Link>
                {lesson.frontmatter.tags?.map((tag: string) => {
                  if (['angular', 'react', 'vue', 'stencil'].includes(tag)) {
                    return <Tag key={tag} tag={tag} />;
                  } else {
                    return <></>;
                  }
                })}
              </div>
            </FeaturedLessonHeader>
          </div>
        ))}
      </FeaturedLessonsStyles>
    </FeaturedLessonsGrid>
  );
};

export default FeaturedLessons;
