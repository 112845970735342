import styled from 'styled-components';
import React from 'react';

export interface LessonCommentsProps {
  slug: string;
  basePath: string;
  bonus_link?: string;
}

const LessonCommentsStyles = styled.aside`
  background-color: var(--secondary);
  padding: 3rem;
  color: var(--lightest);
  border-radius: var(--border-radius);

  a {
    color: var(--lightest);
    --primary: var(--lightest);
  }
`;

export const LessonComments = ({
  slug,
  bonus_link,
  basePath,
}: LessonCommentsProps) => {
  return (
    <LessonCommentsStyles>
      <h1>Discussion</h1>

      <p style={{ marginBottom: `4rem` }}>
        <strong>
          Need some help with this tutorial? Spotted an error? Got some helpful
          advice for others?
        </strong>{' '}
        <a
          href={`https://twitter.com/search?q=${encodeURIComponent(
            basePath + slug
          )}`}
        >
          Join the discussion on Twitter
        </a>
      </p>

      <p>
        If there are no active discussions, start one by including the URL of
        this post and tag me (
        <a href="https://twitter.com/joshuamorony">@joshuamorony</a>) in a new
        tweet.
      </p>
      <p>
        I'll try to help out directly whenever I have the time, but you might
        also want to include other relevant tags to attract attention from
        others who might also be able to help. To make it super easy for others
        to help you out, you might consider setting up an example on{' '}
        <a href="https://stackblitz.com/">Stack Blitz</a> so others can jump
        right into your code.
      </p>
      {bonus_link && (
        <>
          <h2 style={{ marginTop: `6rem` }}>Pull requests</h2>
          <p>
            If you find an error or some outdated code that you would like to
            help fix, feel free to{' '}
            <a href={bonus_link}>send me a pull request</a> on GitHub
          </p>
        </>
      )}
    </LessonCommentsStyles>
  );
};
