import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

export interface AuthorProps {
  name: string;
  link: string;
  image: IGatsbyImageData | undefined;
}

const AuthorStyles = styled.div`
  display: flex;
  align-items: center;
`;

export const Author = (props: AuthorProps) => {
  const { name, link, image } = props;

  return (
    <AuthorStyles itemProp="author" itemType="http://schema.org/Person">
      {image && (
        <GatsbyImage
          image={image}
          alt="post author"
          style={{
            width: `50px`,
            height: `50px`,
            marginRight: `1rem`,
            borderRadius: `50%`,
            border: `3px solid var(--lightest)`,
          }}
        />
      )}
      <a href={link}>{name}</a>
    </AuthorStyles>
  );
};
