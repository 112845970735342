import { postStyles } from '@sites/eliteionic/shared/styles';
import { MdxHeading } from '@sites/shared/ui/layout';
import { LessonAuthor, LessonContentSeries } from '@sites/shared/ui/lesson';
import { PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import styled from 'styled-components';
import { LessonSidebar } from '../index';

export interface LessonContentProps {
  pageProps: Pick<PageProps, 'data' | 'location'>;
}

const PostStyles = styled.div`
  ${postStyles}
`;

const LessonContentStyles = styled.div`
  padding: 4rem;
  margin-bottom: 5rem;

  @media only screen and (max-width: 700px) {
    padding: 2rem;
  }

  .table-of-contents {
  }
`;

const LessonContentGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;

  @media only screen and (max-width: 700px) {
    margin-top: 4rem;
  }

  @media only screen and (max-width: 1350px) {
    grid-template-columns: 1fr;

    .sticky-container {
      display: none;
    }
  }
`;

export const LessonContent = ({
  pageProps: { data, location },
}: LessonContentProps) => {
  const { lesson, othersInSeries } =
    data as GatsbyTypes.SingleLessonPageDataQuery;

  if (!lesson?.frontmatter || !lesson.timeToRead) return <></>;

  return (
    <LessonContentStyles>
      <LessonAuthor categoryPath="topics" pageProps={{ data }}></LessonAuthor>
      {lesson.frontmatter.order_in_series && (
        <LessonContentSeries
          pageProps={{ data, location }}
          basePath="tutorials"
        ></LessonContentSeries>
      )}
      <LessonContentGrid>
        <PostStyles>
          <MDXRenderer
            headings={lesson.headings}
            frontmatter={lesson.frontmatter}
          >
            {lesson.body}
          </MDXRenderer>
        </PostStyles>
        <LessonSidebar
          tocHeadings={lesson.headings as MdxHeading[]}
          bonus_type={lesson.frontmatter.bonus_type}
          bonus_link={lesson.frontmatter.bonus_link}
        ></LessonSidebar>
      </LessonContentGrid>
    </LessonContentStyles>
  );
};
