import { SEO } from '@sites/shared/ui/layout';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const TagsGrid = styled.div`
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;

  & > div {
    margin: 0 20px;
  }

  & > div:first-of-type {
    margin-top: 3rem;
  }
`;

const TagsPage = ({ data }: PageProps) => {
  const { site } = data as GatsbyTypes.TagsPageDataQuery;

  if (!site) return <></>;

  return (
    <TagsGrid>
      <SEO
        title={`Tags`}
        description={`Browse content by tag`}
        siteMetadata={site.siteMetadata}
      />
      <div className="title-bar secondary-background">
        <h1>Tags</h1>
      </div>
      <div className="info-box">
        <p style={{ textAlign: `center` }}>
          Choose one of the tags above or use the search bar to see if you can
          find what you are looking for!
        </p>
      </div>
    </TagsGrid>
  );
};

export default TagsPage;

export const query = graphql`
  query TagsPageData {
    site {
      ...SiteInformation
    }
  }
`;
