import backends from '@sites/shared/assets/../lib/images/icons/backends.svg';
import testing from '@sites/shared/assets/../lib/images/icons/testing.svg';
import performance from '@sites/shared/assets/../lib/images/icons/performance.svg';
import native from '@sites/shared/assets/../lib/images/icons/native.svg';
import uiux from '@sites/shared/assets/../lib/images/icons/uiux.svg';
import codeQuality from '@sites/shared/assets/../lib/images/icons/code-quality.svg';

export const featuredTopics = [
  { id: '1', title: 'Performance', path: 'performance', icon: performance },
  { id: '2', title: 'UI/UX', path: 'user-experience', icon: uiux },
  { id: '3', title: 'Testing', path: 'testing', icon: testing },
  { id: '4', title: 'Backend', path: 'backend', icon: backends },
  { id: '5', title: 'Architecture', path: 'architecture', icon: codeQuality },
  {
    id: '6',
    title: 'Native Integration',
    path: 'native-integration',
    icon: native,
  },
];
