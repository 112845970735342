import { EliteIonicLogoIconOnly } from '@sites/shared/ui/marketing';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  margin-top: 5rem;
  padding: 4rem;
  color: var(--light);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--darker);

  @media only screen and (max-width: 900px) {
    flex-direction: column;

    .logo-container {
      margin-bottom: 2rem;
    }
  }

  .links {
    ul {
      font-size: 0.8em;
      font-weight: bold;
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 5rem;

      li {
        margin-bottom: 0.5rem;
      }
    }

    a {
      color: var(--light);
    }

    .copyright {
      font-size: 0.7em;

      a {
        font-weight: normal;
        margin-right: 2rem;
      }
    }
  }

  .logo-container {
    display: flex;
    flex-direction: column;

    .pro {
      width: 100%;

      & > span {
        width: 100%;
        padding: 0.5rem;
      }
    }
  }

  .logo {
    display: flex;

    h4 {
      max-width: 250px;
      color: var(--light);
      margin-left: 1rem;
    }

    svg {
      width: 50px;
      height: auto;
    }
  }
`;

export const Footer = () => (
  <FooterStyles>
    <div className="logo-container">
      <Link to={`/`}>
        <div className="logo">
          {EliteIonicLogoIconOnly()}
          <h4>
            Education and resources for creating{' '}
            <span
              style={{
                color: `var(--primary)`,
                textDecoration: `underline`,
              }}
            >
              <strong>
                <em>NEXT LEVEL</em>
              </strong>
            </span>{' '}
            native web applications.
          </h4>
        </div>
      </Link>
      <Link className="pro" to={`/pro-membership/`}>
        <span className="badge">
          Become a <span className="badge tilt secondary-background">PRO</span>{' '}
          member
        </span>
      </Link>
    </div>

    <div className="links">
      <ul>
        <li>
          <Link to={`/`}>Get the Newsletter</Link>
        </li>
        <li>
          <a href="https://twitter.com/intent/user?screen_name=joshuamorony">
            Follow on Twitter
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCbVZdLngJH6KOJvpAOO3qTw?sub_confirmation=1">
            Subscribe on YouTube
          </a>
        </li>
        <li>
          <Link to={`/consulting/`}>Services</Link>
        </li>
        <li>
          <Link to={`/membership/`}>Pricing</Link>
        </li>
        <li>
          <a href="https://www.joshmorony.com/">Josh Morony</a>
        </li>
        <li>
          <a href="https://www.joshmorony.com/books/">Books</a>
        </li>
        <li>
          <Link to={`/about/`}>About</Link>
        </li>
        <li>
          <Link to={`/contact/`}>Contact</Link>
        </li>
      </ul>
      <div className="copyright">
        <Link to={`/terms-of-service/`}>Terms of Service</Link>{' '}
        <Link to={`/privacy-policy/`}>Privacy Policy</Link>
        <span style={{ fontWeight: `bold` }}>
          &copy; Mobirony {new Date().getFullYear()}
        </span>{' '}
      </div>
    </div>
  </FooterStyles>
);
