import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import angular from '@sites/shared/assets/../lib/images/frameworks/angular.svg';
import react from '@sites/shared/assets/../lib/images/frameworks/react.svg';
import stencil from '@sites/shared/assets/../lib/images/frameworks/stencil-ds.svg';
import vue from '@sites/shared/assets/../lib/images/frameworks/vue.svg';

const FeaturedFrameworksGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  & > div {
    height: 100%;
    padding: 3px;
    border-radius: 4px;
    background: linear-gradient(
      60deg,
      var(--primary),
      var(--lightest) 40%,
      var(--secondary) 80%
    );
    /* animation: popIn 0.4s calc((var(--animation-order) + 3) * 70ms) both ease-in; */
  }

  .featured-topic-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--lightest);
    height: 300px;

    img {
      height: 50%;
      width: auto;
      filter: grayscale(1);
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      img {
        filter: grayscale(0) drop-shadow(1px 2px 3px var(--shadow-transparent));
        transform: translateY(-2px);
      }
    }
  }

  .featured-header {
    height: 100%;
    position: relative;
    border-radius: 4px;
    background: linear-gradient(
      40deg,
      var(--primary),
      var(--lightest) 40%,
      var(--secondary) 80%
    );

    .badge {
      position: absolute;
    }

    & > div {
      position: absolute;
      display: flex;
      align-items: center;
      text-align: center;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      background-color: var(--primary);

      h1 {
        color: var(--lightest);
        font-weight: bold;
        filter: drop-shadow(2px 4px 6px var(--shadow-transparent));
      }

      span {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
  }
`;

interface FeaturedFrameworksProps {
  filter: string;
  category: string;
}

export const FeaturedFrameworks = ({
  filter,
  category,
}: FeaturedFrameworksProps) => {
  const featuredFrameworks = [
    { id: '2', title: 'Angular', path: 'angular', icon: angular },
    { id: '3', title: 'React', path: 'react', icon: react },
    { id: '4', title: 'Vue', path: 'vue', icon: vue },
    { id: '5', title: 'StencilJS', path: 'stencil', icon: stencil },
  ];

  return (
    <FeaturedFrameworksGrid>
      {featuredFrameworks.map((framework, index) => {
        const link = category
          ? `/topics/${framework.path}-${category
              .toLowerCase()
              .replace(' ', '-')}/`
          : `/frameworks/${framework.path}/`;

        return (
          <div key={framework.id} style={{ '--animation-order': index } as any}>
            <Link to={link}>
              <div className="featured-topic-container">
                <img src={framework.icon} alt={framework.title} />
                <h3>{framework.title}</h3>
              </div>
            </Link>
          </div>
        );
      })}
    </FeaturedFrameworksGrid>
  );
};

export default FeaturedFrameworks;
