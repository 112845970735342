import styled from 'styled-components';

export const sidebarStyles = `
  .sticky-container {
    position: sticky;
    top: 8rem;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .table-of-contents {
    font-size: 0.8em;
    padding: 0 2rem;
    transition: opacity 0.2s linear;
    z-index: 2;
    padding-bottom: 2rem;
    border-radius: 5px;

    ol ol {
      display: none;
    }

    .active {
      opacity: 1;

      a {
        box-shadow: inset 0px -3px var(--primary);
      }
    }

    li {
      opacity: 0.6;
      margin-bottom: 1rem;
      list-style-type: circle;

      a {
        box-shadow: initial;
      }

      &:hover {
        opacity: 1;

        a {
          box-shadow: inset 0px -3px var(--primary);
        }
      }

      li {
        a {
          box-shadow: none !important;
        }
      }
    }
  }

  .table-of-contents:hover {
    opacity: 1;
  }
`;
