// Elite Ionic
import testing from '@sites/eliteionic/shared/assets/../lib/images/models/testing.png';
import performance from '@sites/eliteionic/shared/assets/../lib/images/models/performance.png';
import api from '@sites/eliteionic/shared/assets/../lib/images/models/api.png';
import uiux from '@sites/eliteionic/shared/assets/../lib/images/models/uiux.png';
import components from '@sites/eliteionic/shared/assets/../lib/images/models/components.png';
import presentation from '@sites/eliteionic/shared/assets/../lib/images/models/presentation.png';
import firebase from '@sites/eliteionic/shared/assets/../lib/images/models/firebase.png';
import testingIcon from '@sites/eliteionic/shared/assets/../lib/images/models/testing.png';
import performanceIcon from '@sites/eliteionic/shared/assets/../lib/images/models/performance.png';
import apiIcon from '@sites/eliteionic/shared/assets/../lib/images/models/api.png';
import uiuxIcon from '@sites/eliteionic/shared/assets/../lib/images/models/uiux.png';
import componentsIcon from '@sites/eliteionic/shared/assets/../lib/images/models/components.png';
import presentationIcon from '@sites/eliteionic/shared/assets/../lib/images/models/presentation.png';
import firebaseIcon from '@sites/eliteionic/shared/assets/../lib/images/models/firebase.png';

// Ionic start
import cyberpunk from '@sites/shared/assets/../lib/images/models/1-cyberpunk.png';
import cyberpunkIcon from '@sites/shared/assets/../lib/images/models/icon/1-cyberpunk.png';
import scienceFiction from '@sites/shared/assets/../lib/images/models/2-science-fiction.png';
import scienceFictionIcon from '@sites/shared/assets/../lib/images/models/icon/2-science-fiction.png';
import dystopia from '@sites/shared/assets/../lib/images/models/3-dystopia.png';
import dystopiaIcon from '@sites/shared/assets/../lib/images/models/icon/3-dystopia.png';
import societalCollapse from '@sites/shared/assets/../lib/images/models/4-societal-collapse.png';
import societalCollapseIcon from '@sites/shared/assets/../lib/images/models/icon/4-societal-collapse.png';
import artificialIntelligence from '@sites/shared/assets/../lib/images/models/5-artificial-intelligence.png';
import artificialIntelligenceIcon from '@sites/shared/assets/../lib/images/models/icon/5-artificial-intelligence.png';
import implant from '@sites/shared/assets/../lib/images/models/6-implant.png';
import implantIcon from '@sites/shared/assets/../lib/images/models/icon/6-implant.png';
import microchip from '@sites/shared/assets/../lib/images/models/7-microchip.png';
import microchipIcon from '@sites/shared/assets/../lib/images/models/icon/7-microchip.png';
import hologram from '@sites/shared/assets/../lib/images/models/8-hologram.png';
import hologramIcon from '@sites/shared/assets/../lib/images/models/icon/8-hologram.png';
import neonSign from '@sites/shared/assets/../lib/images/models/9-neon-sign.png';
import neonSignIcon from '@sites/shared/assets/../lib/images/models/icon/9-neon-sign.png';
import cyborg from '@sites/shared/assets/../lib/images/models/10-cyborg.png';
import cyborgIcon from '@sites/shared/assets/../lib/images/models/icon/10-cyborg.png';
import robot from '@sites/shared/assets/../lib/images/models/11-robot.png';
import robotIcon from '@sites/shared/assets/../lib/images/models/icon/11-robot.png';
import cctvCamera from '@sites/shared/assets/../lib/images/models/12-cctv-camera.png';
import cctvCameraIcon from '@sites/shared/assets/../lib/images/models/icon/12-cctv-camera.png';
import medication from '@sites/shared/assets/../lib/images/models/13-medication.png';
import medicationIcon from '@sites/shared/assets/../lib/images/models/icon/13-medication.png';
import binaryCode from '@sites/shared/assets/../lib/images/models/14-binary-code.png';
import binaryCodeIcon from '@sites/shared/assets/../lib/images/models/icon/14-binary-code.png';
import hacker from '@sites/shared/assets/../lib/images/models/15-hacker.png';
import hackerIcon from '@sites/shared/assets/../lib/images/models/icon/15-hacker.png';
import vaporwave from '@sites/shared/assets/../lib/images/models/16-vaporwave.png';
import vaporwaveIcon from '@sites/shared/assets/../lib/images/models/icon/16-vaporwave.png';
import eyeScanner from '@sites/shared/assets/../lib/images/models/17-eye-scanner.png';
import eyeScannerIcon from '@sites/shared/assets/../lib/images/models/icon/17-eye-scanner.png';
import city from '@sites/shared/assets/../lib/images/models/18-city.png';
import cityIcon from '@sites/shared/assets/../lib/images/models/icon/18-city.png';
import mohawkSkull from '@sites/shared/assets/../lib/images/models/19-mohawk-skull.png';
import mohawkSkullIcon from '@sites/shared/assets/../lib/images/models/icon/19-mohawk-skull.png';
import megacorporation from '@sites/shared/assets/../lib/images/models/20-megacorporation.png';
import megacorporationIcon from '@sites/shared/assets/../lib/images/models/icon/20-megacorporation.png';
import cyberspace from '@sites/shared/assets/../lib/images/models/21-cyberspace.png';
import cyberspaceIcon from '@sites/shared/assets/../lib/images/models/icon/21-cyberspace.png';
import highTech from '@sites/shared/assets/../lib/images/models/22-high-tech.png';
import highTechIcon from '@sites/shared/assets/../lib/images/models/icon/22-high-tech.png';
import lowLifePunk from '@sites/shared/assets/../lib/images/models/23-low-life-punk.png';
import lowLifePunkIcon from '@sites/shared/assets/../lib/images/models/icon/23-low-life-punk.png';
import bodyModification from '@sites/shared/assets/../lib/images/models/24-body-modification.png';
import bodyModificationIcon from '@sites/shared/assets/../lib/images/models/icon/24-body-modification.png';
import target from '@sites/shared/assets/../lib/images/models/25-target.png';
import targetIcon from '@sites/shared/assets/../lib/images/models/icon/25-target.png';
import augmentedReality from '@sites/shared/assets/../lib/images/models/26-augmented-reality.png';
import augmentedRealityIcon from '@sites/shared/assets/../lib/images/models/icon/26-augmented-reality.png';
import cyberEye from '@sites/shared/assets/../lib/images/models/27-cyber-eye.png';
import cyberEyeIcon from '@sites/shared/assets/../lib/images/models/icon/27-cyber-eye.png';
import flashDrive from '@sites/shared/assets/../lib/images/models/28-flash-drive.png';
import flashDriveIcon from '@sites/shared/assets/../lib/images/models/icon/28-flash-drive.png';
import weapon from '@sites/shared/assets/../lib/images/models/29-weapon.png';
import weaponIcon from '@sites/shared/assets/../lib/images/models/icon/29-weapon.png';
import techwear from '@sites/shared/assets/../lib/images/models/30-techwear.png';
import techwearIcon from '@sites/shared/assets/../lib/images/models/icon/30-techwear.png';

const eliteIcons = {
  testing,
  testingIcon,
  performance,
  performanceIcon,
  api,
  apiIcon,
  uiux,
  uiuxIcon,
  components,
  componentsIcon,
  presentation,
  presentationIcon,
  firebase,
  firebaseIcon,
};

const ionicStartIcons = {
  cyberpunk,
  cyberpunkIcon,
  scienceFiction,
  scienceFictionIcon,
  dystopia,
  dystopiaIcon,
  societalCollapse,
  societalCollapseIcon,
  artificialIntelligence,
  artificialIntelligenceIcon,
  implant,
  implantIcon,
  microchip,
  microchipIcon,
  hologram,
  hologramIcon,
  neonSign,
  neonSignIcon,
  cyborg,
  cyborgIcon,
  robot,
  robotIcon,
  cctvCamera,
  cctvCameraIcon,
  medication,
  medicationIcon,
  binaryCode,
  binaryCodeIcon,
  hacker,
  hackerIcon,
  vaporwave,
  vaporwaveIcon,
  eyeScanner,
  eyeScannerIcon,
  city,
  cityIcon,
  mohawkSkull,
  mohawkSkullIcon,
  megacorporation,
  megacorporationIcon,
  cyberspace,
  cyberspaceIcon,
  highTech,
  highTechIcon,
  lowLifePunk,
  lowLifePunkIcon,
  bodyModification,
  bodyModificationIcon,
  target,
  targetIcon,
  augmentedReality,
  augmentedRealityIcon,
  cyberEye,
  cyberEyeIcon,
  flashDrive,
  flashDriveIcon,
  weapon,
  weaponIcon,
  techwear,
  techwearIcon,
};

const icons: { [key: string]: string } = { ...eliteIcons, ...ionicStartIcons };

export const addModuleIcons = (modules: readonly any[]) => {
  return modules.map((module) => {
    const imageString = module.frontmatter.cardIcon;
    const iconString = imageString + 'Icon';

    return {
      ...module,
      frontmatter: {
        ...module.frontmatter,
        cardImage: icons[imageString],
        cardIcon: icons[iconString],
      },
    };
  });
};

export const addModuleIcon = (module: any) => {
  const imageString = module.frontmatter.cardIcon;
  const iconString = imageString + 'Icon';

  return {
    ...module,
    frontmatter: {
      ...module.frontmatter,
      cardImage: icons[imageString],
      cardIcon: icons[iconString],
    },
  };
};
