import { IONIC_START_MODULE_ORDER } from '@sites/shared/utils';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { ModuleCard } from '../module-card/module-card';

const ModulesGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1fr);
`;

const ModuleItem = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
  background-color: var(--lighter);
  border-radius: 4px;
  box-shadow: 2px 2px 6px var(--shadow-transparent);

  &:hover {
    .icon-container {
      transform: scale(1.1);
    }
  }

  .start-button {
    margin-left: auto;
    margin-right: 2rem;
    padding: 5px 1rem;
    border-radius: 5px;
    color: var(--lightest);
    font-size: 0.8em;
    background: linear-gradient(
      116deg,
      var(--primary) 0%,
      var(--secondary) 100%
    );
    box-shadow: 2px 2px var(--primary);

    &:hover {
      box-shadow: 1px 1px var(--primary) !important;
    }
  }

  .icon-container {
    width: 75px;
    padding: 3px;
    border-radius: 4px;
    background: linear-gradient(60deg, var(--primary), var(--secondary) 80%);
    box-shadow: 4px 4px 0px 0px var(--secondary);
    transition: transform 0.1s linear;

    img {
      width: 75px;
      height: 75px;
      filter: drop-shadow(5px 3px 1px var(--shadow-transparent));
      background-color: var(--lightest);
      border-radius: 4px;
    }
  }

  @media only screen and (max-width: 700px) {
    .badge-box {
      flex-direction: column;
      align-items: center;
    }

    img {
      margin-bottom: 3rem;
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 1rem;

    .icon-container {
      display: none;
    }

    .lessons {
      display: none;
    }
  }
`;

export interface ModuleFeedHorizontalProps {
  modules: any[];
}

export const ModuleFeedHorizontal = ({
  modules,
}: ModuleFeedHorizontalProps) => {
  const sortedModules = IONIC_START_MODULE_ORDER.map((slug) =>
    modules.find((module) => module.frontmatter.slug === slug)
  );

  return (
    <ModulesGrid>
      {sortedModules.map((module, index) => {
        return (
          <ModuleItem key={module.id}>
            <div className="icon-container">
              <a href={`/modules/${module.frontmatter.slug}/`}>
                <img src={module.frontmatter.cardIcon} alt="icon for module" />
              </a>
            </div>
            <div className="title-container">
              <div
                className="badge secondary-background"
                style={{ marginRight: `1rem` }}
              >
                <strong>{module.frontmatter.membership.toUpperCase()}</strong>
              </div>
              <a href={`/modules/${module.frontmatter.slug}/`}>
                <span style={{ color: `var(--light)`, marginRight: `1rem` }}>
                  {index.toString().padStart(2, '0')}{' '}
                </span>
                <strong>{module.frontmatter.title}</strong>
              </a>
              <div className="badge lessons" style={{ marginLeft: `1rem` }}>
                <strong>Lessons: {module.lessonCount}</strong>
              </div>
            </div>
            <a
              className="start-button"
              href={`/modules/${module.frontmatter.slug}/`}
            >
              Start
            </a>
          </ModuleItem>
        );
      })}
    </ModulesGrid>
  );
};
