import { useEffect, useRef } from 'react';

export const useTocObserver = (selector: string, headerSelector: string) => {
  const observer = useRef<IntersectionObserver>();
  const activeElements = useRef<HTMLElement[]>([]);

  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute('id');
          const tocElement: HTMLElement | null = document.querySelector(
            `${selector} a[href="#${id}"]`
          );

          if (tocElement) {
            if (entry.intersectionRatio > 0) {
              activeElements.current.forEach((element) => {
                element.classList.remove('active');
              });

              activeElements.current = [];
              if (tocElement.parentElement) {
                tocElement.parentElement.classList.add('active');
                activeElements.current.push(tocElement.parentElement);
              }
            }
          }
        });
      });

      document.querySelectorAll(headerSelector).forEach((heading) => {
        observer.current?.observe(heading);
      });
    }
  });
};
