import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export interface TextLooperProps {
  words: string[];
  delay: number;
  width?: number;
}

const TextLooperStyles = styled.span`
  display: inline-block;
  width: 100px;
  margin: 0 1rem;
  font-weight: bold;
  background-color: var(--highlight);
  border: 2px solid var(--lightest);
  border-radius: 5px;
`;

export const TextLooper = ({ words, delay, width }: TextLooperProps) => {
  const [currentWord, updateCurrentWord] = useState('');

  useEffect(() => {
    let index = 0;
    let interval: ReturnType<typeof setInterval> | null = null;

    if (words.length > 1) {
      interval = setInterval(() => {
        index = index >= words.length - 1 ? 0 : index + 1;
        updateCurrentWord(words[index]);
      }, delay);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [words, delay]);

  return (
    <TextLooperStyles style={{ width: width ? `${width}px` : `100px` }}>
      <span>{currentWord}</span>
    </TextLooperStyles>
  );
};
