import styled from 'styled-components';
import React from 'react';

export interface LessonTwitterProps {
  textToShare: string;
}

const LessonTwitterStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 4rem 0;
  width: 100%;
  padding: 4rem;
  border: 2px dashed var(--secondary);
  background-color: var(--secondary);
`;

export const LessonTwitter = ({ textToShare }: LessonTwitterProps) => {
  return (
    <LessonTwitterStyles>
      <p style={{ color: `var(--lightest)`, fontWeight: `bold` }}>
        If you enjoyed this article, feel free to share it with others!
      </p>
      <a
        className="twitter-share-button"
        href={`https://twitter.com/intent/tweet?text=${textToShare}`}
        data-size="large"
      >
        Tweet
      </a>
    </LessonTwitterStyles>
  );
};
