import React from 'react';
import styled from 'styled-components';
import { FaYoutube, FaFileAlt } from 'react-icons/fa';

const ModuleOutlineGrid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(1);

  a {
    padding: 2rem;
    border-radius: 5px;
    border: 1px solid var(--lightest);
    background-color: var(--lightest);

    svg {
      position: relative;
      top: -2px;
    }
  }
`;

export interface ModuleOutlineProps {
  lessons: GatsbyTypes.ModuleLessonsFragment;
}

export const ModuleOutline = ({ lessons }: ModuleOutlineProps) => {
  return (
    <ModuleOutlineGrid className="module-outline">
      {lessons.nodes.map(
        (lesson) =>
          lesson.frontmatter &&
          typeof lesson.frontmatter.lesson !== 'undefined' && (
            <a
              href={
                lesson.frontmatter.public
                  ? `/modules/${lesson.frontmatter.module}/${lesson.frontmatter.lesson}`
                  : `/${lesson.frontmatter.path}/modules/${lesson.frontmatter.module}/${lesson.frontmatter.lesson}`
              }
              key={lesson.id}
            >
              {lesson.frontmatter.video !== null ? (
                <FaYoutube style={{ color: `#FF0000` }} />
              ) : (
                <FaFileAlt />
              )}{' '}
              {lesson.frontmatter.lesson > 0 &&
                `Lesson ${lesson.frontmatter.lesson}: `}
              {lesson.frontmatter.title}{' '}
              {!lesson.frontmatter.public ? (
                <span className="badge tilt secondary-background">
                  {lesson.frontmatter.path?.toUpperCase()}
                </span>
              ) : (
                <span className="badge tilt success-background">PUBLIC</span>
              )}
            </a>
          )
      )}
    </ModuleOutlineGrid>
  );
};
