import React from 'react';

export interface IFrameEmbedProps {
  title: string;
  width: string;
  height: string;
  src: string;
  allowFullScreen?: boolean;
}

export const IFrameEmbed = ({
  title,
  width,
  height,
  src,
  allowFullScreen,
}: IFrameEmbedProps) => {
  return (
    <div className="embed-container">
      <iframe
        title={title}
        width={width}
        height={height}
        src={src}
        frameBorder="0"
        allowFullScreen={allowFullScreen ?? false}
      ></iframe>
    </div>
  );
};
