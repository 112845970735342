import { Author, ReadTime } from '@sites/shared/ui/general';
import { Tag } from '@sites/shared/ui/layout';
import { Link, PageProps } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

export interface LessonAuthorProps {
  pageProps: Pick<PageProps, 'data'>;
  categoryPath: string;
}

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  text-align: center;
  gap: 1rem;
  position: relative;
  top: -100px;
  background-color: var(--lightest);
  box-shadow: 0 0 20px 0px var(--shadow-transparent);
  border-radius: 5px;
  padding: 3rem;

  .category-tag {
    margin-right: 1rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    padding: 1rem;
    border: 1px solid var(--darkest);
  }
`;

export function LessonAuthor({
  pageProps: { data },
  categoryPath,
}: LessonAuthorProps) {
  const { lesson } = data as GatsbyTypes.SingleLessonPageDataQuery;

  if (!lesson?.frontmatter || !lesson.timeToRead) return <></>;

  return (
    <AuthorContainer className="author-container">
      <Author
        name={lesson.frontmatter.author}
        link={lesson.frontmatter.author_link}
        image={getImage(lesson.frontmatter.author_image as any)}
      />
      <meta
        itemProp="datePublished"
        content={lesson.frontmatter.published_unformatted}
      />
      <p style={{ margin: `0` }}>
        <small>
          <time
            itemProp="dateModified"
            dateTime={lesson.frontmatter.updated_unformatted}
          >
            <strong>{lesson.frontmatter.updated_formatted}</strong>
          </time>{' '}
          <ReadTime time={lesson.timeToRead} />
        </small>
      </p>
      <div>
        {lesson.frontmatter.categories &&
          lesson.frontmatter.categories.map(
            (category) =>
              category && (
                <Link
                  key={category}
                  to={`/${categoryPath}/${category
                    .toLowerCase()
                    .replace(' ', '-')}/`}
                >
                  <span className="category-tag badge">{category}</span>
                </Link>
              )
          )}
        {lesson.frontmatter.tags &&
          lesson.frontmatter.tags.map(
            (tag) => tag && <Tag key={tag} tag={tag} />
          )}
      </div>
      <p
        style={{
          margin: `0`,
          fontSize: `0.7em`,
          opacity: `0.3`,
          color: `var(--light)`,
        }}
      >
        Originally published{' '}
        <strong>{lesson.frontmatter.published_formatted}</strong>
      </p>
    </AuthorContainer>
  );
}
