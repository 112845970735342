import { IFrameEmbed } from '@sites/shared/ui/general';
import {
  MDXProviderPreview,
  ModuleOutlineSimple,
  ProMembershipBox,
} from '@sites/shared/ui/layout';
import { PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import styled from 'styled-components';
import { ModuleLessonSidebar } from '../index';

export interface ModuleLessonContentProps {
  pageProps: Pick<PageProps, 'data' | 'pageContext' | 'location'>;
  postStyles: string;
  sidebarStyles: string;
}

const TutorialContent = styled.div`
  padding: 4rem;
  padding-bottom: 8rem;
  margin-bottom: 5rem;

  @media only screen and (max-width: 700px) {
    padding: 2rem;
  }

  .embed-container {
    margin-top: 4rem;
  }
`;

const TutorialContentGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;

  @media only screen and (max-width: 1350px) {
    grid-template-columns: 1fr;

    .sticky-container {
      display: none;
    }
  }
`;

export const ModuleLessonContent = ({
  pageProps: { data, location, pageContext },
  postStyles,
  sidebarStyles,
}: ModuleLessonContentProps) => {
  const { module, lessons, lesson, site } =
    data as GatsbyTypes.SingleModuleLessonPageQuery;

  const PostStyles = styled.div`
    ${postStyles}
  `;

  if (
    !(
      module &&
      lessons &&
      lesson?.frontmatter &&
      typeof lesson.frontmatter.lesson !== 'undefined' &&
      site
    )
  )
    return <></>;
  return (
    <TutorialContent>
      <ModuleOutlineSimple
        currentLesson={lesson.frontmatter.lesson}
        lessons={lessons}
      />
      {lesson.frontmatter.video && !(pageContext as any)?.preview && (
        <IFrameEmbed
          title="Lesson video"
          width="600"
          height="400"
          src={lesson.frontmatter.video}
        ></IFrameEmbed>
      )}
      <TutorialContentGrid>
        <MDXProviderPreview previewPercentage={0.3}>
          {(pageContext as any)?.preview ? (
            <PostStyles style={{ marginBottom: `4rem` }}>
              <MDXRenderer headings={lesson.headings} isPreview={true}>
                {lesson.body}
              </MDXRenderer>
              <div className="preview-fade-out"></div>
            </PostStyles>
          ) : (
            <PostStyles>
              <MDXRenderer headings={lesson.headings}>
                {lesson.body}
              </MDXRenderer>
            </PostStyles>
          )}
        </MDXProviderPreview>
        <ModuleLessonSidebar
          isPreview={(pageContext as any)?.preview ?? false}
          lesson={lesson}
          sidebarStyles={sidebarStyles}
        ></ModuleLessonSidebar>
      </TutorialContentGrid>
      {(pageContext as any)?.preview && (
        <ProMembershipBox
          lesson={lesson}
          location={location}
          site={site}
        ></ProMembershipBox>
      )}
    </TutorialContent>
  );
};
