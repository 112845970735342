import React from 'react';
import styled from 'styled-components';
import { EliteIonicLogoIconOnly } from '../../index';

export interface ElitePromoProps {
  source: string;
}

const ElitePromoStyles = styled.div`
  background-color: #2b2c30;
  outline: 2px dashed #000;
  color: #fff;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 16px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ElitePromo = ({ source }: ElitePromoProps) => {
  return (
    <ElitePromoStyles>
      <div style={{ marginTop: `5px`, textAlign: `center` }}>
        <div
          style={{
            position: `relative`,
            top: `2px`,
            display: `inline-block`,
            marginRight: `5px`,
            width: `35px`,
            height: `35px`,
          }}
        >
          <EliteIonicLogoIconOnly />
        </div>
        Check out my <strong>advanced</strong> Ionic tutorials at{' '}
        <a
          style={{
            color: `#fff`,
            fontWeight: `bold`,
            textDecoration: `underline`,
          }}
          href={`https://eliteionic.com/?utm_source=${source}`}
        >
          eliteionic.com
        </a>
      </div>
    </ElitePromoStyles>
  );
};
