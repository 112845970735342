import { SEO } from '@sites/shared/ui/layout';
import { graphql, Link, PageProps } from 'gatsby';
import * as React from 'react';

const NotFoundPage = ({ data }: PageProps) => {
  const { site } = data as GatsbyTypes.NotFoundPageDataQuery;

  if (!site) return <></>;

  return (
    <div className="info-box">
      <SEO title={`404 - Page Not Found`} siteMetadata={site.siteMetadata} />
      <h1>Page not found</h1>
      <p>
        Looks like that page doesn't exist... If this pages non-existence is an
        issue, feel free to <Link to="/contact/">contact me</Link> and let me
        know.
      </p>
      <p>
        Otherwise, you might want to try using the <strong>search bar</strong>{' '}
        above to find what you are looking for or go back to the{' '}
        <Link to="/">home</Link> page.
      </p>
    </div>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPageData {
    site {
      ...SiteInformation
    }
  }
`;
