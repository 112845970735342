import { featuredTopics } from '@sites/eliteionic/shared/utils';
import {
  ContentFeed,
  FeaturedFrameworks,
  FeaturedLessons,
  FeaturedTopics,
  Pagination,
  SEO,
} from '@sites/shared/ui/layout';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const LessonsGrid = styled.div`
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;

  & > div {
    margin: 0 20px;
  }

  & > div:first-of-type {
    margin-top: 3rem;
  }
`;

const LessonsPage = ({ data, pageContext }: PageProps) => {
  const { site, tutorials, allTutorials, general } =
    data as GatsbyTypes.LessonsPageDataQuery;

  if (!site) return <></>;

  const featuredLessons = general.nodes;

  let allTags: any[] = [];

  allTutorials.nodes.forEach((tutorial) => {
    if (tutorial.frontmatter?.tags) {
      allTags = [...allTags, ...tutorial.frontmatter.tags];
    }
  });

  return (
    <>
      <SEO
        title={`Tutorials - Page ${(pageContext as any).currentPage || 1}`}
        description={`View the latest Ionic tutorials`}
        siteMetadata={site.siteMetadata}
      />
      <LessonsGrid>
        {!(pageContext as any).currentPage && (
          <>
            <FeaturedLessons lessons={featuredLessons} basePath="/tutorials" />
            <FeaturedTopics topics={featuredTopics}></FeaturedTopics>
          </>
        )}
        <div className="title-bar secondary-background">
          <h1>Latest Tutorials</h1>
        </div>
        {process.env.GATSBY_PAGE_SIZE && (
          <Pagination
            pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
            totalCount={tutorials.totalCount}
            currentPage={(pageContext as any).currentPage || 1}
            skip={(pageContext as any).skip}
            base="/tutorials"
          />
        )}
        <ContentFeed items={tutorials.nodes} basePath="/tutorials" />
        {process.env.GATSBY_PAGE_SIZE && (
          <Pagination
            pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
            totalCount={tutorials.totalCount}
            currentPage={(pageContext as any).currentPage || 1}
            skip={(pageContext as any).skip}
            base="/tutorials"
          />
        )}
      </LessonsGrid>
    </>
  );
};

export default LessonsPage;

export const query = graphql`
  query LessonsPageData($skip: Int = 0, $pageSize: Int = 10) {
    ...FeaturedLessons
    site: site {
      ...SiteInformation
    }
    tutorials: allMdx(
      filter: { frontmatter: { type: { eq: "post" } } }
      limit: $pageSize
      skip: $skip
      sort: { fields: [frontmatter___updated], order: DESC }
    ) {
      ...AllPostDetails
    }
    allTutorials: allMdx(filter: { frontmatter: { type: { eq: "post" } } }) {
      nodes {
        frontmatter {
          tags
        }
      }
    }
    siteSearchIndex: siteSearchIndex {
      index
    }
  }
`;
