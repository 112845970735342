import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import {
  FaAngular,
  FaChalkboardTeacher,
  FaJs,
  FaReact,
  FaVuejs,
} from 'react-icons/fa';

const HeaderCard = styled.div`

  height: 100%;
  padding: 3px;
  border-radius: 4px;
  background: linear-gradient(60deg, var(--primary), var(--lightest) 40%, var(--secondary) 80%);
  box-shadow: 4px 4px 0px 0px var(--secondary);

  h2 {
    font-size: 1.3em;
  }

  .total-lessons {
    font-size: 0.8em;
  }

  .description-container {
    border: 1px solid var(--lighter);
    padding: 2rem;
    margin-bottom: 2rem;
    font-size: 0.8em;
  }

  .info-card {
    margin-top: 3rem;
    color: var(--lightest);
    background-color var(--darker);
    padding: 2rem;
    border: 2px solid var(--darkest);
  }

  .featured-topic-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--lightest);
    padding: 2rem 2rem 6rem 2rem;
    height: 100%;
    text-align: center;

    img {
      width: 50%;
      height: auto;
      filter: grayscale(1) drop-shadow(2px 4px 6px var(--shadow-transparent));
      transform: scale(1.3);
      transition: all 0.3s ease-in-out;
      margin-bottom: 2rem;
    }

    &:hover {
      img {
        filter: grayscale(0) drop-shadow(2px 4px 6px var(--shadow-transparent));
        transform: translateY(-2px) scale(1.4);
      }
    }

    .framework-container {

      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      font-size: 4rem;
      background-color: var(--primary);
      justify-content: center;
      padding: 1rem;

      svg {
        margin: 0 1rem;
        background-color: var(--lightest);
        border-radius: 3px;
        padding: 4px;
      }
    }

    .module-begin-button {
      margin: auto 0 2rem 0;
      width: 100%;
    }

  }
`;

export const ModuleCard = ({
  module,
  lessons,
}: Pick<GatsbyTypes.SingleModulePageDataQuery, 'lessons' | 'module'>) => {
  if (!module?.frontmatter?.frameworks) return <></>;

  return (
    <HeaderCard key={module.id} className="header-card">
      <div className="featured-topic-container">
        {module.frontmatter.isNew && (
          <span
            style={{
              position: `absolute`,
              top: `2rem`,
              right: `2rem`,
            }}
            className="badge secondary-background"
          >
            NEW
          </span>
        )}
        <Link to={`/modules/${module.frontmatter.slug}/`}>
          <img
            src={module.frontmatter.cardImage}
            alt={module.frontmatter.title}
          />
        </Link>

        <Link to={`/modules/${module.frontmatter.slug}/`}>
          <h2>{module.frontmatter.title}</h2>
        </Link>
        {module.lessonCount && (
          <span className="total-lessons">
            <FaChalkboardTeacher
              style={{ position: `relative`, top: `-2px` }}
            />{' '}
            {module.lessonCount} lessons
          </span>
        )}

        <div className="description-container">
          <p>{module.frontmatter.summary}</p>
        </div>

        <Link
          className="module-begin-button"
          to={`/modules/${module.frontmatter.slug}/`}
        >
          start
        </Link>

        <div className="framework-container">
          {module.frontmatter.frameworks.includes('angular') && (
            <FaAngular style={{ color: `var(--angular)` }} />
          )}
          {module.frontmatter.frameworks.includes('react') && (
            <FaReact style={{ color: `var(--react)` }} />
          )}
          {module.frontmatter.frameworks.includes('vue') && (
            <FaVuejs style={{ color: `var(--vue)` }} />
          )}
          {module.frontmatter.frameworks.includes('stencil') && (
            <FaJs style={{ color: `var(--stencil)` }} />
          )}
        </div>
      </div>
    </HeaderCard>
  );
};
