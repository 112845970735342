import { SEO } from '@sites/shared/ui/layout';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const PacksStyles = styled.div`
  padding: 1rem;
`;

const PacksPage = ({ data }: PageProps) => {
  const { site } = data as GatsbyTypes.PacksPageDataQuery;

  if (!site) return <></>;

  return (
    <PacksStyles>
      <SEO
        title={`Resources`}
        description={`Advanced Ionic resources to learn from and use in your own projects`}
        siteMetadata={site.siteMetadata}
      />
      <div className="title-bar secondary-background">
        <h1>Resources</h1>
      </div>
      <div
        className="info-box"
        style={{ textAlign: `center`, margin: `4rem 3rem 3rem 3rem` }}
      >
        <strong>
          This feature is not available yet, but I am aiming to launch it in the
          first half of 2022
        </strong>
      </div>
      <div className="info-box">
        <h1>What is this?</h1>
        <p>
          This page will contain premium resources that will be similar to
          themes/starter applications, but with more emphasis on learning and
          education rather than being intended to be used as a{' '}
          <em>plug-and-play</em> style item.
        </p>
        <p>
          A resource might include a collection of best practice animation
          examples, or a shop front application with a solid state management
          foundation baked in, or a complex application with well-designed unit
          and E2E tests. Along with the code itself and resources, there will
          also typically be additional supporting education material to help
          understand the practices being used and to implement them yourself.
        </p>
        <p>
          You will be free to use these resources for whatever commercial or
          non-commercial purposes you wish, but it is intended for use by
          developers who will be able to understand and make the required
          modifications to the code themselves. The primary focus of these
          resources will be on education and general skill improvement,{' '}
          <strong>not</strong> on saving time or development effort with
          resources that you can just drop into your workflow.
        </p>
        <p>
          All resources will be available for free to{' '}
          <span className="badge tilt secondary-background">PRO</span> members,
          and there will likely be some resources that are free for everyone.
        </p>
      </div>
    </PacksStyles>
  );
};

export default PacksPage;

export const query = graphql`
  query PacksPageData {
    site {
      ...SiteInformation
    }
  }
`;
