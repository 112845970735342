import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const TagStyles = styled.span`
  background-color: var(--medium);
  color: var(--lightest);
  margin-right: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  padding: 1rem;
`;

export interface TagProps {
  tag: string;
  noFrameworkPath?: boolean;
}

export const Tag = ({ tag, noFrameworkPath }: TagProps) => {
  const path = `/tags/${tag.replace(' ', '-')}/`;

  let backgroundColor = `var(--secondary)`;

  switch (tag) {
    case 'angular':
      backgroundColor = 'var(--angular)';
      break;
    case 'react':
      backgroundColor = 'var(--react)';
      break;
    case 'vue':
      backgroundColor = 'var(--vue)';
      break;
    case 'stencil':
      backgroundColor = 'var(--stencil)';
      break;
    default:
      backgroundColor = 'var(--secondary)';
  }

  return (
    <Link to={path}>
      <TagStyles style={{ backgroundColor: backgroundColor }} className="badge">
        {tag}
      </TagStyles>
    </Link>
  );
};
