import { MDXProvider } from '@mdx-js/react';
import React, { ReactNode } from 'react';

export interface MDXProviderPreviewProps {
  children: ReactNode;
  previewPercentage: number;
}

export const MDXProviderPreview = ({
  previewPercentage,
  children,
}: MDXProviderPreviewProps) => {
  return (
    <MDXProvider
      components={{
        wrapper: ({ isPreview = false, children }: any) => {
          if (isPreview && Array.isArray(children)) {
            const previewUpToIndex = Math.ceil(
              children.length * previewPercentage
            );
            const previewChildren = [...children].splice(0, previewUpToIndex);

            return <>{previewChildren}</>;
          }

          return <>{children}</>;
        },
      }}
    >
      {children}
    </MDXProvider>
  );
};
