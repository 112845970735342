import React from 'react';
import { Helmet } from 'react-helmet';

export interface SiteMetadata {
  title: string;
  description: string;
  siteUrl: string;
}

export interface SEOProps {
  title: string;
  siteMetadata: SiteMetadata;
  children?: any;
  location?: any;
  description?: string;
  image?: string;
}

export const SEO = ({
  children,
  location,
  description,
  title,
  siteMetadata,
  image,
}: SEOProps) => {
  return (
    <Helmet titleTemplate={`%s | ${siteMetadata.title}`}>
      <html lang="en" />
      <title>{title}</title>
      {/* FavIcons */}
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="alternate icon" href="/favicon.ico" />
      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta
        name="description"
        content={description || siteMetadata.description}
      />
      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta
        property="og:image"
        content={image || `${siteMetadata.siteUrl}/og-default.png`}
      />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site_name"
        content={siteMetadata.title}
        key="ogsitename"
      />
      <meta
        property="og:description"
        content={description || siteMetadata.description}
        key="ogdescription"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@joshuamorony" />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content={description || siteMetadata.description}
      />
      <meta
        name="twitter:image:src"
        content={image || `${siteMetadata.siteUrl}/og-default.png`}
      />
      <meta name="twitter:site" content="@joshuamorony" />
      {children}
    </Helmet>
  );
};
