import { LessonContent } from '@sites/eliteionic/articles/ui';
import {
  LessonFooter,
  LessonComments,
  LessonTwitter,
  LessonHeader,
} from '@sites/shared/ui/lesson';
import { SEO } from '@sites/shared/ui/layout';
import { useTocObserver } from '@sites/shared/utils';
import { graphql, PageProps } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

const SingleLessonStyles = styled.article`
  background-color: var(--lightest);
  position: relative;
  box-shadow: 5px 5px var(--primary);

  @media only screen and (max-width: 700px) {
    margin: 0;

    .author-container {
      position: unset;
    }
  }
`;

const SingleLessonPage = ({ data, location }: PageProps) => {
  const { lesson, othersInSeries, site } =
    data as GatsbyTypes.SingleLessonPageDataQuery;

  useTocObserver('aside .table-of-contents', 'article h2');

  if (
    !(
      lesson?.frontmatter &&
      lesson.frontmatter.background.childImageSharp &&
      site &&
      othersInSeries
    )
  ) {
    return;
  }

  return (
    <>
      <SingleLessonStyles>
        <SEO
          title={lesson.frontmatter.title}
          description={lesson.frontmatter.description}
          image={`https://eliteionic.com${
            getImage(lesson.frontmatter.background as any)?.images.fallback?.src
          }`}
          siteMetadata={site.siteMetadata}
        />
        <LessonHeader
          title={lesson.frontmatter.title}
          background={getImage(lesson.frontmatter.background as any)}
          icon={getImage(lesson.frontmatter.icon as any)}
        ></LessonHeader>
        <LessonContent pageProps={{ data, location }}></LessonContent>
        <LessonFooter pageProps={{ data }}></LessonFooter>
      </SingleLessonStyles>
      <LessonTwitter
        textToShare={encodeURIComponent(
          lesson.frontmatter.title + ' via @joshuamorony'
        )}
      ></LessonTwitter>
      <LessonComments
        basePath="https://eliteionic.com/tutorials/"
        slug={lesson.frontmatter.slug}
        bonus_link={lesson.frontmatter.bonus_link}
      ></LessonComments>
    </>
  );
};

export const query = graphql`
  query SingleLessonPageData($slug: String!, $series_slug: String = "none") {
    site {
      ...SiteInformation
    }
    lesson: mdx(frontmatter: { slug: { eq: $slug } }) {
      ...SinglePostDetails
    }
    othersInSeries: allMdx(
      filter: { frontmatter: { series_slug: { eq: $series_slug } } }
      sort: { fields: [frontmatter___order_in_series], order: ASC }
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
        }
      }
    }
  }
`;

export default SingleLessonPage;
