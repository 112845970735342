module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QLTL26S002"]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"languageAliases":{"toc":"bash","objc":"typescript"}}]],"gatsbyRemarkPlugins":[{"resolve":"/Users/joshuamorony/Dev/projects/sites/node_modules/gatsby-remark-responsive-iframe","id":"1a3d9dbf-c3c7-50dc-9f59-97f0cf8bb163","name":"gatsby-remark-responsive-iframe","version":"5.6.0","modulePath":"/Users/joshuamorony/Dev/projects/sites/node_modules/gatsby-remark-responsive-iframe/index.js","pluginOptions":{"plugins":[],"wrapperStyle":"margin-bottom: 1.0725rem"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/joshuamorony/Dev/projects/sites/node_modules/gatsby-remark-autolink-headers","id":"20e517f8-5d79-56d5-83ed-b36b6c1562b4","name":"gatsby-remark-autolink-headers","version":"5.6.0","modulePath":"/Users/joshuamorony/Dev/projects/sites/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"isIconAfterHeader":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/Users/joshuamorony/Dev/projects/sites/node_modules/gatsby-remark-images","id":"b8b1d25f-5891-5167-a5a7-cdfc99cfa1b9","name":"gatsby-remark-images","version":"6.6.0","modulePath":"/Users/joshuamorony/Dev/projects/sites/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1600,"withWebp":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/joshuamorony/Dev/projects/sites/apps/eliteionic","commonmark":false},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1600,"withWebp":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
