import React from 'react';
import styled from 'styled-components';

import { Tag } from '../../index';

const TagContainer = styled.div`
  text-align: center;

  span {
    background-color: transparent !important;
    border: 1px solid var(--light);
    border-radius: 3px;

    &:hover {
      background-color: var(--secondary) !important;
    }
  }
`;

export interface TagsProps {
  tags: string[];
  excludeTags?: string[];
  limit?: number;
  noFrameworkPath?: boolean;
}

export const Tags = ({
  tags,
  excludeTags,
  limit,
  noFrameworkPath,
}: TagsProps) => {
  if (excludeTags) {
    tags = tags.filter((tag) => !excludeTags.includes(tag));
  }

  const tagMap = tags.reduce(
    (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
    new Map()
  );

  const tagsSortedByFrequency = [...tagMap.entries()].sort(
    (a, b) => b[1] - a[1]
  );

  if (limit) {
    tagsSortedByFrequency.length = limit + 1;
  }

  return (
    <TagContainer className="tags-container">
      {tagsSortedByFrequency.map((tag) => (
        <Tag
          noFrameworkPath={noFrameworkPath ? true : false}
          key={tag[0]}
          tag={tag[0]}
        />
      ))}
    </TagContainer>
  );
};
