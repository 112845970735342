import React from 'react';
import styled from 'styled-components';

const SkeletonBarStyles = styled.div`
  .skeleton-bar {
    width: 100%;
    height: 30px;
    margin-bottom: 2rem;
  }
`;

export interface SkeletonBarsProps {
  amount: number;
  color?: string;
}

export const SkeletonBars = ({ amount, color }: SkeletonBarsProps) => {
  const bars = new Array(amount).fill(1);

  return (
    <SkeletonBarStyles>
      {bars.map((val, index) => {
        const randomSixtyToOneHundred =
          Math.floor(Math.random() * (100 - 60 + 1)) + 60;

        return (
          <div
            key={index}
            className="skeleton-bar"
            style={{
              backgroundColor: color || `var(--lighter, #cecece)`,
              width: `${randomSixtyToOneHundred}%`,
              marginBottom: index % 6 === 0 ? `6rem` : `2rem`,
            }}
          ></div>
        );
      })}
    </SkeletonBarStyles>
  );
};
