export const IONIC_START_MODULE_ORDER = [
  'welcome',
  'setting-up-environment',
  'getting-started-with-ionic',
  'basic-angular-concepts',
  'ionic-vs-angular',
  'simple-ionic-todo-app',
  'intro-to-rxjs',
  'basics-of-state-management',
  'basics-application-architecture',
  'ionic-quicklists',
  'intro-to-capacitor',
  'ionic-snapaday',
  'api-server-database',
  'ionic-giflist',
  'ngrx-component-store',
  'advanced-forms-and-validation',
  'ionic-chat',
  'advanced-capacitor',
  'conclusion',
];
