import { addModuleIcons } from '@sites/shared/utils';
import { ModuleFeed, SEO } from '@sites/shared/ui/layout';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const ModulesGrid = styled.div`
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;

  & > div {
    margin: 0 20px;
  }

  & > div:first-of-type {
    margin-top: 3rem;
  }

  .deprecated {
    opacity: 0.8;
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 1;
    }
  }
`;

const ModulesPage = ({ data }: PageProps) => {
  const { site, modules, lessons, deprecatedModules } =
    data as GatsbyTypes.ModulesPageDataQuery;

  if (!site) return <></>;

  const modulesWithIcons = addModuleIcons(modules.nodes).map((module) => {
    return {
      ...module,
      lessonCount:
        lessons.nodes.filter(
          (lesson) => lesson.frontmatter?.module === module.frontmatter.slug
        ).length - 1,
    };
  });

  return (
    <ModulesGrid>
      <SEO
        title={`Pro Modules`}
        description={`Advanced Ionic content organised into premium modules`}
        siteMetadata={site.siteMetadata}
      />
      <div className="title-bar secondary-background">
        <h1>Modules</h1>
      </div>
      <div className="info-box">
        <p>
          Elite Ionic's <strong>pro modules</strong> are high quality courses on
          various intermediate to advanced topics. All of these modules are
          available to{' '}
          <span className="badge tilt secondary-background">PRO</span>{' '}
          subscribers, but generally the first couple of lessons of each module
          are available for free.
        </p>
        <p>
          A breakdown of what is included in each module can be found below. The
          majority of the content is supplied in a <strong>written</strong>{' '}
          format with some <strong>supplemental videos</strong>.
        </p>
      </div>
      <div className="title-bar-simple">
        <h2>Choose a module</h2>
      </div>
      <ModuleFeed modules={modulesWithIcons}></ModuleFeed>
      <div className="title-bar-simple deprecated">
        <h2>Deprecated Modules</h2>
      </div>
      <div className="info-box text-center deprecated">
        <p>
          The following modules are deprecated and no longer receive updates.
          You can view individual deprecated modules for more information on why
          they are deprecated.
        </p>
      </div>
      <div className="deprecated">
        <ModuleFeed
          modules={addModuleIcons(deprecatedModules.nodes)}
        ></ModuleFeed>
      </div>
    </ModulesGrid>
  );
};

export default ModulesPage;

export const query = graphql`
  query ModulesPageData {
    site {
      ...SiteInformation
    }
    modules: allMdx(
      filter: {
        frontmatter: {
          type: { eq: "module" }
          deprecated: { ne: true }
          draft: { ne: true }
        }
      }
      sort: { fields: [frontmatter___lesson], order: ASC }
    ) {
      ...AllModuleDetails
    }
    deprecatedModules: allMdx(
      filter: {
        frontmatter: { type: { eq: "module" }, deprecated: { eq: true } }
      }
      sort: { fields: [frontmatter___lesson], order: ASC }
    ) {
      ...AllModuleDetails
    }
    lessons: allMdx(
      filter: { frontmatter: { type: { eq: "lesson" } } }
      sort: { fields: [frontmatter___lesson], order: ASC }
    ) {
      ...ModuleLessons
    }
  }
`;
