import React from 'react';
import styled from 'styled-components';
import { ModuleCard } from '../module-card/module-card';

const ModuleFeedStyles = styled.div`
  padding: 2rem;
  border: 2px dashed var(--secondary);

  max-width: 75ch;
  margin: 5rem auto;

  @media only screen and (max-width: 800px) {
    display: none;
  }

  a {
    font-weight: normal;

    svg {
      position: relative;
      top: -2px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  ol {
    list-style-type: none;
    padding: 0 0 0 2rem;

    li {
      margin-bottom: 1rem;
    }
  }

  .active-lesson {
    border-radius: 5px;
    padding: 1rem;
    border: 3px solid var(--success);
  }
`;

interface ModuleFeedProps {
  modules: any[];
}

export const ModuleFeedSimple = ({ modules }: ModuleFeedProps) => {
  console.log(modules);

  modules.sort((a, b) => a.frontmatter.order - b.frontmatter.order);

  return (
    <ModuleFeedStyles>
      <ol>
        {modules.map((module) => {
          return (
            <li key={module.id}>
              <a href={`/modules/${module.frontmatter.slug}/`}>
                <strong>{module.frontmatter.title}</strong>
                {!module.frontmatter.public ? (
                  <span className="badge tilt secondary-background">
                    MEMBER
                  </span>
                ) : (
                  <span className="badge tilt success-background">PUBLIC</span>
                )}
              </a>
            </li>
          );
        })}
      </ol>
    </ModuleFeedStyles>
  );
};
