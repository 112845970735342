import { ContentFeed, Pagination, SEO } from '@sites/shared/ui/layout';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const LessonsGrid = styled.div`
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;

  & > div {
    margin: 0 20px;
  }

  & > div:first-of-type {
    margin-top: 3rem;
  }
`;

const TagsPage = ({ data, pageContext }: PageProps) => {
  const { tutorials, allTutorials, site } =
    data as GatsbyTypes.TagsIndexPageDataQuery;

  const { tag, currentPage, skip } = pageContext as any;

  if (!(site && process.env['GATSBY_PAGE_SIZE'])) return <></>;

  const siteMetadata = site.siteMetadata;

  let allTags: string[] = [];

  allTutorials.nodes.forEach((tutorial) => {
    allTags = allTags.concat(tutorial.frontmatter?.tags || []);
  });

  return (
    <>
      <SEO
        title={`${tag.toUpperCase()} - Page ${currentPage || 1}`}
        siteMetadata={siteMetadata}
      />
      <LessonsGrid>
        <div className="title-bar secondary-background">
          <h1 style={{ textTransform: `uppercase` }}>{tag}</h1>
        </div>
        <Pagination
          pageSize={parseInt(process.env['GATSBY_PAGE_SIZE'])}
          totalCount={tutorials.totalCount}
          currentPage={currentPage || 1}
          skip={skip}
          base={`/tags/${tag.toLowerCase()}`}
        />
        <ContentFeed items={tutorials.nodes} basePath="/tutorials" />
        <Pagination
          pageSize={parseInt(process.env['GATSBY_PAGE_SIZE'])}
          totalCount={tutorials.totalCount}
          currentPage={currentPage || 1}
          skip={skip}
          base={`/tags/${tag.toLowerCase()}`}
        />
      </LessonsGrid>
    </>
  );
};

export default TagsPage;

export const query = graphql`
  query TagsIndexPageData($skip: Int = 0, $pageSize: Int = 10, $tag: String!) {
    site {
      ...SiteInformation
    }
    tutorials: allMdx(
      filter: { frontmatter: { type: { eq: "post" }, tags: { eq: $tag } } }
      limit: $pageSize
      skip: $skip
      sort: { fields: [frontmatter___updated], order: DESC }
    ) {
      ...AllPostDetails
    }
    allTutorials: allMdx(filter: { frontmatter: { type: { eq: "post" } } }) {
      nodes {
        frontmatter {
          tags
        }
      }
    }
  }
`;
