import {
  featuredTopics as eliteFeaturedTopics,
  getFeaturedLessonsFromCategory as eliteGetFeaturedLessonsFromCategory,
} from '@sites/eliteionic/shared/utils';
import {
  ContentFeed,
  FeaturedLessons,
  FeaturedTopics,
  Pagination,
  SEO,
} from '@sites/shared/ui/layout';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const LessonsGrid = styled.div`
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;

  & > div {
    margin: 0 20px;
  }

  & > div:first-of-type {
    margin-top: 3rem;
  }
`;

const CategoriesPage = ({ data, pageContext }: PageProps) => {
  const { tutorials, site } = data as GatsbyTypes.CategoriesIndexPageDataQuery;

  const { category, currentPage, skip } = pageContext as any;

  const featuredLessons = eliteGetFeaturedLessonsFromCategory(category, data);
  const featuredTopics = eliteFeaturedTopics;

  if (!(tutorials && site && process.env['GATSBY_PAGE_SIZE'])) return <></>;

  return (
    <>
      <SEO
        title={`${category} - Page ${currentPage || 1}`}
        description={`Browse content by topic`}
        siteMetadata={site.siteMetadata}
      />
      <LessonsGrid>
        {currentPage === 1 && (
          <FeaturedTopics
            category={category}
            topics={featuredTopics}
          ></FeaturedTopics>
        )}
        <div className="title-bar secondary-background">
          <h1>{category}</h1>
        </div>
        {!currentPage && (
          <FeaturedLessons lessons={featuredLessons} basePath="/tutorials" />
        )}
        <Pagination
          pageSize={parseInt(process.env['GATSBY_PAGE_SIZE'])}
          totalCount={tutorials.totalCount}
          currentPage={currentPage || 1}
          skip={skip}
          base={`/topics/${category.toLowerCase()}`}
        />
        <ContentFeed items={tutorials.nodes} basePath="/tutorials" />
        <Pagination
          pageSize={parseInt(process.env['GATSBY_PAGE_SIZE'])}
          totalCount={tutorials.totalCount}
          currentPage={currentPage || 1}
          skip={skip}
          base={`/topics/${category.toLowerCase()}`}
        />
      </LessonsGrid>
    </>
  );
};

export default CategoriesPage;

export const query = graphql`
  query CategoriesIndexPageData(
    $skip: Int = 0
    $pageSize: Int = 10
    $category: String!
  ) {
    ...FeaturedLessons
    site {
      ...SiteInformation
    }
    tutorials: allMdx(
      filter: {
        frontmatter: { type: { eq: "post" }, categories: { eq: $category } }
      }
      limit: $pageSize
      skip: $skip
      sort: { fields: [frontmatter___updated], order: DESC }
    ) {
      ...AllPostDetails
    }
  }
`;
