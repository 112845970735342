import { sidebarStyles } from '@sites/eliteionic/shared/styles';
import { isLoggedIn } from '@sites/eliteionic/shared/utils';
import { SourceLink } from '@sites/shared/ui/general';
import { MdxHeading, MdxTOC } from '@sites/shared/ui/layout';
import { LessonForm } from '@sites/shared/ui/lesson';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const SidebarStyles = styled.aside`
  ${sidebarStyles}
`;

export interface LessonSidebarProps {
  tocHeadings: MdxHeading[];
  bonus_type?: string;
  bonus_link?: string;
}

export const LessonSidebar = ({
  tocHeadings,
  bonus_link,
  bonus_type,
}: LessonSidebarProps) => {
  return (
    <SidebarStyles>
      <div className="sticky-container">
        {!isLoggedIn() && (
          <Link to="/membership/" activeClassName="active">
            <span
              className="badge"
              style={{
                backgroundColor: `var(--dark)`,
                color: `var(--lightest)`,
                padding: `1rem`,
                marginBottom: `2rem`,
              }}
            >
              <span>
                Become a{' '}
                <span className="badge tilt secondary-background">PRO</span>{' '}
                member
              </span>
            </span>
          </Link>
        )}
        <MdxTOC headings={tocHeadings}></MdxTOC>
        {bonus_type === 'source' && bonus_link && (
          <SourceLink link={bonus_link}></SourceLink>
        )}
        <LessonForm
          action="https://app.convertkit.com/forms/2277296/subscriptions"
          sv="2277296"
          uid="26beaa19ca"
        ></LessonForm>
        <span
          style={{
            display: `flex`,
            justifyContent: `center`,
            position: `relative`,
            left: `12px`,
          }}
        >
          {' '}
          <a
            className="twitter-follow-button"
            href="https://twitter.com/joshuamorony"
            data-size="large"
          >
            Follow @joshuamorony
          </a>
        </span>
      </div>
    </SidebarStyles>
  );
};
