import { postStyles, sidebarStyles } from '@sites/eliteionic/shared/styles';
import { isLoggedIn, logout } from '@sites/eliteionic/shared/utils';
import { SEO } from '@sites/shared/ui/layout';
import {
  ModuleLessonContent,
  ModuleLessonFooter,
  ModuleLessonHeader,
} from '@sites/shared/ui/module';
import { useAuthGuard, useTocObserver } from '@sites/shared/utils';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const SingleModuleLessonStyles = styled.div`
  & > article {
    border-radius: 5px;
    margin: 3rem;
    background-color: var(--lightest);
    position: relative;
    box-shadow: 5px 5px var(--primary);
  }

  @media only screen and (max-width: 700px) {
    & > article {
      margin: 0;
      border-radius: 0;
    }

    .author-container {
      position: unset;
    }
  }
`;

const SingleModuleLesson = ({ pageContext, location, data }: PageProps) => {
  const { lesson, lessons, site } =
    data as GatsbyTypes.SingleModuleLessonPageQuery;

  useAuthGuard((pageContext as any).preview, 'pro', isLoggedIn, logout);
  useTocObserver('aside .table-of-contents', 'article h2');

  if (
    !(
      lesson?.frontmatter &&
      typeof lesson.frontmatter.lesson !== 'undefined' &&
      site
    )
  )
    return <></>;

  return (
    <SingleModuleLessonStyles>
      <SEO
        title={`Lesson ${lesson.frontmatter.lesson}: ${lesson.frontmatter.title}`}
        description={lesson.frontmatter.summary}
        siteMetadata={site.siteMetadata}
      />

      <article key={lesson.id}>
        <ModuleLessonHeader pageProps={{ data }}></ModuleLessonHeader>
        <ModuleLessonContent
          pageProps={{ data, pageContext, location }}
          postStyles={postStyles}
          sidebarStyles={sidebarStyles}
        ></ModuleLessonContent>
        <ModuleLessonFooter
          lesson={lesson}
          lessons={lessons}
        ></ModuleLessonFooter>
      </article>
    </SingleModuleLessonStyles>
  );
};

export const query = graphql`
  query SingleModuleLessonPage($module: String!, $lesson: Int!) {
    site {
      ...SiteInformation
    }
    lesson: mdx(
      frontmatter: { module: { eq: $module }, lesson: { eq: $lesson } }
    ) {
      ...ModuleLesson
    }
    module: mdx(frontmatter: { slug: { eq: $module } }) {
      ...SingleModuleDetails
    }
    lessons: allMdx(
      filter: {
        frontmatter: { type: { eq: "lesson" }, module: { eq: $module } }
      }
      sort: { fields: [frontmatter___lesson], order: ASC }
    ) {
      ...ModuleLessons
    }
  }
`;

export default SingleModuleLesson;
