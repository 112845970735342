import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IONIC_START_MODULE_ORDER } from '@sites/shared/utils';
import styled from 'styled-components';

export interface ModuleLessonFooterProps {
  lessons: GatsbyTypes.ModuleLessonsFragment;
  lesson: GatsbyTypes.ModuleLessonFragment;
}

const ModuleLessonFooterStyles = styled.footer`
  padding: 1rem;
  background-color: var(--lighter);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  a {
    color: var(--lightest);
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterNav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;

  a:hover {
    text-decoration: none;
  }
`;

export const ModuleLessonFooter = ({
  lessons,
  lesson,
}: ModuleLessonFooterProps) => {
  if (
    !lesson.frontmatter?.lesson &&
    typeof lesson.frontmatter?.lesson !== 'number'
  )
    return <></>;

  const lessonsStartAtZero = lessons.nodes[0].frontmatter?.lesson === 0;
  const numberOfLessons = lessons.nodes.length;

  const nextLessonAvailable =
    lesson.frontmatter.lesson <
    (lessonsStartAtZero ? numberOfLessons - 1 : numberOfLessons);

  const nextLessonIndex = lessonsStartAtZero
    ? lesson.frontmatter.lesson + 1
    : lesson.frontmatter.lesson;

  const prevLessonAvailable =
    lesson.frontmatter.lesson > (lessonsStartAtZero ? 0 : 1);

  const prevLessonIndex = lessonsStartAtZero
    ? lesson.frontmatter.lesson - 1
    : lesson.frontmatter.lesson - 2;

  const nextLesson = nextLessonAvailable
    ? lessons.nodes[nextLessonIndex]
    : false;

  const prevLesson = prevLessonAvailable
    ? lessons.nodes[prevLessonIndex]
    : false;

  const currentModuleIndex = IONIC_START_MODULE_ORDER.indexOf(
    lesson.frontmatter.module || ''
  );

  const nextModuleSlug =
    currentModuleIndex < IONIC_START_MODULE_ORDER.length - 1
      ? IONIC_START_MODULE_ORDER[currentModuleIndex + 1]
      : false;

  return (
    <ModuleLessonFooterStyles>
      <FooterNav>
        {prevLesson && prevLesson.frontmatter ? (
          <a
            href={
              prevLesson.frontmatter.public === true
                ? `/modules/${prevLesson.frontmatter.module}/${prevLesson.frontmatter.lesson}`
                : `/${prevLesson.frontmatter.path}/modules/${prevLesson.frontmatter.module}/${prevLesson.frontmatter.lesson}`
            }
            style={{ float: `right` }}
            className="button light-background"
          >
            <FaChevronLeft /> Previous lesson
          </a>
        ) : (
          <span></span>
        )}

        {nextLesson && nextLesson.frontmatter ? (
          <a
            href={
              nextLesson.frontmatter.public === true
                ? `/modules/${nextLesson.frontmatter.module}/${nextLesson.frontmatter.lesson}`
                : `/${nextLesson.frontmatter.path}/modules/${nextLesson.frontmatter.module}/${nextLesson.frontmatter.lesson}`
            }
            style={{ float: `right` }}
            className="button success-background"
          >
            Next lesson <FaChevronRight />
          </a>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {nextModuleSlug && (
              <a
                href={`/modules/${nextModuleSlug}`}
                style={{ float: `right` }}
                className="button success-background"
              >
                Next module <FaChevronRight />
              </a>
            )}
          </>
        )}
      </FooterNav>
    </ModuleLessonFooterStyles>
  );
};
