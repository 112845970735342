import {
  handleLogin,
  isLoggedIn,
  logout,
  useForm,
} from '@sites/eliteionic/shared/utils';
import { SEO } from '@sites/shared/ui/layout';
import { graphql, Link, PageProps } from 'gatsby';
import React, { FormEvent, useState } from 'react';
import Helmet from 'react-helmet';
import { FaCheckCircle, FaKey } from 'react-icons/fa';
import styled from 'styled-components';

const ProStyles = styled.div`
  ul {
    list-style-type: none;

    svg {
      position: relative;
      top: -2px;
      fill: var(--success);
    }
  }

  small {
    color: var(--light);
  }

  .purchase {
    width: 100%;
    display: block;
    padding: 2rem;
    margin-top: 2rem;
    font-size: 1.2em;
  }

  form {
    padding: 2rem;
    border-radius: 5px;
    background-color: var(--lightest);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    fieldset {
      display: grid;
      gap: 1rem;
      grid-column: span 2;
      max-height: 600px;
      overflow: auto;
      label {
        display: grid;
        gap: 1rem;
        align-content: start;
        font-weight: bold;
      }
      label + label {
        margin-top: 1rem;
      }
      p {
        font-size: 0.9em;
        margin: 0;
        color: var(--light);
      }
    }
    @media (max-width: 900px) {
      fieldset.menu,
      fieldset.order {
        grid-column: span 2;
      }
    }
  }
`;

const ProMembership = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 75ch;

  svg {
    font-size: 5rem;
    margin: 1rem 1rem 3rem 1rem;
  }

  .fancy-box-outer {
    height: initial;
    box-shadow: 0px 0px 30px 0px var(--shadow-transparent);
  }

  .fancy-box-inner {
    padding: 4rem;
  }
`;

const ProPage = ({ data }: PageProps) => {
  const { site } = data as GatsbyTypes.ProPageDataQuery;
  const { values, updateValue } = useForm({
    license: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  if (!site) return <></>;

  const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setLoading(true);

    try {
      await handleLogin(values);
      setLoading(false);
      return true;
    } catch (err) {
      setLoading(false);
      setError(
        'Authentication failed. Please check your License Key and try again. If you are still unable to log in, please contact me for help.'
      );
      throw err;
    }
  };

  const handleLogout = () => {
    logout();
    if (window) {
      window.location.reload();
    }
  };

  return (
    <ProStyles>
      <Helmet>
        {/* <script src="https://gumroad.com/js/gumroad.js"></script> */}
      </Helmet>
      <SEO
        title={`Become a Pro`}
        description={`Get access to all of the pro content on Elite Ionic by becoming a Pro Member`}
        siteMetadata={site.siteMetadata}
      />
      {isLoggedIn() && (
        <div className="fancy-box-outer">
          <div className="fancy-box-inner">
            <div className="info-box">
              <h1 style={{ textAlign: `center` }}>Welcome!</h1>
              <p>
                You are now logged in and have access to all of the{' '}
                <span className="badge tilt secondary-background">PRO</span>{' '}
                content on this site. To get started, click on one of the links
                below:
              </p>
              <p>
                <Link to={`/modules/`}>
                  <span className="badge secondary-background purchase">
                    Browse modules
                  </span>
                </Link>
              </p>
              <p>
                <button
                  className="badge lighter-background purchase"
                  style={{ boxShadow: `none`, textTransform: `capitalize` }}
                  onClick={() => handleLogout()}
                >
                  Logout
                </button>
              </p>
            </div>
          </div>
        </div>
      )}

      {!isLoggedIn() && typeof window !== 'undefined' && (
        <>
          <div className="fancy-box-outer">
            <div className="fancy-box-inner">
              {new URLSearchParams(window?.location.search).has('p') ? (
                <ProMembership>
                  <div className="fancy-box-outer">
                    <div className="fancy-box-inner">
                      <FaKey />
                      <p>
                        The lesson you tried to access is only available to{' '}
                        <strong>pro members</strong>. Check out the information
                        below if you are interested in getting a{' '}
                        <strong>pro membership</strong> (or{' '}
                        <a href="#login">log in</a> if you are already a
                        member).
                      </p>
                    </div>
                  </div>
                </ProMembership>
              ) : (
                <></>
              )}

              <div className="info-box">
                <h1 style={{ textAlign: `center` }}>
                  Become a{' '}
                  <span className="badge tilt secondary-background">PRO</span>{' '}
                  member
                </h1>
                <p style={{ textAlign: `center` }}>
                  Already a pro member? <a href="#login">Log in</a> below
                </p>
                <p>
                  Becoming a{' '}
                  <span className="badge tilt secondary-background">PRO</span>{' '}
                  member of <strong>Elite Ionic</strong> will grant you access
                  to all of the pro content on this website. At the moment, pro
                  memberships are available for a once-off lifetime membership
                  fee. Pay once, and get access to the following content
                  forever:
                </p>
                <ul>
                  <li>
                    <FaCheckCircle /> All current and future{' '}
                    <Link className="special-link" to={`/modules/`}>
                      pro modules
                    </Link>{' '}
                    <small>
                      (<strong>139</strong> lessons over <strong>7</strong>{' '}
                      modules and growing)
                    </small>
                  </li>
                  <li>
                    <FaCheckCircle /> All future{' '}
                    <Link className="special-link" to={`/resources/`}>
                      pro resources
                    </Link>{' '}
                    <small>(under development)</small>
                  </li>
                </ul>
                <p>
                  Elite Ionic contains only <strong>intermediate</strong> to{' '}
                  <strong>advanced</strong> level content. Most modules are
                  generally around <strong>80%</strong> written content, and{' '}
                  <strong>20%</strong> videos.
                </p>
                <p>
                  All of the pro content is created and maintained by{' '}
                  <a href="https://www.joshmorony.com/">Josh Morony</a>, an
                  officially recognised Ionic{' '}
                  <a href="https://ionicframework.com/community">
                    community leader
                  </a>{' '}
                  and author of multiple popular Ionic{' '}
                  <a href="https://www.joshmorony.com/books/">books</a> that
                  have helped thousands of developers.
                </p>
                <p style={{ marginBottom: `1rem` }}>
                  <a
                    role="button"
                    href="https://joshmorony.lemonsqueezy.com/checkout/buy/e7277d0403debbe506329ac9b3b6d6fed38b48ea"
                  >
                    <span className="badge secondary-background purchase">
                      Become a PRO{' '}
                      <span
                        style={{
                          backgroundColor: `var(--lightest)`,
                          color: `var(--darker)`,
                          position: `relative`,
                          top: `-2px`,
                          left: `5px`,
                        }}
                        className="badge"
                      >
                        <span>$179</span>
                        {/* <span
                          style={{
                            opacity: `0.5`,
                            marginLeft: `5px`,
                            textDecoration: `line-through`,
                            textDecorationThickness: `2px`,
                          }}
                        >
                          {' '}
                          $179
                        </span> */}
                      </span>
                    </span>
                  </a>
                </p>
                <p style={{ textAlign: `center`, color: `var(--light)` }}>
                  <small>Once-off fee, lifetime membership</small>
                  <br />
                  <small>
                    All prices are in <strong>USD</strong>. Listed price{' '}
                    <strong>excludes</strong> VAT for EU customers, and GST for
                    AU customers.
                  </small>
                  <br />
                  <small>14-day no questions asked refund policy</small>
                </p>
                <p style={{ textAlign: `center` }}>
                  <small>
                    Each pro membership purchase includes a non-transferable
                    license for one person. If your organisation requires
                    multiple licenses you can make multiple purchases, or you
                    can <Link to={'/contact/'}>contact me</Link> to arrange a
                    custom package.
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="title-bar-simple">
            <a href="#login" id="login">
              <h1>Existing Members</h1>
            </a>
          </div>
          <form
            onSubmit={async (ev) => {
              try {
                await handleSubmit(ev);
                if (window) {
                  const urlParams = new URLSearchParams(window.location.search);
                  if (urlParams.has('p')) {
                    const param = urlParams.get('p');
                    if (param) {
                      window.location.href = decodeURIComponent(param);
                    }
                  }
                }
              } catch (err) {
                console.log(err);
              }
            }}
          >
            <fieldset disabled={loading}>
              <label htmlFor="license">
                License Key
                <p>
                  Your License Key can be found in your purchase email, it will
                  look something like this:{' '}
                  <code>55A1234F-1EEA4738-CCBF1092-1CE98AB33</code>. Please
                  enter the license key exactly as it appears, including the
                  hyphens.
                </p>
                <input
                  type="text"
                  name="license"
                  id="license"
                  value={values.license}
                  onChange={updateValue}
                />
              </label>
            </fieldset>
            <fieldset disabled={loading}>
              <div aria-live="polite" aria-atomic="true">
                {error !== '' ? (
                  <p style={{ textAlign: `center`, color: `var(--danger)` }}>
                    {error}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <button type="submit" disabled={loading}>
                <span aria-live="assertive" aria-atomic="true">
                  {loading ? 'Authenticating...' : ''}
                </span>
                {loading ? '' : 'Log in'}
              </button>
            </fieldset>
          </form>
        </>
      )}
    </ProStyles>
  );
};

export default ProPage;

export const query = graphql`
  query ProPageData {
    site {
      ...SiteInformation
    }
  }
`;
