import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const PaginationStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 2rem 0;
  border-radius: 5px;
  text-align: center;

  a {
    max-width: 100px;
    color: var(--lightest);

    &:hover {
      color: var(--primary);
      text-decoration: underline;
    }
  }

  & > * {
    padding: 1rem;
    flex: 1;
    border: 1px solid var(--light);
    margin: 2px;
    text-decoration: none;
    &[aria-current],
    &.current {
      color: var(--primary);
    }
    &[disabled] {
      pointer-events: none;
      color: var(--light);
    }
  }

  @media only screen and (max-width: 800px) {
    .page-number {
      display: none;
    }
  }
`;

export interface PaginationProps {
  pageSize: number;
  totalCount: number;
  currentPage: number;
  skip: number;
  base: string;
}

export const Pagination = ({
  pageSize,
  totalCount,
  currentPage,
  skip,
  base,
}: PaginationProps) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  const prevPage = currentPage - 1 === 1 ? '' : currentPage - 1;
  const nextPage = currentPage + 1;
  const hasNextPage = nextPage <= totalPages;
  const hasPrevPage = prevPage >= 1 || prevPage === '';

  return (
    <PaginationStyles className="pagination">
      <Link disabled={!hasPrevPage} to={`${base}/${prevPage}/`}>
        Prev
      </Link>
      {Array.from({ length: totalPages }).map((_, i) => (
        <Link
          key={i}
          className={
            currentPage === 1 && i === 0 ? 'current page-number' : 'page-number'
          }
          to={`${base}/${i > 0 ? i + 1 + '/' : ''}`}
        >
          {i + 1}
        </Link>
      ))}

      <Link disabled={!hasNextPage} to={`${base}/${nextPage}/`}>
        Next
      </Link>
    </PaginationStyles>
  );
};
