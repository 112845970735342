import styled from 'styled-components';

export const postStyles = `
  overflow: auto;

  @media only screen and (max-width: 700px) {
    .anchor.after {
      display: none;
    }
  }

  h1 {
    font-size: 2.3em;
    font-weight: bold;
    text-align: center;
  }

  h2 {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }

  h3 {
    font-size: 1.6em;
    font-weight: bold;
    text-align: center;
  }

  h4 {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
  }

  h5 {
    font-size: 1em;
    text-align: center;
  }

  h6 {
    font-size: 0.8em;
    text-align: center;
  }

  hr {
    background-color: var(--primary);
    height: 5px;
    border: none;
    margin-bottom: 3rem;
  }

  .anchor.after {
    position: absolute;
  }

  p {
    max-width: 75ch;
    color: var(--medium);
    margin: 0 auto 5rem auto;
    font-size: 2.1rem;

    strong {
      color: var(--darker);
    }
  }

  .grvsc-container {
    margin-bottom: 4rem;

    code {
      padding: 2rem;
    }
  }

  ul {
    max-width: 75ch;
    margin: 5rem auto !important;
  }

  .table-of-contents {
    max-width: 75ch;
    margin: auto;

    a {
      font-weight: normal;
    }

    ol {
      padding: 3rem;
      padding-top: 0;
      padding-left: 4rem;
    }

    li {
      padding: 1rem;
    }
  }

  ul,
  ol {
    font-size: 1.1em;
    font-weight: 300;
    margin: 5rem 0;
    line-height: 2;
    border: 3px solid var(--lighter);
    padding: 2rem 0 2rem 4rem;

    ul,
    ol {
      border: none;
      margin: 0;
      padding-bottom: 0 !important;
      list-style-type: lower-alpha;
      font-size: 2rem;
    }
  }

  blockquote {
    border-left: 10px solid var(--primary);
    text-align: center;
    background-color: var(--lightest-tint);
    margin: 1.5em 10px;
    padding: 0.5em 10px;
  }

  blockquote p {
    display: inline;
    font-style: italic;
    color: var(--light);
  }

  video {
    display: block;
    margin: 4rem auto;
    max-height: 800px;
    max-width: 100%;
    width: auto;
    border: 20px solid var(--lighter);
    border-radius: 5px;
  }

  .preview-fade-out {
    height: 500px;
    width: 100%;
    position: relative;
    top: -500px;
    background: linear-gradient(
      0deg,
      var(--lightest) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;
