import testing from '@sites/eliteionic/shared/assets/../lib/images/models/testing.png';
import performance from '@sites/eliteionic/shared/assets/../lib/images/models/performance.png';
import api from '@sites/eliteionic/shared/assets/../lib/images/models/api.png';
import uiux from '@sites/eliteionic/shared/assets/../lib/images/models/uiux.png';
import components from '@sites/eliteionic/shared/assets/../lib/images/models/components.png';
import presentation from '@sites/eliteionic/shared/assets/../lib/images/models/presentation.png';
import firebase from '@sites/eliteionic/shared/assets/../lib/images/models/firebase.png';

export const getModuleIcon = (cardIcon: string) => {
  switch (cardIcon) {
    case 'testing':
      return testing;
    case 'performance':
      return performance;
    case 'native':
      return api;
    case 'uiux':
      return uiux;
    case 'code-quality':
      return components;
    case 'presentation':
      return presentation;
    case 'firebase':
      return firebase;
    default:
      return components;
  }
};

export const addModuleIcons = (modules: readonly any[]) => {
  return modules.map((module) => {
    return {
      ...module,
      frontmatter: {
        ...module.frontmatter,
        cardIcon: getModuleIcon(module.frontmatter.cardIcon),
      },
    };
  });
};

export const addModuleIcon = (module: any) => {
  return {
    ...module,
    frontmatter: {
      ...module.frontmatter,
      cardIcon: getModuleIcon(module.frontmatter.cardIcon),
    },
  };
};
