import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --darkest: hsla(240, 13%, 10%, 1);
    --darker: hsla(238, 8%, 11%, 1);
    --dark: hsla(238, 6%, 18%, 1);
    --medium: hsla(240, 7%, 18%, 1);
    --light: hsla(245, 7%, 40%, 1);
    --lighter: hsla(240, 9%, 90%, 1);
    --lightest: hsla(0, 0%, 95%, 1);
    --lightest-tint: hsla(240, 13%, 94%, 1);
    --primary: hsla(49, 100%, 51%, 1);
    --secondary: hsla(38, 99%, 51%, 1);
    --success: hsla(145, 63%, 49%, 1);
    --shadow: hsla(250, 30%, 21%, 1);
    --shadow-slight-transparent: hsla(250, 30%, 21%, 0.6);
    --shadow-transparent: hsla(250, 30%, 21%, 0.2);
    --content-background-shadow: hsla(238, 8%, 11%, 0.3);

    --angular: hsla(347, 100%, 43%, 1);
    --vue: hsla(154, 49%, 49%, 1);
    --react: hsla(192, 95%, 68%, 1);
    --stencil: hsla(220, 60%, 3%, 1);
    --js: #F0DB4F;
  }
  html {
    font-size: 10px;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: var(--darkest);
    font-size: 2rem;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 600;
  }

  h3 {
    font-weight: 500;
  }

  h4 {
    font-weight: 400;
  }

  h5 {
    font-weight: 300;
  }

  h6 {
    font-weight: 200;
  }

  p {
    font-weight: 300;
    line-height: 1.8;
    color: var(--medium);
  }

  a {
    color: var(--medium);
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: var(--medium);
      text-decoration: none;
    }    
  }

  .link-bar a {
    color: var(--lightest);

    &:hover {
      text-decoration: underline;
    }
  }

  p a, .table-of-contents a {
    box-shadow: inset 0px -4px var(--primary);
    color: var(--darker);
    font-weight: 700;
    text-decoration: none;

    &:hover {
      box-shadow: inset 0px -4px var(--secondary);
      text-decoration: none;
    }  
  }

  .table-of-contents p {
    margin-bottom: 1rem !important;
  }

  .special-link {
    box-shadow: inset 0px -4px var(--primary);
    color: var(--darker);
    font-weight: 700;
    text-decoration: none;

    &:hover {
      box-shadow: inset 0px -4px var(--secondary);
      text-decoration: none;
    }
  }

  .alternate-link {
    color: var(--lightest);

    &:hover {
      color: var(--lighter);
    }
  }

  .underline {
    box-shadow: inset 0px -4px var(--primary);
    color: var(--darker);
    font-weight: 700;
    -webkit-text-decoration: none;
    text-decoration: none;
  }

  strong {
    font-weight: 700 !important;
  }

  button, .module-begin-button {
    box-shadow: 0 5px 20px -10px var(--shadow-slight-transparent);
    background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
    font-size: 1em;

    color: #fff;
    border: 0;
    border-radius: 5px;
    text-transform: lowercase;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-out;
    &:hover {
      color: #fff;
      box-shadow: 3px 3px 0px 0px var(--primary);
    }
  }

  .module-begin-button {
    margin: 2rem;
    background: var(--darker);
    transition: 0s;

    &:hover {
      background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
    }
  }

  input {
    border: none;
    border-radius: 5px;
    margin: 0.5rem;
    padding: 1rem;

    &:focus {
      outline: none;
    }
  }

  hr {
    border: 0;
    height: 8px;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .tilt-reverse {
    transform: rotate(2deg) !important;
  }

  .badge {
    background-color: var(--lightest);
    padding: 5px;
  }

  .big-badge {
    width: 200px;
    display: block;
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: 1.1em;
  }

  .button {
    padding: 1rem;
    display: block;
    border-radius: 3px;
    font-weight: 400;

    svg {
      position: relative;
      top: -1px;
    }

    &.success-background {
      box-shadow: 2px 2px 0px var(--lightest);
    }

    &.success-background:hover {
      color: var(--lightest);
    }

    &.light-background {
      color: var(--light);
    }
  }

  .primary-background {
    background-color: var(--primary) !important;
  }

  .secondary-background {
    color: var(--lightest);
    background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
  }

  .danger-background {
    color: var(--lightest);
    background-color: var(--angular) !important;
  }

  .dark-hover {
    &:hover {
      background-color: var(--dark) !important;
    }
  }

  .lighter-background {
    color: var(--darker);
    background-color: var(--lighter) !important;
  }

  .success-background {
    color: var(--lightest);
    background-color: var(--success) !important;
  }
  

  .title-bar {
    background-color: var(--lightest);
    border-radius: 5px;
    margin: 2rem 0;
    padding: 2rem;
    box-shadow: 5px 5px var(--primary);
    text-shadow: 2px 2px var(--medium);
    transform: rotate(0.5deg);
    transition: 0.1s linear;

    &:hover {
      text-shadow: 4px 4px var(--darker);
      transform: rotate(0deg);
    }
  }

  .title-bar-simple {
    background-color: var(--lightest);
    border-radius: 5px;
    margin: 2rem 0;
    padding: 2rem;
    box-shadow: 5px 5px var(--primary);

    h2 {
      font-size: 1.5em;
    }
  }

  .link-bar {
    background-color: var(--darkest);
    border-radius: 5px;
    margin: 2rem 0;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .info-box {
    background-color: var(--lightest);
    padding: 3rem;
    margin-bottom: 2rem;

    h1 {
      font-weight: 700;
      text-align: center;
    }
  
    h2 {
      font-weight: 600;
      text-align: center;
    }
  
    h3 {
      font-weight: 500;
      text-align: center;
    }
  
    h4 {
      font-weight: 400;
      text-align: center;
    }
  
    h5 {
      font-weight: 300;
      text-align: center;
    }
  
    h6 {
      font-weight: 200;
      text-align: center;
    }
  
    p {
      font-weight: 300;
      line-height: 1.6em;
      color: var(--medium);
      margin: 0 auto 5rem auto;
      max-width: 75ch;
  
      strong {
        color: var(--darker);
      }
    }

    p:last-of-type {
      margin-bottom: 0 !important;
    }
  
    ul,
    ol {
      max-width: 75ch;
      margin: 5rem auto !important;
    }
  }

  .fancy-box-outer {
    height: 100%;
    padding: 3px;
    border-radius: 4px;
    background: linear-gradient(60deg, var(--primary), var(--lightest) 40%, var(--secondary) 80%);
    margin: 4rem;
  }

  .fancy-box-inner {
    padding: 4rem;
    background-color: var(--lightest);
  }

  .embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .center-flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .angular-text-gradient {
    background: linear-gradient(135deg, var(--angular) 0%, #ff0037 100%);
    color: var(--angular);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
  }

  .elite-text-gradient {
    background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
    color: var(--primary);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
  }

  .svg-blur {
    filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='8' result='b'/%3E%3CfeMorphology operator='dilate' radius='4'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E %3C/svg%3E#a");
  }

  @keyframes popIn {
    0% {
      opacity: 0;
      transform: scale(0.6) translateY(-8px);
    }

    100% {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes sway {
    0% {
      transform: translate3d(0, 0, 0);
    }

    50% {
      transform: translate3d(0, 2px, 0);
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @media only screen and (max-width: 700px){}
    .fancy-box-outer {
      margin: 4rem 0;
    }

    .fancy-box-inner {
      padding: 1rem;
    }
  }

`;
