import { featuredTopics } from '@sites/eliteionic/shared/utils';
import { FeaturedTopics, SEO } from '@sites/shared/ui/layout';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const TopicsGrid = styled.div`
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;

  & > div {
    margin: 0 20px;
  }

  & > div:first-of-type {
    margin-top: 3rem;
  }
`;

const TopicsPage = ({ data }: PageProps) => {
  const { site } = data as GatsbyTypes.TopicsPageDataQuery;

  if (!site) return <></>;

  return (
    <TopicsGrid>
      <SEO
        title={`Topics`}
        description={`Browse content by six main topics: Performance, UI/UX, Testing, Backend, Architecture, Native Integration`}
        siteMetadata={site.siteMetadata}
      />
      <div className="title-bar secondary-background">
        <h1>Topics</h1>
      </div>
      <div className="info-box">
        <p style={{ textAlign: `center` }}>
          Elite Ionic's content is broken down into <strong>six</strong> main
          topics. Advancing your expertise in each of these areas will help take
          your applications to the next level.
        </p>
      </div>
      <FeaturedTopics topics={featuredTopics} />
    </TopicsGrid>
  );
};

export default TopicsPage;

export const query = graphql`
  query TopicsPageData {
    site {
      ...SiteInformation
    }
  }
`;
