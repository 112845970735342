import { MdxHeading, MdxTOC } from '@sites/shared/ui/layout';
import React from 'react';
import styled from 'styled-components';

export interface ModuleLessonSidebarProps {
  isPreview: boolean;
  lesson: GatsbyTypes.ModuleLessonFragment;
  sidebarStyles: string;
}

export const ModuleLessonSidebar = ({
  isPreview,
  lesson,
  sidebarStyles,
}: ModuleLessonSidebarProps) => {
  const SidebarStyles = styled.aside`
    ${sidebarStyles}
  `;

  return (
    <SidebarStyles>
      <div
        className="sticky-container"
        style={{
          marginBottom: isPreview ? `550px` : `inherit`,
        }}
      >
        {isPreview && lesson.frontmatter?.video === null && (
          <p
            style={{
              padding: `2rem`,
              margin: `2rem`,
              border: `3px solid var(--secondary)`,
              backgroundColor: `var(--lighter)`,
              borderRadius: `5px`,
              textAlign: `center`,
            }}
          >
            <strong>Preview!</strong> The full version of this lesson is only
            available to{' '}
            <span className="badge secondary-background">
              {lesson.frontmatter.path?.toUpperCase()}
            </span>{' '}
            members
          </p>
        )}
        <MdxTOC headings={lesson.headings as MdxHeading[]}></MdxTOC>
      </div>
    </SidebarStyles>
  );
};
