import { GlobalStyles, Typography } from '@sites/eliteionic/shared/styles';
import { isLoggedIn } from '@sites/eliteionic/shared/utils';
import { Footer } from '@sites/eliteionic/shell/ui';
import headerBg from '@sites/shared/assets/../lib/images/backgrounds/gradient-sunset-blood.png';
import { Header, NavigationItem } from '@sites/shared/ui/layout';
import {
  EliteIonicLogo,
  PromoBanner,
  PromoContent,
  PromoExpireTime,
} from '@sites/shared/ui/marketing';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import 'normalize.css';
import React from 'react';
import {
  FaBook,
  FaFileCode,
  FaHandsHelping,
  FaLaptopCode,
} from 'react-icons/fa';
import styled from 'styled-components';

const SiteContainer = styled.div`
  background-color: var(--dark);
`;

const ContentStyles = styled.main`
  background: var(--dark);
  max-width: 1600px;
  margin: auto;
  border-radius: 5px;

  @media only screen and (min-width: 701px) {
    position: relative;
    top: -30px;
    box-shadow: 0 0 20px 0px var(--content-background-shadow);
    padding: 2rem;
  }
`;

export const Layout = ({ children, path, data }: any) => {
  // Queries
  const { allPosts, siteSearchIndex } = useStaticQuery(graphql`
    query {
      siteSearchIndex: siteSearchIndex {
        index
      }
      allPosts: allMdx(filter: { frontmatter: { type: { eq: "post" } } }) {
        nodes {
          frontmatter {
            tags
          }
        }
      }
    }
  `);

  const becomeProElement = (
    <span className="badge">
      <span
        style={{
          color: `var(--darker)`,
          textTransform: `none`,
          padding: `2rem`,
        }}
      >
        Become a <span className="badge tilt secondary-background">PRO</span>
      </span>
    </span>
  );

  // Navigation
  const navItems: NavigationItem[] = [
    {
      id: '1',
      title: 'Tutorials',
      linkTo: '/tutorials/',
      icon: <FaFileCode />,
    },
    {
      id: '2',
      title: 'Modules',
      linkTo: '/modules/',
      icon: <FaLaptopCode />,
      badgeText: 'PRO',
    },
    {
      id: '3',
      title: 'Resources',
      linkTo: '/resources/',
      icon: <FaBook />,
      badgeText: 'PRO',
    },
    {
      id: '4',
      title: 'Consulting',
      linkTo: '/consulting/',
      icon: <FaHandsHelping />,
    },
    {
      id: '5',
      title: 'Become a Pro',
      linkTo: '/pro-membership/',
      customElement: becomeProElement,
    },
  ];

  // Tags
  let allTags: string[] = [];

  allPosts.nodes.forEach((post: any) => {
    allTags = allTags.concat(post.frontmatter.tags);
  });

  const uniqueTags = Array.from(new Set(allTags)).filter(
    (tag) =>
      ![
        'performance',
        'ui',
        'ux',
        'testing',
        'backend',
        'architecture',
        'native',
      ].includes(tag.toLowerCase())
  );

  const bgImageExists = data?.lesson?.frontmatter?.background;

  const headerProps = {
    searchIndex: siteSearchIndex,
    searchBasePath: `/tutorials`,
    tags: uniqueTags,
    items: navItems,
    logo: EliteIonicLogo(),
    bgSrc: bgImageExists ? getSrc(bgImageExists) : headerBg,
  };

  const promoMessage = (
    <>
      <strong>LAUNCH</strong> sale ends in
    </>
  );

  const promoButton = (
    <>
      Get <span style={{ fontWeight: 900 }}>$50</span> off{' '}
      <span className="badge tilt secondary-background">PRO</span> membership
    </>
  );

  const promoContent: PromoContent = {
    message: promoMessage,
    buttonContent: promoButton,
  };

  const promoExpires: PromoExpireTime = {
    day: 15,
    month: 4,
    year: 2022,
    hour: 12,
    minute: 30,
  };

  return (
    <SiteContainer>
      <GlobalStyles />
      <Typography />
      {/*!isLoggedIn() && (
        <PromoBanner
          link={`https://eliteionic.com/pro-membership`}
          expires={promoExpires}
          content={promoContent}
        />
      )*/}
      <Header {...headerProps} />
      <ContentStyles>{children}</ContentStyles>
      <Footer />
    </SiteContainer>
  );
};
