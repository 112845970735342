import { addModuleIcon } from '@sites/shared/utils';
import { ModuleCard, ModuleOutline, SEO } from '@sites/shared/ui/layout';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const SingleModuleStyles = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(1);

  padding: 1rem;
`;

const ModuleGrid = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr 3fr;

  .header-card {
    max-height: 500px !important;

    .module-begin-button {
      display: none;
    }
  }

  @media only screen and (max-width: 1120px) {
    grid-template-columns: 1fr;

    .header-card {
      max-height: 1000px !important;
      height: inherit;
    }
  }
`;

const SingleModule = ({ data }: PageProps) => {
  const { module, lessons, site } =
    data as GatsbyTypes.SingleModulePageDataQuery;

  if (!(module?.frontmatter && lessons?.nodes && site)) {
    return;
  }

  return (
    <SingleModuleStyles>
      <SEO
        title={module.frontmatter.title}
        description={module.frontmatter.description}
        siteMetadata={site.siteMetadata}
      />
      {module.frontmatter.deprecated && (
        <div className="title-bar-simple">
          <p className="text-center">
            <span className="badge danger-background">DEPRECATED</span>
          </p>
          <p>{module.frontmatter.summary}</p>
        </div>
      )}
      <ModuleGrid>
        <ModuleCard
          key={module.id}
          module={addModuleIcon(module)}
          lessons={lessons}
        ></ModuleCard>
        <div>
          <ModuleOutline lessons={lessons} />
        </div>
      </ModuleGrid>
    </SingleModuleStyles>
  );
};

export const query = graphql`
  query SingleModulePageData($slug: String!) {
    site {
      ...SiteInformation
    }
    module: mdx(frontmatter: { slug: { eq: $slug } }) {
      ...SingleModuleDetails
    }
    lessons: allMdx(
      filter: { frontmatter: { type: { eq: "lesson" }, module: { eq: $slug } } }
      sort: { fields: [frontmatter___lesson], order: ASC }
    ) {
      ...ModuleLessons
    }
  }
`;

export default SingleModule;
