import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FaSearch } from 'react-icons/fa';
import { Input } from 'reactstrap';
import { Index } from 'elasticlunr';
import { Tag } from '../../index';

const ListItemStyle = styled.li`
  border: 1px solid var(--light);
  list-style-type: none;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export interface SearchProps {
  searchIndex: any;
  basePath: any;
}

export const Search = ({ searchIndex, basePath }: SearchProps) => {
  const index = useRef<Index<unknown> | null>(null);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<unknown[]>([]);

  const getOrCreateIndex = () => {
    return index.current !== null ? index.current : Index.load(searchIndex);
  };

  const search = (evt: any) => {
    index.current = getOrCreateIndex();
    setQuery(evt.target.value);

    const result = index.current
      .search(evt.target.value, {
        expand: true /*,
      fields: {
        tags: {boost: 1}
      }*/,
      })
      .map(({ ref }) => {
        if (index.current) {
          return index.current.documentStore.getDoc(ref);
        } else {
          return index.current;
        }
      })
      .filter((res: any) => res.type === 'post');

    setResults(result);
  };

  return (
    <div
      style={{
        position: `relative`,
      }}
    >
      <FaSearch
        style={{
          fontSize: `35px`,
          position: `absolute`,
          right: 15,
          top: 20,
        }}
      />
      <Input
        type="text"
        placeholder="search site by tag or title..."
        value={query}
        onChange={search}
        style={{
          height: `65px`,
          fontSize: `20px`,
        }}
      />
      <ul
        style={{
          opacity: results.length > 0 ? '1' : '0',
          transition: `opacity 0.2s linear`,
          backgroundColor: `var(--dark)`,
          padding: `2rem`,
          borderRadius: `10px`,
          marginTop: `20px`,
          color: `var(--lightest)`,
        }}
      >
        {results
          .sort((a: any, b: any) => {
            return (
              new Date(b.updated).getTime() - new Date(a.updated).getTime()
            );
          })
          .map((page: any) => {
            let path =
              page.type === 'lesson'
                ? `/${page.path}/modules/${page.module}/${page.lesson}/`
                : `${basePath}/${page.slug}/`;

            if (page.type === 'lesson' && page.public === true) {
              path = `/modules/${page.module}/${page.lesson}/`;
            }

            return (
              <ListItemStyle key={page.slug}>
                <Link
                  onClick={() => {
                    setQuery('');
                    setResults([]);
                  }}
                  style={{ color: `var(--lightest)`, marginRight: `1rem` }}
                  to={path}
                >
                  <h2>{page.title}</h2>
                </Link>
                <span>
                  {page.tags?.map((tag: string) => {
                    if (['angular', 'react', 'vue', 'stencil'].includes(tag)) {
                      return <Tag key={tag} tag={tag} />;
                    } else {
                      return <></>;
                    }
                  })}
                </span>
              </ListItemStyle>
            );
          })}
      </ul>
    </div>
  );
};
