import React from 'react';
import styled from 'styled-components';

const LogoStyles = styled.div`
  .cls-1 {
    fill: #478aff;
  }
`;

export const IonicLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 435.5 147">
      <path
        className="cls-1"
        d="M107.75,222.5a33.5,33.5,0,1,0,33.5,33.5A33.54,33.54,0,0,0,107.75,222.5Z"
        transform="translate(-34.25 -182.5)"
      />
      <circle className="cls-1" cx="121.6" cy="27.71" r="15.26" />
      <path
        className="cls-1"
        d="M174.64,225.55l-.64-1.4-1,1.16a23,23,0,0,1-9.21,6.34l-1,.36.4,1a60.09,60.09,0,1,1-29.71-31.21l.94.45.41-1a23,23,0,0,1,6.78-8.89l1.2-1-1.37-.71a72.68,72.68,0,0,0-33.7-8.19,73.51,73.51,0,1,0,66.89,43Z"
        transform="translate(-34.25 -182.5)"
      />
      <rect className="cls-1" x="184.83" y="41.5" width="15.5" height="64" />
      <rect className="cls-1" x="352.1" y="41.5" width="15.5" height="64" />
      <path
        className="cls-1"
        d="M350.56,222.5c-10.9,0-19.32,6.57-21.25,8.38h0l-1-6.88H315.41v64h15.5V249.25c0-7.56,10.32-13.82,17.87-13.82S362,242.65,362,251.25V288h15.5V252.78C377.45,236,370.21,222.5,350.56,222.5Z"
        transform="translate(-34.25 -182.5)"
      />
      <path
        className="cls-1"
        d="M442.54,276.37c-11.65,0-19.35-9.12-19.35-20.37s7.7-20.37,19.35-20.37c9.94,0,16,9.63,16,9.63l11.23-7.51c-3.25-5-7-8-12.12-11.1A28.62,28.62,0,0,0,443,222.5c-19.39,0-34.2,14.56-34.29,33.5.09,18.94,14.9,33.5,34.29,33.5a28.62,28.62,0,0,0,14.59-4.15c5.12-3.09,8.87-6.1,12.12-11.1l-11.23-7.51S452.48,276.37,442.54,276.37Z"
        transform="translate(-34.25 -182.5)"
      />
      <path
        id="path3000"
        className="cls-1"
        d="M275,222.5a33,33,0,0,0-33.53,33.23c0,18.15,13.58,33.77,33.76,33.77,18.27,0,33.24-14,33.24-33.38,0-22.19-17.41-33.62-33.47-33.62Zm.19,13.16c9.61,0,18.71,7.8,18.71,20.37,0,12.3-9.06,20.32-18.75,20.32-10.65,0-19-8.56-19-20.42,0-11.6,8.29-20.27,19.09-20.27Z"
        transform="translate(-34.25 -182.5)"
      />
    </svg>
  );
};

export const IonicLogoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 147 147">
      <path
        className="cls-1"
        d="M107.75,222.5a33.5,33.5,0,1,0,33.5,33.5A33.54,33.54,0,0,0,107.75,222.5Z"
        transform="translate(-34.25 -182.5)"
      />
      <circle className="cls-1" cx="121.6" cy="27.71" r="15.26" />
      <path
        className="cls-1"
        d="M174.64,225.55l-.64-1.4-1,1.16a23,23,0,0,1-9.21,6.34l-1,.36.4,1a60.09,60.09,0,1,1-29.71-31.21l.94.45.41-1a23,23,0,0,1,6.78-8.89l1.2-1-1.37-.71a72.68,72.68,0,0,0-33.7-8.19,73.51,73.51,0,1,0,66.89,43Z"
        transform="translate(-34.25 -182.5)"
      />
      <rect className="cls-1" x="184.83" y="41.5" width="15.5" height="64" />
      <rect className="cls-1" x="352.1" y="41.5" width="15.5" height="64" />
      <path
        className="cls-1"
        d="M350.56,222.5c-10.9,0-19.32,6.57-21.25,8.38h0l-1-6.88H315.41v64h15.5V249.25c0-7.56,10.32-13.82,17.87-13.82S362,242.65,362,251.25V288h15.5V252.78C377.45,236,370.21,222.5,350.56,222.5Z"
        transform="translate(-34.25 -182.5)"
      />
      <path
        className="cls-1"
        d="M442.54,276.37c-11.65,0-19.35-9.12-19.35-20.37s7.7-20.37,19.35-20.37c9.94,0,16,9.63,16,9.63l11.23-7.51c-3.25-5-7-8-12.12-11.1A28.62,28.62,0,0,0,443,222.5c-19.39,0-34.2,14.56-34.29,33.5.09,18.94,14.9,33.5,34.29,33.5a28.62,28.62,0,0,0,14.59-4.15c5.12-3.09,8.87-6.1,12.12-11.1l-11.23-7.51S452.48,276.37,442.54,276.37Z"
        transform="translate(-34.25 -182.5)"
      />
      <path
        id="path3000"
        className="cls-1"
        d="M275,222.5a33,33,0,0,0-33.53,33.23c0,18.15,13.58,33.77,33.76,33.77,18.27,0,33.24-14,33.24-33.38,0-22.19-17.41-33.62-33.47-33.62Zm.19,13.16c9.61,0,18.71,7.8,18.71,20.37,0,12.3-9.06,20.32-18.75,20.32-10.65,0-19-8.56-19-20.42,0-11.6,8.29-20.27,19.09-20.27Z"
        transform="translate(-34.25 -182.5)"
      />
    </svg>
  );
};
