import React, { useState, ReactElement } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { Search, Tags } from '../../index';

export interface NavigationItem {
  id: string;
  title: string;
  linkTo: string;
  icon?: ReactElement;
  badgeText?: string;
  isUrl?: boolean;
  customElement?: JSX.Element;
}

export interface HeaderProps {
  items: NavigationItem[];
  logo: JSX.Element;
  searchIndex?: any;
  searchBasePath?: string;
  tags?: string[];
  excludeTags?: string[];
  noFrameworkPath?: boolean;
  bgSrc?: any;
}

const SearchContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const HeaderStyles = styled.header`
  background-color: var(--darker);

  @media only screen and (max-width: 700px) {
    .search-container {
      display: none;
    }
  }
`;

const NavStyles = styled.nav`
  .logo-link {
    width: 15%;
  }

  @media only screen and (max-width: 991px) {
    .logo-link {
      width: 40%;
    }
  }

  .nav-item {
    width: 100%;
    margin: 1rem 0;
    padding-left: 3rem;
    transition: transform 0.2s ease-in-out;
    fill: #fff;
  }

  .nav-item:hover {
    fill: rgb(255, 211, 0);
    transform: rotate(2deg);
  }

  a {
    color: var(--lightest) !important;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    margin-left: 5px;
    box-shadow: none;
    font-weight: 300;
    text-transform: lowercase;
    transition: transform 0.2s ease-in-out;
  }

  .active {
    color: var(--primary) !important;
    font-weight: bold;
    font-size: 22px;
  }
`;

export const Header = ({
  searchIndex,
  searchBasePath,
  tags,
  excludeTags,
  noFrameworkPath,
  items,
  logo,
  bgSrc,
}: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HeaderStyles
      id="header"
      role="banner"
      itemType="http://schema.org/WPHeader"
      className="main-header"
      style={{
        position: `relative`,
        overflow: `hidden`,
      }}
    >
      {bgSrc && (
        <img
          style={{
            position: `absolute`,
            top: 0,
            left: 0,
            width: `100%`,
            height: `auto`,
            opacity: `0.3`,
            pointerEvents: `none`,
            filter: `blur(7px)`,
          }}
          alt="Header background"
          src={bgSrc}
        />
      )}
      <NavStyles>
        <Navbar
          dark
          expand="lg"
          style={{
            position: `relative`,
            boxShadow: `rgba(8, 8, 8, 0.4) 0px 0px 13px 1px`,
            paddingTop: `5px`,
            paddingBottom: `5px`,
            zIndex: 101,
          }}
        >
          <Link
            to="/"
            className="logo-link"
            style={{
              display: `inline-block`,
              padding: `1rem`,
              marginRight: `1rem`,
              fontSize: `1.25rem`,
              lineHeight: `inherit`,
              whiteSpace: `nowrap`,
              boxShadow: 'none',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            {logo}
          </Link>
          <NavbarToggler
            style={{ backgroundColor: `var(--secondary)` }}
            onClick={() => setIsOpen(!isOpen)}
          />
          <Collapse isOpen={isOpen} navbar>
            <Nav
              className="ml-auto"
              navbar
              style={{
                width: `100%`,
                justifyContent: `space-around`,
              }}
            >
              {items.map((item) => {
                const navItem = (
                  <NavItem>
                    {item.customElement ? (
                      item.customElement
                    ) : (
                      <>
                        {item.icon}{' '}
                        <span>
                          {item.title}{' '}
                          {item.badgeText && (
                            <span className="badge tilt secondary-background">
                              {item.badgeText}
                            </span>
                          )}
                        </span>
                      </>
                    )}
                  </NavItem>
                );

                return item.isUrl ? (
                  <a key={item.id} href={item.linkTo}>
                    {navItem}
                  </a>
                ) : (
                  <Link key={item.id} to={item.linkTo} activeClassName="active">
                    {navItem}
                  </Link>
                );
              })}
            </Nav>
          </Collapse>
        </Navbar>
      </NavStyles>

      <SearchContainer
        style={{ position: `relative` }}
        className="search-container"
      >
        {tags && (
          <Tags
            noFrameworkPath={noFrameworkPath ? true : false}
            tags={tags}
            excludeTags={excludeTags}
            limit={30}
          />
        )}
        {searchIndex && (
          <Search
            searchIndex={searchIndex.index}
            basePath={searchBasePath}
          ></Search>
        )}
      </SearchContainer>
    </HeaderStyles>
  );
};
