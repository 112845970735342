import { addModuleIcon } from '@sites/shared/utils';
import { ModuleCard } from '@sites/shared/ui/layout';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import React from 'react';

export interface ModuleLessonHeaderProps {
  pageProps: Pick<PageProps, 'data'>;
}

const TutorialHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr 2fr;
  position: relative;
  height: 50vh;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
  box-shadow: 0px 0px 7px 0px var(--shadow-transparent);

  @media only screen and (max-width: 1000px), screen and (max-height: 850px) {
    grid-template-columns: 1fr;

    .header-card {
      display: none;
    }

    .title-container {
      align-items: center;
    }
  }

  .featured-topic-container img {
    filter: grayscale(0) drop-shadow(2px 4px 6px var(--shadow-transparent)) !important;
  }

  h1 {
    color: var(--lightest);
    text-shadow: 3px 3px var(--dark);
  }

  p {
    background-color: var(--lightest);
    color: var(--dark);
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 0 20px 0px var(--shadow-transparent);
  }

  strong {
    font-size: 1.5em;
  }

  .header-card {
    margin: 0 auto;
    width: 80%;
    height: unset;
    filter: drop-shadow(2px 4px 6px var(--shadow-transparent));

    .description-container,
    .module-begin-button {
      display: none;
    }
  }

  .title-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 2rem;
  }
`;

export const ModuleLessonHeader = ({
  pageProps: { data },
}: ModuleLessonHeaderProps) => {
  const { module, lessons, lesson } =
    data as GatsbyTypes.SingleModuleLessonPageQuery;

  if (
    !(
      module?.frontmatter &&
      lessons &&
      lesson?.frontmatter &&
      lesson.frontmatter.lesson
    )
  )
    return <></>;

  return (
    <TutorialHeader>
      <ModuleCard
        key={module.id}
        module={addModuleIcon(module)}
        lessons={lessons}
      ></ModuleCard>
      <div className="title-container">
        {lesson.frontmatter.lesson > 0 && (
          <strong>Lesson {lesson.frontmatter.lesson}</strong>
        )}
        <h1>{lesson.frontmatter.title}</h1>
        {module.frontmatter.deprecated && (
          <p>
            <strong>WARNING:</strong> {module.frontmatter.summary}
          </p>
        )}
        <p>{lesson.frontmatter.summary}</p>
        {module.frontmatter.deprecated ? (
          <span
            style={{ position: `absolute`, top: `2rem`, right: `2rem` }}
            className="badge danger-background"
          >
            DEPRECATED
          </span>
        ) : (
          <span
            style={{ position: `absolute`, top: `2rem`, right: `2rem` }}
            className="badge secondary-background"
          >
            {lesson.frontmatter.path?.toUpperCase()}
          </span>
        )}
      </div>
    </TutorialHeader>
  );
};
