import phone from '@sites/eliteionic/shared/assets/../lib/images/models/phone-two-hand-hold.png';
import { addModuleIcons } from '@sites/shared/utils';
import { featuredTopics } from '@sites/eliteionic/shared/utils';
import {
  ContentFeed,
  FeaturedLessons,
  FeaturedTopics,
  ModuleFeed,
  SEO,
} from '@sites/shared/ui/layout';
import { graphql, Link, PageProps } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

const FormStyles = styled.form`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.5em;

  @media only screen and (max-width: 700px) {
    font-size: 1em;

    & > div {
      flex-direction: column;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    width: 95%;
  }

  button {
    margin-left: 0.5rem;
  }

  label {
    margin-bottom: 0px;
  }
`;

const VideoStyles = styled.div`
  background-color: var(--lightest);
  padding: 1rem;
`;

const IndexGrid = styled.div`
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;

  & > div {
    margin: 0 20px;
  }

  & > div:first-of-type {
    margin: 0;
  }

  .hero {
    display: grid;
    margin: 2rem !important;
    border-radius: 5px;
    grid-template-columns: 1fr 2fr;
    background-color: var(--lighter);
    padding-right: 4rem;
    color: #fff;
    box-shadow: 0px 15px 20px 0px var(--darker);
    background-image: radial-gradient(
      ellipse closest-side,
      rgba(24, 23, 33, 0.75),
      var(--dark)
    );
    margin-top: 40px;
    margin-bottom: 40px;

    .hero-left {
      display: flex;
      align-items: flex-end;
    }

    .hero-right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  @media only screen and (max-width: 1300px) {
    .hero {
      padding: 5rem 5rem 0 5rem;
      grid-template-columns: 1fr;
    }

    .hero-left {
      grid-row: 2;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 500px) {
    .hero-left .hand {
      display: none;
    }

    .hero h1,
    .hero form {
      display: none;
    }
  }
`;

const IndexPage = ({ data }: PageProps) => {
  const { site, modules, general, tutorials, lessons } =
    data as GatsbyTypes.IndexPageDataQuery;

  if (!site) return <></>;

  // Add imported icons to modules, and add lesson counts
  const modulesWithIcons = addModuleIcons(modules.nodes).map((module) => {
    return {
      ...module,
      lessonCount:
        lessons.nodes.filter(
          (lesson) => lesson.frontmatter?.module === module.frontmatter.slug
        ).length - 1,
    };
  });

  const featuredLessons = general.nodes;

  return (
    <IndexGrid>
      <Helmet>
        <script async src="https://f.convertkit.com/ckjs/ck.5.js"></script>
      </Helmet>
      <SEO
        title={`Build next level Ionic apps`}
        siteMetadata={site.siteMetadata}
      />
      <div className="hero text-center">
        <div className="hero-left">
          <img
            className="hand"
            style={{ maxWidth: `400px` }}
            src={phone}
            alt="3d cartoon hands holding a phone"
          />
        </div>
        <div className="hero-right">
          <h1 style={{ lineHeight: `1.5` }}>
            Advanced training for{' '}
            <span
              className="angular-text-gradient"
              style={{
                fontWeight: 900,
                filter: `drop-shadow(1px 1px 2px var(--darker))`,
              }}
            >
              Angular
            </span>{' '}
            developers who want to create{' '}
            <span
              className="elite-text-gradient"
              style={{
                borderBottom: `4px solid var(--primary)`,
                fontWeight: 900,
              }}
            >
              <em>NEXT LEVEL</em>
            </span>{' '}
            native web applications.
          </h1>
          <FormStyles
            action="https://app.convertkit.com/forms/2277269/subscriptions"
            method="post"
            data-sv-form="2277269"
            data-uid="42f52b2491"
            style={{
              width: `100%`,
            }}
          >
            <div data-element="fields">
              <label htmlFor="fields[first_name]">
                <input
                  type="text"
                  id="firstName"
                  name="fields[first_name]"
                  placeholder="first name"
                />
              </label>

              <label htmlFor="email_address">
                <input
                  type="email"
                  id="email"
                  name="email_address"
                  placeholder="email"
                />
              </label>

              <button data-element="submit">Subscribe</button>
              <ul data-element="errors" />
            </div>
          </FormStyles>
          <div style={{ margin: `1rem 0` }}>
            <p style={{ color: `var(--lighter)`, fontWeight: 900 }}>
              {' '}
              Get lifetime access to all of the content on this site:
            </p>
            <Link to="/membership">
              <span
                className="badge"
                style={{ fontSize: `1.2em`, padding: `1rem` }}
              >
                <span
                  style={{
                    color: `var(--darker)`,
                    textTransform: `none`,
                    padding: `2rem`,
                  }}
                >
                  Become a{' '}
                  <span className="badge tilt secondary-background">PRO</span>
                </span>
              </span>
            </Link>
          </div>
          {/*<small>
            Join the newsletter to instantly get access to{' '}
            <strong
              style={{
                fontWeight: 900,
                borderBottom: `2px solid var(--lightest)`,
              }}
            >
              3 pro resources
            </strong>{' '}
            for <strong>free</strong>
            </small>*/}
          <p style={{ color: `var(--light)`, padding: `3rem` }}>
            Elite Ionic only contains{' '}
            <strong>
              <em>intermediate</em>
            </strong>{' '}
            to{' '}
            <strong>
              <em>advanced</em>
            </strong>{' '}
            level content. For a course that suits a beginner to intermediate
            level, you can check out{' '}
            <a className="alternate-link" href="https://ionicstart.com">
              ionicstart.com
            </a>
          </p>
        </div>
      </div>
      <div className="title-bar secondary-background">
        <h1>Modules</h1>
      </div>
      <ModuleFeed modules={modulesWithIcons}></ModuleFeed>
      <div className="link-bar">
        <Link to={'/modules/'}>View all modules</Link>
      </div>

      <div className="title-bar secondary-background">
        <h1>Latest tutorials</h1>
      </div>

      <FeaturedLessons lessons={featuredLessons} basePath="/tutorials" />
      <FeaturedTopics topics={featuredTopics} />

      <div>
        <ContentFeed items={tutorials.nodes} basePath="/tutorials" />

        <div className="link-bar">
          <Link to={'/tutorials/'}>View all tutorials</Link>
        </div>
      </div>
    </IndexGrid>
  );
};

export const query = graphql`
  query IndexPageData {
    ...FeaturedLessons
    site {
      ...SiteInformation
    }
    tutorials: allMdx(
      filter: { frontmatter: { type: { eq: "post" } } }
      limit: 10
      sort: { fields: [frontmatter___updated], order: DESC }
    ) {
      ...AllPostDetails
    }
    modules: allMdx(
      filter: {
        frontmatter: {
          type: { eq: "module" }
          deprecated: { ne: true }
          draft: { ne: true }
        }
      }
      sort: { fields: [frontmatter___lesson], order: ASC }
    ) {
      ...AllModuleDetails
    }
    lessons: allMdx(
      filter: { frontmatter: { type: { eq: "lesson" } } }
      sort: { fields: [frontmatter___lesson], order: ASC }
    ) {
      ...ModuleLessons
    }
    siteSearchIndex: siteSearchIndex {
      index
    }
  }
`;

export default IndexPage;
